import React from 'react'
import Input from 'components/Input'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import DatePicker from 'components/DatePicker'
import { changeActivenessFormUserWorkPeriod } from 'workspace/actions'
import {
  selectActivenessFormDataUserWorkPeriod,
  selectUserWorkPeriodFieldError
} from './selectors'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.activenessForm.userFinishedAtLabel',
    defaultMessage: 'Work finished at'
  }
})

const RealizationMenuUserFinishedAtInput = ({
  intl,
  onChange,
  finishedAt,
  error
}) => (
  <Input
    label={intl.formatMessage(messages.label)}
    error={error}
    nested
    className='workspace-form__control'
  >
    <DatePicker
      className='workspace-form__input'
      timeIntervals={1}
      showTimeSelect
      precision='minute'
      onChange={newValue => onChange({ finishedAt: newValue })}
      selected={finishedAt}
    />
  </Input>
)

RealizationMenuUserFinishedAtInput.propTypes = {
  intl: intlShape.isRequired,
  finishedAt: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const data = selectActivenessFormDataUserWorkPeriod(state, ownProps.idx)

  return {
    finishedAt: data.finishedAt,
    error: selectUserWorkPeriodFieldError(state, ownProps.idx, 'FinishedAt')
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: data =>
    dispatch(changeActivenessFormUserWorkPeriod({ idx: ownProps.idx, data }))
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(RealizationMenuUserFinishedAtInput)
