import React from 'react'
import PropTypes from 'prop-types'
import OriginalDatePicker from 'react-datepicker'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import { DATETIME_FORMAT, TIME_FORMAT } from 'lib/const'

const messages = defineMessages({
  time: {
    id: 'components.datePicker.time',
    defaultMessage: 'Time',
    description: 'Datepicker time label'
  }
})

const DatePicker = ({ intl, onChange, precision, ...props }) => (
  <OriginalDatePicker
    dateFormat={DATETIME_FORMAT}
    timeFormat={TIME_FORMAT}
    timeCaption={intl.formatMessage(messages.time)}
    onChange={value => onChange(precision ? value.startOf(precision) : value)}
    {...props}
  />
)

DatePicker.defaultProps = {
  onChange: () => {}
}

DatePicker.propTypes = {
  intl: intlShape,
  onChange: PropTypes.func,
  precision: PropTypes.oneOf(['second', 'minute'])
}

export default injectIntl(DatePicker)
