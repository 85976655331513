import React, { Component } from 'react'
import moment, { duration } from 'moment-timezone'
import PropTypes from 'prop-types'

class TimelineExtra extends Component {
  static propTypes = {
    timelineStart: PropTypes.object.isRequired,
    timelineHours: PropTypes.number.isRequired,
    startsAt: PropTypes.object.isRequired,
    endsAt: PropTypes.object.isRequired
  }

  render () {
    const {
      startsAt,
      endsAt,
      timelineStart,
      timelineHours: hours
    } = this.props
    const timelineEnd = timelineStart.clone().add(hours, 'hours')
    const visibleStartsAt = moment.max(startsAt, timelineStart)
    const visibleEndsAt = moment.min(endsAt, timelineEnd)
    const width = 100 * duration(visibleEndsAt.diff(visibleStartsAt)).asHours() / hours
    const leftOffset = 100 * duration(visibleStartsAt.diff(timelineStart)).asHours() / hours

    return (
      <div
        className='timeline-extra'
        style={{
          width: width + '%',
          left: leftOffset + '%'
        }}
      />
    )
  }
}

export default TimelineExtra
