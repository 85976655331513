import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormActionTaken = ({ actionTaken, onChange }) => (
  <WorkspaceFormInput attribute='actionTaken'>
    <input
      type='text'
      className='workspace-form__input'
      value={actionTaken || ''}
      onChange={(e) => onChange({ actionTaken: e.target.value ? e.target.value : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormActionTaken.propTypes = {
  actionTaken: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  actionTaken: state.form.data.actionTaken
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormActionTaken)
