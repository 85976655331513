import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import Select from 'components/Select'
import {
  selectProductionRunFormDataComponent,
  selectPermissions,
  selectProductionRunComponentAvailableOptions,
  selectProductionRunFormComponentFieldError,
  selectProductionRunComponentAllOptions
} from './selectors'
import { changeProductionRunFormComponent } from './actions'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.componentNameLabel',
    defaultMessage: 'Component'
  },
  noInfo: {
    id: 'workspace.form.noInfo',
    defaultMessage: 'No Information'
  }
})

const ProductionRunFormComponentName = ({
  intl,
  idx,
  options,
  availableOptions,
  productionRunComponent,
  isDisabled,
  onChange,
  error
}) => {
  let option = (
    options.find(e => e.value && e.value === productionRunComponent.productComponentId) ||
    availableOptions.find(e => e.value && !productionRunComponent.dirty) ||
    options[0]
  )

  if (!productionRunComponent.productComponentId && !productionRunComponent.dirty) {
    onChange(option)
  }

  return (
    <Input
      nested
      label={intl.formatMessage(messages.label)}
      className='workspace-form__control'
      error={error}
    >
      <Select
        className='workspace-form__input'
        style={{ width: '100%', boxSizing: 'border-box' }}
        options={options}
        onChange={onChange}
        value={option}
        noOptionsMessage={() => intl.formatMessage(messages.noInfo)}
        isDisabled={isDisabled}
        isClearable
        placeholder={
          intl.formatMessage(messages.noInfo)
        }
      />
    </Input>
  )
}

ProductionRunFormComponentName.propTypes = {
  idx: PropTypes.number,
  productionRunComponent: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  intl: intlShape,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })
  ),
  availableOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.any, label: PropTypes.string })
  ),
  error: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const productionRunComponent = selectProductionRunFormDataComponent(
    state,
    ownProps.idx
  )

  const options = selectProductionRunComponentAllOptions(
    state,
    ownProps.idx
  )

  const availableOptions = selectProductionRunComponentAvailableOptions(
    state,
    ownProps.idx
  )

  const {
    realization: { changeProductionRunComponent: canChange }
  } = selectPermissions(state)

  return {
    productionRunComponent,
    options,
    availableOptions,
    error: selectProductionRunFormComponentFieldError(state, ownProps.idx, 'ProductComponent'),
    isDisabled: !productionRunComponent.productComponentIds.length || !canChange
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: selectData =>
    dispatch(
      changeProductionRunFormComponent({
        idx: ownProps.idx,
        data: {
          dirty: true,
          productComponentId: selectData ? selectData.value : null,
          componentDescription: selectData
            ? selectData.label
            : null
        }
      })
    )
})

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductionRunFormComponentName)
