import { put, select } from 'redux-saga/effects'
import {
  selectAvailablePredefinedRemarks,
  selectAvailableProducts,
  selectAvailableProjects,
  selectAvailableStoppageTypes,
  selectMode,
  selectUsersForMachinery
} from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'
import { FormTypes, Modes } from 'workspace/const'

export default function * formChange (action) {
  const {
    projectId,
    productId,
    stoppageTypeId,
    predefinedRemarkId,
    responsibleId,
    machineryId
  } = yield select(state => state.form.data)
  const mode = yield select(selectMode)
  const type = yield select(state => state.form.type)

  const availableProjects = yield select(selectAvailableProjects)
  if (projectId && !availableProjects.map(p => p.id).includes(projectId)) {
    yield put(changeFormData({ projectId: null }))
  }

  const availableProducts = yield select(selectAvailableProducts)
  if (productId && !availableProducts.map(p => p.id).includes(productId)) {
    yield put(changeFormData({ productId: null }))
  }

  const availableStoppageTypes = yield select(selectAvailableStoppageTypes)
  if (stoppageTypeId && !availableStoppageTypes.map(p => p.id).includes(stoppageTypeId)) {
    if (type === FormTypes.REALIZATION_STOPPAGE) {
      yield put(changeFormData({ stoppageTypeId: availableStoppageTypes[0] && availableStoppageTypes[0].id }))
    } else {
      yield put(changeFormData({ stoppageTypeId: null }))
    }
  }

  if (action.payload.scrapTypeId) {
    yield put(changeFormData({ scrapSubtypeId: null }))
  }

  if (mode === Modes.REALIZATION) {
    const availablePredefinedRemarks = yield select(selectAvailablePredefinedRemarks)
    if (predefinedRemarkId && !availablePredefinedRemarks.map(p => p.id).includes(predefinedRemarkId)) {
      yield put(changeFormData({ predefinedRemarkId: null }))
    }
  }

  const usersForMachinery = yield select(selectUsersForMachinery)
  if (responsibleId && machineryId && !usersForMachinery.map(u => u.id).includes(responsibleId)) {
    yield put(changeFormData({ responsibleId: null }))
  }
}
