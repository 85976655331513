import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectIntl, intlShape } from 'react-intl'
import { DivisionMessages } from 'workspace/const'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import Select from 'components/Select'
import { changeFormData } from 'workspace/actions'

const DIVISIONS = [
  'electrical',
  'logistics',
  'mechanical',
  'quality',
  'technical'
]

const optionMapper = (v, intl) => ({ label: v && intl.formatMessage(DivisionMessages[v]), value: v })

const WorkspaceFormDivision = ({ division, onChange, intl, disabled }) => (
  <WorkspaceFormInput attribute='division'>
    <Select
      options={DIVISIONS.map(v => optionMapper(v, intl))}
      value={optionMapper(division, intl)}
      onChange={(value) => onChange({ division: value ? value.value : false })}
      isDisabled={disabled}
      isClearable={false}
      backspaceRemovesValue={false}
    />
  </WorkspaceFormInput>
)

WorkspaceFormDivision.propTypes = {
  division: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape
}

const mapStateToProps = state => ({
  division: state.form.data.division
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormDivision)
