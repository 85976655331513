import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { startProductionRun, finishProductionRun } from './actions'
import ProductionRunFormProductionPlan from './ProductionRunFormProductionPlan'
import ProductionRunFormLength from './ProductionRunFormLength'
import ProductionRunFormQuantity from './ProductionRunFormQuantity'
import {
  selectProductionRunFormDataComponents,
  selectProductionRunFormData,
  selectProductionPlanForProductionRun
} from './selectors'
import ProductionRunFormComponent from './ProductionRunFormComponent'
import ProductionRunFormFirstGoodPartAt from './ProductionRunFormFirstGoodPartAt'
import ProductionRunFormLastGoodPartAt from './ProductionRunFormLastGoodPartAt'
import ProductionRunFormProfile from './ProductionRunFormProfile'
import ProductionRunFormRemarks from './ProductionRunFormRemarks'
import ConfirmableButton from '../components/ConfirmableButton'
import ProductionRunFormConfirmation from './ProductionRunFormConfirmation'
import ProductionRunFormCarrierScrap from './ProductionRunFormCarrierScrap'
import ProductionRunFormProductionSpeed from './ProductionRunFormProductionSpeed'

const FIRST_COLUMN_LENGTH = 4

const sectionStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: 5,
  backgroundColor: 'white',
  marginTop: 0,
  marginBottom: 0
}

const leftColumnStyles = {
  width: 509,
  paddingLeft: 76,
  boxSizing: 'border-box'
}

const autoSaveFeedbackStyles = {
  fontSize: 16,
  textTransform: 'uppercase',
  lineHeight: 2
}

const Processing = () => (
  <span
    style={{
      ...autoSaveFeedbackStyles,
      color: '#3498db'
    }}
  >
    <FormattedMessage id='workspace.menu.productionRunForm.processing' />
  </span>
)

const Saved = () => (
  <span
    style={{
      ...autoSaveFeedbackStyles,
      color: '#1abc9c'
    }}
  >
    <Icon name='check-circle' />
    &nbsp;
    <FormattedMessage id='workspace.menu.productionRunForm.saved' />
  </span>
)

const columnStyles = { width: '100%', backgroundColor: 'white' }

const rightColumnStyles = { width: 400 }

class RealizationMenuProductionRunForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault()

    if (!this.isConfirmed) {
      return
    }

    this.props.onSubmit()
  }

  handleFinish = e => {
    e.preventDefault()
    this.props.onFinish(this.props.id)
  }

  componentDidMount () {
    window.closeProductionRunForm = this.props.onClose
    window.openProductionRunForm = this.props.open
  }

  componentWillUnmount () {
    window.closeProductionRunForm = null
    window.openProductionRunForm = null
  }

  get isConfirmable () {
    const { productionPlan, id } = this.props
    if (id || !productionPlan) {
      return false
    }

    return !!productionPlan.remarks
  }

  get isConfirmed () {
    if (!this.isConfirmable) {
      return true
    }

    return this.props.confirmed
  }

  get confirmableRemarks () {
    return this.props.productionPlan.remarks
  }

  render () {
    const {
      onClose,
      productionRunComponents,
      id,
      lastGoodPartAtDirty,
      lastGoodPartAtProcessing,
      firstGoodPartAtDirty,
      firstGoodPartAtProcessing
    } = this.props

    return (
      <form
        style={{ marginTop: 2, marginBottom: 2, width: 1090, padding: 10 }}
        onSubmit={this.handleSubmit}
      >
        <div style={sectionStyles}>
          <div
            className='workspace-form'
            style={{ marginBottom: 0, minHeight: 'initial' }}
          >
            <ProductionRunFormProductionPlan />
            <ProductionRunFormRemarks />
          </div>
        </div>
        <div style={sectionStyles}>
          <div className='workspace-form'>
            <ProductionRunFormProfile />
            <ProductionRunFormLength />
            <ProductionRunFormQuantity />
          </div>
        </div>
        { !this.isConfirmed && <ProductionRunFormConfirmation remarks={this.confirmableRemarks} /> }
        {!!productionRunComponents.length && (
          <React.Fragment>
            <div style={{ display: 'flex' }}>
              <div style={{ ...columnStyles, marginLeft: 2 }}>
                {productionRunComponents
                  .slice(0, FIRST_COLUMN_LENGTH)
                  .map((e, idx) => (
                    <ProductionRunFormComponent idx={idx} key={idx} />
                  ))}
              </div>
              <div style={{ ...columnStyles, marginRight: 2 }}>
                {productionRunComponents
                  .slice(FIRST_COLUMN_LENGTH)
                  .map((e, idx) => (
                    <ProductionRunFormComponent
                      idx={idx + FIRST_COLUMN_LENGTH}
                      key={idx + FIRST_COLUMN_LENGTH}
                    />
                  ))}
              </div>
            </div>
            {!!id && (
              <div
                style={{
                  display: 'flex'
                }}
              >
                <div style={{ ...columnStyles, marginLeft: 2 }}>
                  <div
                    className='workspace-form'
                    style={{ justifyContent: 'flex-start', marginBottom: 0 }}
                  >
                    <ProductionRunFormFirstGoodPartAt />
                    <div
                      className='workspace-form__menu'
                      style={{ marginTop: 2 }}
                    >
                      {firstGoodPartAtProcessing && <Processing />}
                      {!firstGoodPartAtProcessing && firstGoodPartAtDirty && (
                        <Saved />
                      )}
                    </div>
                  </div>
                  <div
                    className='workspace-form'
                    style={{ justifyContent: 'flex-start', marginBottom: 0 }}
                  >
                    <ProductionRunFormLastGoodPartAt />
                    <div
                      className='workspace-form__menu'
                      style={{ marginTop: 2 }}
                    >
                      {lastGoodPartAtProcessing && <Processing />}
                      {!lastGoodPartAtProcessing && lastGoodPartAtDirty && (
                        <Saved />
                      )}
                    </div>
                  </div>
                </div>
                <div style={{ ...columnStyles, marginRight: 2 }}>
                  <div style={{ marginTop: 2 }} >
                    <ProductionRunFormCarrierScrap />
                  </div>
                  <div style={{ marginTop: 2 }} >
                    <ProductionRunFormProductionSpeed />
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                ...sectionStyles,
                marginTop: -2
              }}
            >
              <div style={leftColumnStyles}>
                {!!id && (
                  <ConfirmableButton color='success' onClick={this.handleFinish}>
                    <FormattedMessage id='workspace.menu.productionRunForm.endButton' />
                  </ConfirmableButton>
                )}
              </div>
              <div style={{ ...rightColumnStyles, textAlign: 'right' }}>
                <Button color='danger' onClick={onClose}>
                  <FormattedMessage id='workspace.menu.productionRunForm.cancel' />
                </Button>
                <Button
                  color='success'
                  disabled={!this.isConfirmed}
                  onClick={this.handleSubmit}
                  style={{ marginLeft: 2 }}
                >
                  <FormattedMessage id='workspace.menu.activenessForm.confirm' />
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </form>
    )
  }
}

RealizationMenuProductionRunForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  productionRunComponents: PropTypes.array,
  lastGoodPartAtProcessing: PropTypes.bool,
  lastGoodPartAtDirty: PropTypes.bool,
  firstGoodPartAtProcessing: PropTypes.bool,
  firstGoodPartAtDirty: PropTypes.bool,
  confirmed: PropTypes.bool,
  id: PropTypes.number,
  open: PropTypes.func,
  productionPlan: PropTypes.object
}

const mapStateToProps = state => {
  const formData = selectProductionRunFormData(state)

  const {
    firstGoodPartAtDirty,
    firstGoodPartAtProcessing,
    lastGoodPartAtDirty,
    lastGoodPartAtProcessing,
    confirmed
  } = formData

  return {
    productionRunComponents: selectProductionRunFormDataComponents(state),
    firstGoodPartAtDirty,
    firstGoodPartAtProcessing,
    lastGoodPartAtDirty,
    lastGoodPartAtProcessing,
    id: formData.id,
    productionPlan: selectProductionPlanForProductionRun(state, formData.productionPlanId),
    confirmed
  }
}

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(startProductionRun()),
  onFinish: id => dispatch(finishProductionRun(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationMenuProductionRunForm)
