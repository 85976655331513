import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormLength = ({ length, onChange, style }) => (
  <WorkspaceFormInput attribute='length' extraErrorsAttribute='length' style={style}>
    <input
      type='number'
      min='0'
      className='workspace-form__input'
      value={(length || length === 0) ? length : ''}
      onChange={(e) => onChange({ length: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormLength.propTypes = {
  length: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
}

WorkspaceFormLength.defaultProps = {
  style: {}
}

const mapStateToProps = (state) => ({
  length: state.form.data.length
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormLength)
