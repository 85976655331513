import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormQuantity = ({ quantity, onChange, style }) => (
  <WorkspaceFormInput attribute='quantity' extraErrorsAttribute='quantity' style={style}>
    <input
      type='number'
      min='0'
      className='workspace-form__input'
      value={(quantity || quantity === 0) ? quantity : ''}
      onChange={(e) => onChange({ quantity: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormQuantity.propTypes = {
  quantity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
}

WorkspaceFormQuantity.defaultProps = {
  style: {}
}

const mapStateToProps = (state, { quantityType }) => ({
  quantity: state.form.data.quantity
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormQuantity)
