import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableMachineries } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noMachineries: {
    id: 'workspace.form.noMachinery',
    defaultMessage: 'No matching machinery',
    description: 'Message displayed when there are no machinery available for the selected stoppage type or search term'
  }
})

const WorkspaceFormMachinery = ({ id, intl, machineries, onChange }) => (
  <WorkspaceFormInput attribute='machinery'>
    <AssociationSelect
      isClearable
      options={machineries}
      value={id}
      onChange={(newId) => onChange({ machineryId: newId })}
      labelMapper={machinery => `${machinery.name}`}
      noOptionsMessage={() => intl.formatMessage(messages.noMachineries)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormMachinery.propTypes = {
  intl: intlShape.isRequired,
  machineries: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  machineries: selectAvailableMachineries(state),
  id: state.form.data.machineryId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormMachinery)
