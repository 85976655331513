import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProductionRunFormDataComponent, selectProductionRunFormComponentFieldError } from './selectors'
import { changeProductionRunFormComponent } from './actions'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.premixQuantityLabel',
    defaultMessage: 'Premix quantity'
  }
})

const ProductionRunFormComponentPremixQuantity = ({
  intl,
  idx,
  premixQuantity,
  componentDescription,
  onChange,
  error,
  isExtruder
}) => {
  if (!isExtruder) return null

  return (
    <Input
      nested
      label={intl.formatMessage(messages.label)}
      className='workspace-form__control'
      error={error}
      style={{ flexGrow: 1 }}
    >
      <input
        type='text'
        className='workspace-form__input'
        style={{ width: '100%', boxSizing: 'border-box' }}
        value={premixQuantity || ''}
        onChange={e => onChange({ premixQuantity: e.target.value })}
        readOnly={!componentDescription}
      />
    </Input>
  )
}

ProductionRunFormComponentPremixQuantity.propTypes = {
  idx: PropTypes.number,
  premixQuantity: PropTypes.number,
  componentDescription: PropTypes.any,
  intl: intlShape,
  onChange: PropTypes.func,
  error: PropTypes.string,
  isExtruder: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
  const { premixQuantity, componentDescriptions, isExtruder } = selectProductionRunFormDataComponent(
    state,
    ownProps.idx
  )

  return {
    premixQuantity,
    isExtruder,
    componentDescription: componentDescriptions[0],
    error: selectProductionRunFormComponentFieldError(state, ownProps.idx, 'PremixQuantity')
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: data =>
    dispatch(changeProductionRunFormComponent({ idx: ownProps.idx, data }))
})

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductionRunFormComponentPremixQuantity)
