import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormDescription = ({ description, onChange, disabled }) => (
  <WorkspaceFormInput attribute='description'>
    <input
      type='text'
      className='workspace-form__input'
      value={description}
      onChange={(e) => onChange({ description: e.target.value })}
      disabled={disabled}
    />
  </WorkspaceFormInput>
)

WorkspaceFormDescription.propTypes = {
  description: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  description: state.form.data.description
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormDescription)
