import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Icon from 'components/Icon'

const Modal = ({ children, onClose, className, style }) => {
  return (
    <div className='modal__overlay' onClick={onClose}>
      <div className={classnames('modal', className)} onClick={e => e.stopPropagation()} style={style}>
        <div className='modal__close' onClick={onClose}><Icon name='times' fw /></div>
        {children}
      </div>
    </div>
  )
}

Modal.defaultProps = {
  onClose: () => {}
}

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object
}

export default Modal
