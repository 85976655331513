import { call, all, select } from 'redux-saga/effects'
import { selectMode } from 'workspace/selectors'
import {
  requestLocationSchedules,
  requestAllSchedules,
  loadLocationSchedules,
  loadAllSchedules,
  loadAllScheduleUpdates
} from './schedules'
import {
  requestLocationRealizations,
  requestAllRealizations,
  loadLocationRealizations,
  loadAllRealizations,
  loadAllShiftNotifications
} from './realizations'
import {
  requestLocationScraps,
  requestAllScraps,
  loadLocationScraps,
  loadAllScraps
} from './scraps'
import {
  requestLocationRealizationSummaries,
  requestAllRealizationSummaries,
  loadLocationRealizationSummaries,
  loadAllRealizationSummaries
} from './realizationSummaries'
import { requestCurrentActiveness } from './activeness'

export function * loadTimelines () {
  const mode = yield select(selectMode)
  const requests = [
    call(requestAllSchedules),
    mode === 'realization' ? call(requestAllRealizations) : null,
    mode === 'realization' ? call(requestAllScraps) : null,
    mode === 'realization' ? call(requestAllRealizationSummaries) : null,
    mode === 'realization' ? call(requestCurrentActiveness) : null
  ]
  const loaders = [
    call(loadAllSchedules),
    mode === 'realization' ? call(loadAllScheduleUpdates) : null,
    mode === 'realization' ? call(loadAllRealizations) : null,
    mode === 'realization' ? call(loadAllScraps) : null,
    mode === 'realization' ? call(loadAllRealizationSummaries) : null,
    mode === 'realization' ? call(loadAllShiftNotifications) : null
  ]

  yield all(requests)
  yield all(loaders)
}

export function * loadTimeline (locationId) {
  const mode = yield select(selectMode)
  const requests = [
    call(requestLocationSchedules, locationId),
    mode === 'realization' ? call(requestLocationRealizations, locationId) : null,
    mode === 'realization' ? call(requestLocationScraps, locationId) : null,
    mode === 'realization' ? call(requestLocationRealizationSummaries, locationId) : null,
    mode === 'realization' ? call(requestCurrentActiveness) : null
  ]
  const loaders = [
    call(loadLocationSchedules, locationId),
    mode === 'realization' ? call(loadLocationRealizations, locationId) : null,
    mode === 'realization' ? call(loadLocationScraps, locationId) : null,
    mode === 'realization' ? call(loadLocationRealizationSummaries, locationId) : null
  ]

  yield all(requests)
  yield all(loaders)
}
