import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableScrapTypes } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormScrapType = ({ id, scrapTypes, onChange }) => (
  <WorkspaceFormInput attribute='scrapType'>
    <AssociationSelect
      isClearable={false}
      backspaceRemovesValue={false}
      options={scrapTypes}
      value={id}
      onChange={newId => onChange({ scrapTypeId: newId })}
      labelMapper={st => `${st.symbol} - ${st.description}`}
    />
  </WorkspaceFormInput>
)

WorkspaceFormScrapType.propTypes = {
  scrapTypes: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  scrapTypes: selectAvailableScrapTypes(state),
  id: state.form.data.scrapTypeId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormScrapType)
