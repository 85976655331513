import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineBlock from 'components/TimelineBlock'
import RealizationTimelineBlockContent from 'workspace/RealizationTimelineBlockContent'
import RealizationTimelineTooltip from 'workspace/RealizationTimelineTooltip'
import { selectTimelineBlock } from 'workspace/actions'
import {
  selectRealization,
  selectRealizationDetails
} from 'workspace/selectors'
import { Modes } from 'workspace/const'
import { Characters } from 'lib/const'
import { selectCurrentProductionRun } from './selectors'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary',
  [Characters.UNREPORTED]: 'warning'
}

const getRawColor = (character, project, stoppageType) => {
  if (character === Characters.PRODUCTION && project && project.blockColor) {
    return '#' + project.blockColor
  } else if (character === Characters.STOPPAGE && stoppageType && stoppageType.blockColor) {
    return '#' + stoppageType.blockColor
  } else {
    return null
  }
}

const RealizationTimelineBlock = ({
  id,
  startedAt,
  finishedAt,
  product,
  project,
  stoppageType,
  timelineSize,
  character,
  isFormActive,
  selectTimelineBlock,
  productionPlan,
  productionRun,
  ...rest
}) => {
  const isNotAllowedToClick =
    character === Characters.PRODUCTION &&
    productionPlan &&
    productionRun &&
    productionRun.productionPlanId !== productionPlan.id

  return (
    <TimelineBlock
      {...rest}
      startsAt={startedAt}
      endsAt={finishedAt}
      color={CHARACTER_COLOR[character]}
      timelineSize={timelineSize}
      size={timelineSize}
      notAllowed={isNotAllowedToClick}
      significant={stoppageType && stoppageType.isSignificant}
      offset={0}
    >
      <RealizationTimelineBlockContent
        id={id}
        onClick={e =>
          selectTimelineBlock({ mode: Modes.REALIZATION, character, id })
        }
        rawColor={getRawColor(character, project, stoppageType)}
      />
      <RealizationTimelineTooltip
        id={id}
        rawColor={project && project.blockColor && '#' + project.blockColor}
      />
    </TimelineBlock>
  )
}

RealizationTimelineBlock.propTypes = {
  id: PropTypes.number.isRequired,
  timelineSize: PropTypes.number.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
    projectIds: PropTypes.arrayOf(PropTypes.number).isRequired
  }),
  project: PropTypes.shape({
    blockColor: PropTypes.string
  }),
  stoppageType: PropTypes.shape({
    blockColor: PropTypes.string
  }),
  character: Characters.propType,
  productionRun: PropTypes.shape({
    productionPlanId: PropTypes.number
  })
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealization(state, id),
  ...selectRealizationDetails(state, id),
  productionRun: selectCurrentProductionRun(state)
})

const mapDispatchToProps = {
  selectTimelineBlock
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationTimelineBlock)
