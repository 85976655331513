import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormProductionCallNumber = ({ productionCallNumber, onChange }) => (
  <WorkspaceFormInput attribute='productionCallNumber' extraErrorsAttribute='productionCallNumber'>
    <input
      className='workspace-form__input'
      value={productionCallNumber || ''}
      onChange={(e) => onChange({ productionCallNumber: e.target.value })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormProductionCallNumber.propTypes = {
  productionCallNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  productionCallNumber: state.form.data.productionCallNumber
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormProductionCallNumber)
