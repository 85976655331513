import React from 'react'
import Input from 'components/Input'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import DatePicker from 'components/DatePicker'
import { changeActivenessFormUserWorkPeriod } from 'workspace/actions'
import {
  selectActivenessFormDataUserWorkPeriod,
  selectUserWorkPeriodFieldError
} from './selectors'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.activenessForm.userStartedAtLabel',
    defaultMessage: 'Work started at'
  }
})

const RealizationMenuUserStartedAtInput = ({
  intl,
  onChange,
  startedAt,
  error
}) => (
  <Input
    label={intl.formatMessage(messages.label)}
    error={error}
    nested
    className='workspace-form__control'
  >
    <DatePicker
      className='workspace-form__input'
      timeIntervals={1}
      showTimeSelect
      precision='minute'
      onChange={newValue => onChange({ startedAt: newValue })}
      selected={startedAt}
    />
  </Input>
)

RealizationMenuUserStartedAtInput.propTypes = {
  intl: intlShape.isRequired,
  startedAt: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const data = selectActivenessFormDataUserWorkPeriod(state, ownProps.idx)

  return {
    startedAt: data.startedAt,
    error: selectUserWorkPeriodFieldError(state, ownProps.idx, 'StartedAt')
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: data =>
    dispatch(changeActivenessFormUserWorkPeriod({ idx: ownProps.idx, data }))
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(RealizationMenuUserStartedAtInput)
