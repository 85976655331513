import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import { initActivenessForm, startActiveness } from './actions'
import UserWorkPeriods from './UserWorkPeriods'

class RealizationMenuActivenessForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.onSubmit()
  }

  componentDidMount () {
    this.props.onInit && this.props.onInit()
  }

  render () {
    const { onClose } = this.props

    return (
      <div style={{ marginTop: 2, width: 600 }}>
        <UserWorkPeriods />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 5,
            backgroundColor: 'white',
            margin: 2,
            marginTop: 0
          }}
        >
          <ButtonGroup nested flex compact>
            <Button size='small' onClick={this.handleSubmit} color='success'>
              <FormattedMessage
                id='workspace.menu.activenessForm.confirm'
                defaultMessage='Confirm'
              />
            </Button>
            <Button size='small' onClick={onClose}>
              <FormattedMessage
                id='workspace.menu.activenessForm.cancel'
                defaultMessage='Cancel'
              />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }
}

RealizationMenuActivenessForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInit: PropTypes.func
}

const mapDispatchToProps = dispatch => ({
  onSubmit: () => dispatch(startActiveness()),
  onInit: () => dispatch(initActivenessForm())
})

export default connect(
  null,
  mapDispatchToProps
)(RealizationMenuActivenessForm)
