import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TimelineLabel from 'components/TimelineLabel'
import { isShiftStart } from '../lib/factoryTime'

const checkResolutionCondition = (date, condition) => {
  const enhancedDate = date.clone()
  enhancedDate.minutesFromMidnight = () => enhancedDate.diff(enhancedDate.clone().startOf('day'), 'minutes')

  return condition && Object.keys(condition).every(key => {
    const value = condition[key]

    if (value.includes) {
      return value.includes(enhancedDate[key]())
    } else {
      return value === enhancedDate[key]()
    }
  })
}

const TimelineCell = ({ date, timelineHours: hours, resolution }) => {
  const isShift = resolution.displayShift && isShiftStart(date)
  const width = `${(resolution.cell * 100) / hours}%`
  const isFull = checkResolutionCondition(date, resolution.cellFull)
  const isHighlight = checkResolutionCondition(date, resolution.cellHighlight)

  const css = classnames(
    isFull ? 'timeline-cell' : 'timeline-cell--half',
    isHighlight && 'timeline-cell--highlight',
    { 'timeline-cell--shift': isShift }
  )

  return (
    <div className={css} style={{ width }}>
      {
        isFull
          ? (
            <TimelineLabel
              date={date}
              showTime={resolution.label.time}
              showShift={resolution.label.shift}
              showWeekday={resolution.label.weekday}
              showDate={resolution.label.date}
            />
          )
          : ''
      }
    </div>
  )
}

TimelineCell.propTypes = {
  date: PropTypes.object,
  timelineHours: PropTypes.number,
  resolution: PropTypes.shape({
    cell: PropTypes.number.isRequired,
    cellFull: PropTypes.object.isRequired,
    displayShift: PropTypes.boolean
  }).isRequired
}

export default TimelineCell
