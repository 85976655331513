import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DatePicker from 'components/DatePicker'
import CustomCalendarInput from 'components/CustomCalendarInput'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import { DATE_FORMAT } from 'lib/const'

const WorkspaceFormStartedAt = ({ date, onChange }) => (
  <WorkspaceFormInput attribute='date' className='workspace-form__button-input'>
    <DatePicker
      className='workspace-form__input react-datepicker--week'
      selected={date}
      onChange={value => onChange({ date: value })}
      dateFormat={DATE_FORMAT}
      startDate={date && date.clone().startOf('isoweek')}
      endDate={date && date.clone().endOf('isoweek')}
      customInput={<CustomCalendarInput
        startDate={date && date.clone().startOf('isoweek')}
        endDate={date && date.clone().endOf('isoweek')}
        buttonProps={{ block: true }}
      />}
    />
  </WorkspaceFormInput>
)

WorkspaceFormStartedAt.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  date: state.form.data.date
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormStartedAt)
