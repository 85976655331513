import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { changeShiftBookNote } from './actions'
import {
  selectShiftBookNoteDraft,
  selectShiftBookError
} from './selectors'
import classnames from 'classnames'

const RealizationMenuShiftBookDraft = ({ draft, error, onChange }) => (
  <textarea
    className={classnames('shift-book-notes__textarea', {
      'shift-book-notes__textarea--error': error
    })}
    onChange={e => onChange(e.target.value)}
    value={draft}
  />
)

RealizationMenuShiftBookDraft.propTypes = {
  draft: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func
}

const mapStateToProps = state => ({
  draft: selectShiftBookNoteDraft(state),
  error: selectShiftBookError(state)
})

const mapDispatchToProps = {
  onChange: changeShiftBookNote
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationMenuShiftBookDraft)
