import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import RealizationScrapTableRow from 'workspace/RealizationScrapTableRow'
import Loader from 'components/Loader'

export const RealizationScrapTable = ({ scrapIds, isLoading, skipActions }) => {
  const skipActionsResult = (typeof skipActions === 'boolean') ? skipActions : false

  return (
    <div style={{ position: 'relative' }}>
      {
        isLoading && <Loader style={{ position: 'absolute', right: 0, left: 0, opacity: 0.66 }} size={2} full />
      }
      <table className='table'>
        <thead>
          <tr>
            <th><FormattedMessage id='workspace.scrap.reportedAt' defaultMessage='Reported at' /></th>
            <th><FormattedMessage id='workspace.scrap.plan' defaultMessage='Plan / Project' /></th>
            <th><FormattedMessage id='workspace.scrap.product' defaultMessage='Product' /></th>
            <th><FormattedMessage id='workspace.scrap.type' defaultMessage='Type' /></th>
            <th><FormattedMessage id='workspace.scrap.subtype' defaultMessage='Subtype' /></th>
            <th><FormattedMessage id='workspace.scrap.comment' defaultMessage='Comment' /></th>
            <th><FormattedMessage id='workspace.scrap.quantity' defaultMessage='Quantity' /></th>
            <th><FormattedMessage id='workspace.form.length' defaultMessage='Length' /></th>
            <th><FormattedMessage id='workspace.scrap.reportedBy' defaultMessage='Reported by' /></th>
            {!skipActionsResult && (
              <th className='wmin' />
            )}
          </tr>
        </thead>

        <tbody>
          {
            scrapIds.length === 0 && <tr><td colSpan={10} style={{ textAlign: 'center' }}>---</td></tr>
          }
          {
            scrapIds.map(id => <RealizationScrapTableRow key={id} id={id} skipActions={skipActions} />)
          }
        </tbody>
      </table>
    </div>
  )
}

RealizationScrapTable.propTypes = {
  scrapIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  isLoading: PropTypes.bool,
  skipActions: PropTypes.oneOfType(PropTypes.bool, PropTypes.func)
}

RealizationScrapTable.defaultProps = {
  skipActions: false
}

const mapStateToProps = (state, { id }) => ({
  scrapIds: state.scraps.idsByLocation[id],
  isLoading: state.locations.isLoadingScrapsById[id]
})

export default connect(mapStateToProps)(RealizationScrapTable)
