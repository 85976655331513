import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import Unit from 'components/Unit'
import {
  selectRealizationSummary,
  selectRealizationSummaryProject
} from 'workspace/selectors'
import Color from 'color'

const RealizationSummaryTimelineBlockContent = ({
  changeover,
  stoppageDuration,
  scrap,
  project,
  production
}) => {
  if (changeover) {
    return <div className='timeline-block__content' />
  } else {
    const color = '#' + project.blockColor
    const style = project.blockColor
      ? { borderColor: Color(color).darken(0.5), backgroundColor: color }
      : {}
    return (
      <div className='timeline-block__stack'>
        <div
          className='timeline-block__content timeline-block__content--success'
          style={style}
        >
          <code className='timeline-block__line'>
            <FormattedMessage
              id='workspace.realizationSummary.block.production'
              defaultMessage='Production'
            />
            {': '}
            <Unit value={production} unit='piece' />
          </code>
        </div>
        <div className='timeline-block__content timeline-block__content--danger'>
          <code className='timeline-block__line'>
            <FormattedMessage
              id='workspace.realizationSummary.block.stoppageDuration'
              defaultMessage='Stoppage'
            />
            {': '}
            <Unit value={stoppageDuration.toFixed(0)} unit='minute' />
          </code>
        </div>
        <div className='timeline-block__content timeline-block__content--info'>
          <code className='timeline-block__line'>
            <FormattedMessage
              id='workspace.realizationSummary.block.scrap'
              defaultMessage='Scrap'
            />
            {': '}
            <Unit value={scrap} unit='meter' />
          </code>
        </div>
      </div>
    )
  }
}

RealizationSummaryTimelineBlockContent.propTypes = {
  id: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  production: PropTypes.number,
  stoppageDuration: PropTypes.number,
  changeover: PropTypes.bool.isRequired,
  scrap: PropTypes.number,
  project: PropTypes.shape({
    blockColor: PropTypes.string
  }).isRequired
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealizationSummary(state, id),
  project: selectRealizationSummaryProject(state, id)
})

export default connect(mapStateToProps)(RealizationSummaryTimelineBlockContent)
