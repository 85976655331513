import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableProjects } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noProjects: {
    id: 'workspace.form.noProjects',
    defaultMessage: 'No matching projects',
    description: 'Message displayed when there are no projects for the selected location or search term'
  }
})

const WorkspaceFormProject = ({ id, intl, projects, onChange }) => (
  <WorkspaceFormInput attribute='project'>
    <AssociationSelect
      isClearable
      options={projects}
      value={id}
      onChange={newId => onChange({ projectId: newId })}
      labelMapper={proj => proj.name}
      noOptionsMessage={() => intl.formatMessage(messages.noProjects)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormProject.propTypes = {
  intl: intlShape.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  projects: selectAvailableProjects(state),
  id: state.form.data.projectId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormProject)
