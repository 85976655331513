import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import ButtonGroup from 'components/ButtonGroup'
import Input from 'components/Input'
import { selectActivenessErrors } from 'workspace/selectors'
import UserWorkPeriods from './UserWorkPeriods'

class RealizationMenuActivenessFinishForm extends React.Component {
  handleAuthorizationCodeSubmitSubmit = e => {
    e.preventDefault()
    const params = {
      authorizationCode: this.authorizationCodeInput.value
    }
    this.props.onAuthorizationCodeSubmit(params)
  }

  componentDidMount () {
    window.closeActivenessFinishForm = this.props.onClose
  }

  render () {
    const { errors } = this.props

    return (
      <div style={{ margin: 2, padding: 5, backgroundColor: 'white' }}>
        <form onSubmit={this.handleAuthorizationCodeSubmitSubmit}>
          <Input
            label={
              <FormattedMessage
                id='workspace.menu.activenessForm.authorizationCodeLabel'
                defaultMessage='Authorization code'
              />
            }
            error={(errors.authorizationCode || [])[0]}
          >
            <input
              ref={node => {
                this.authorizationCodeInput = node
              }}
            />
          </Input>
          <ButtonGroup nested flex compact>
            <Button size='small' onClick={this.handleAuthorizationCodeSubmitSubmit} color='success'>
              <FormattedMessage
                id='workspace.menu.activenessForm.confirmFinish'
                defaultMessage='Confirm'
              />
            </Button>
          </ButtonGroup>
        </form>
        <UserWorkPeriods withFinishedAt />
        <ButtonGroup nested flex compact>
          <Button size='small' onClick={this.props.onActivenessUpdate} color='success'>
            <FormattedMessage
              id='workspace.menu.activenessForm.confirmChange'
              defaultMessage='Confirm'
            />
          </Button>
        </ButtonGroup>
      </div>
    )
  }
}

RealizationMenuActivenessFinishForm.propTypes = {
  onAuthorizationCodeSubmit: PropTypes.func.isRequired,
  onActivenessUpdate: PropTypes.func,
  onClose: PropTypes.func,
  errors: PropTypes.object
}

const mapStateToProps = state => ({
  errors: selectActivenessErrors(state)
})

export default connect(mapStateToProps)(RealizationMenuActivenessFinishForm)
