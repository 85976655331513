import { call, put, select } from 'redux-saga/effects'
// import { selectAvailableProjects, selectAvailableProducts } from 'workspace/selectors'
import { openNewForm, changeFormData } from 'workspace/actions'
import {
  selectCurrentActiveness,
  selectFormType,
  selectIsFormActive,
  selectMode,
  selectProduct,
  selectRealization,
  selectSchedule,
  selectCurrentProductionRun
} from 'workspace/selectors'
import { FormTypes, Modes } from 'workspace/const'
import { Characters } from 'lib/const'
import { changeProductionRunFormData } from '../actions'

const clickProductionRunButtonFunc = () =>
  document.querySelector('[data-production-run-dropdown]').click()

// In planning mode
function * planningPrefill (block) {
  const formType = yield select(selectFormType)
  const schedule = yield select(selectSchedule, block.id)
  const isFormActive = yield select(selectIsFormActive)
  let newFormType
  let changes = { locationId: schedule.locationId }

  switch (block.character) {
    case Characters.PRODUCTION:
      newFormType = FormTypes.PRODUCTION
      // Only prefill project details on production form
      if (!isFormActive || formType === FormTypes.PRODUCTION) {
        changes.projectId = schedule.productionPlan.projectId
        changes.productionCallNumber =
          schedule.productionPlan.productionCallNumber
        changes.quantity = schedule.productionPlan.quantity
        changes.scrapQuantity = schedule.productionPlan.scrapQuantity
        changes.startedAt = schedule.productionPlan.startedAt
        changes.finishedAt = schedule.productionPlan.finishedAt
        changes.ekanbanReservationNumber = schedule.productionPlan.ekanbanReservationNumber
      }
      break

    case Characters.STOPPAGE:
      newFormType = FormTypes.STOPPAGE
      // Only prefill stoppage details on stoppage form
      if (!isFormActive || formType === FormTypes.STOPPAGE) {
        changes.stoppageTypeId = schedule.stoppagePlan.stoppageTypeId
        changes.startedAt = schedule.stoppagePlan.startedAt
        changes.finishedAt = schedule.stoppagePlan.finishedAt
      }
      break

    case Characters.CHANGEOVER:
      return
  }

  if (!isFormActive) {
    yield put(openNewForm(newFormType, {}))
  }

  yield put(changeFormData(changes))
}

// In realization mode when plan is clicked
function * realizationPrefillFromPlan (block) {
  const productionRun = yield select(selectCurrentProductionRun)
  const formType = yield select(selectFormType)
  const schedule = yield select(selectSchedule, block.id)
  const isFormActive = yield select(selectIsFormActive)
  let newFormType
  let changes = {}

  // There is no production run? Open and prefill the form.
  if (!productionRun && block.character === Characters.PRODUCTION) {
    const productionPlanId = schedule.productionPlan.id

    yield put(
      changeProductionRunFormData({
        productionPlanId,
        projectId: schedule.productionPlan.projectId
      })
    )
    const func = window.openProductionRunForm || clickProductionRunButtonFunc

    func()
    return
  } else {
    // Ignore non-related productions
    if (
      block.character === Characters.PRODUCTION &&
      schedule.productionPlan.id !== productionRun.productionPlanId
    ) {
      return
    }
  }

  switch (block.character) {
    case Characters.PRODUCTION:
      newFormType = FormTypes.REALIZATION
      changes.projectId = schedule.productionPlan.projectId
      changes.productionPlanId = schedule.productionPlan.id
      break

    case Characters.STOPPAGE:
      newFormType = FormTypes.REALIZATION_STOPPAGE
      // Only prefill stoppage details on stoppage realization form
      changes.projectId = null
      changes.productionPlanId = null
      if (!isFormActive || formType === FormTypes.REALIZATION_STOPPAGE) {
        changes.stoppageTypeId = schedule.stoppagePlan.stoppageTypeId
      }
      break

    case Characters.CHANGEOVER:
      changes.projectId = null
      changes.productionPlanId = null
      return
  }

  if (!isFormActive) {
    yield put(openNewForm(newFormType, {}))
  }

  yield put(changeFormData(changes))
}

// In realization mode when realization is clicked
function * realizationPrefillFromRealization (block) {
  const formType = yield select(selectFormType)
  const realization = yield select(selectRealization, block.id)
  const isFormActive = yield select(selectIsFormActive)
  const productionRun = yield select(selectCurrentProductionRun)
  let changes = {}
  let newFormType
  let product

  if (!productionRun && block.character === Characters.PRODUCTION) {
    const func = window.openProductionRunForm || clickProductionRunButtonFunc
    yield put(
      changeProductionRunFormData({
        productionPlanId: realization.productionPlanId,
        projectId: realization.projectId
      })
    )

    func()
    return
  } else {
    if (
      block.character === Characters.PRODUCTION &&
      realization.productionPlanId !== productionRun.productionPlanId
    ) {
      return
    }
  }

  switch (block.character) {
    case Characters.PRODUCTION:
      newFormType = FormTypes.REALIZATION
      changes.projectId = realization.projectId
      changes.productionPlanId = realization.productionPlanId
      // Only prefill product details on realization and scrap forms
      if (
        !isFormActive ||
        [FormTypes.REALIZATION, FormTypes.REALIZATION_SCRAP].includes(formType)
      ) {
        product = yield select(selectProduct, realization.productId)
        changes.productId = realization.productId
        changes.quantity = product.packQuantity
      }
      break

    case Characters.STOPPAGE:
      newFormType = FormTypes.REALIZATION_STOPPAGE
      changes.projectId = realization.projectId
      changes.responsibleId = realization.responsibleId
      changes.actionTaken = realization.actionTaken
      changes.machineryId = realization.machineryId
      changes.productionPlanId = null
      // Only prefill stoppage details on stoppage realization form
      if (!isFormActive || formType === FormTypes.REALIZATION_STOPPAGE) {
        changes.stoppageTypeId = realization.stoppageTypeId
      }
      break

    case Characters.CHANGEOVER:
      newFormType = FormTypes.REALIZATION_CHANGEOVER
      changes.projectId = realization.projectId
      changes.productionPlanId = null
      break

    case Characters.UNREPORTED:
      // cancel prefill because unreported blocks have no useful information
      changes.projectId = null
      changes.productionPlanId = null
      return
  }

  if (!isFormActive) {
    yield put(openNewForm(newFormType, {}))
  }

  yield put(changeFormData(changes))
}

// In realization mode
function * realizationPrefill (block) {
  if (block.mode === Modes.PLANNING) {
    yield call(realizationPrefillFromPlan, block)
  } else if (block.mode === Modes.REALIZATION) {
    yield call(realizationPrefillFromRealization, block)
  }
}

export default function * formPrefill ({ payload }) {
  const mode = yield select(selectMode)

  if (mode === Modes.REALIZATION) {
    if (yield select(selectCurrentActiveness)) {
      yield call(realizationPrefill, payload)
    }
  } else if (mode === Modes.PLANNING) {
    // do nothing
    // yield call(planningPrefill, payload)
  }
}
