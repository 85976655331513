import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { confirmProductionRun } from './actions'
import Button from 'components/Button'
import { FormattedMessage } from 'react-intl'

const ProductionRunFormConfirmation = ({
  remarks,
  onConfirm
}) => {
  return (
    <div className='workspace-production-run__confirmation'>
      <div className='workspace-production-run__confirmation-box'>
        <span className='workspace-production-run__confirmation-box--accent'>
          <FormattedMessage id='workspace.menu.productionRunForm.confirmPrompt' />
          :&nbsp;
        </span>
        {remarks}
      </div>
      <div className='workspace-production-run__confirmation-action'>
        <Button
          color='danger'
          style={{ backgroundColor: '#ddaa46' }}
          onClick={e => {
            e.preventDefault()
            onConfirm()
          }}
        >
          <FormattedMessage id='workspace.menu.productionRunForm.confirm' />
        </Button>
      </div>
    </div>
  )
}

ProductionRunFormConfirmation.propTypes = {
  onConfirm: PropTypes.func,
  remarks: PropTypes.string
}

const mapDispatchToProps = dispatch => ({
  onConfirm: id => dispatch(confirmProductionRun(id))
})

export default connect(
  null,
  mapDispatchToProps
)(ProductionRunFormConfirmation)
