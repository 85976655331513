import { call, fork, put, select, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import { receiveRealizations, requestRealizations } from 'workspace/actions'
import { selectToken, selectTimezone } from 'workspace/selectors'
import { selectNavigationRange, selectNavigationScope } from 'containers/Navigation/selectors'
import { receiveShiftNotifications } from '../actions'
import { selectSelectedLocation } from '../selectors'
import { selectNavigationDate } from '../../containers/Navigation/selectors'

const realizationDecorator = ({ startedAt, engineeringFinishedAt, finishedAt, ...realization }, timezone) => ({
  ...realization,
  startedAt: moment.tz(startedAt, timezone),
  finishedAt: moment.tz(finishedAt, timezone),
  engineeringFinishedAt: moment.tz(engineeringFinishedAt, timezone)
})

const decorateRealizations = (realizations, timezone) => realizations.map(s => realizationDecorator(s, timezone))

const callRealizationsAPI = ({ token, from, to, locationId }) =>
  call(api.get, '/location_realizations', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

export function * requestLocationRealizations (locationId) {
  yield put(requestRealizations(locationId))
}

export function * requestAllRealizations () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestRealizations(ids[i]))
  }
}

export function * loadLocationRealizations (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)
  const timezone = yield select(selectTimezone)

  const realizations = yield callRealizationsAPI({ token, from, to, locationId })
  yield put(receiveRealizations({ locationId, realizations: decorateRealizations(realizations, timezone) }))
}

export function * loadAllRealizations () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationRealizations, ids[i])
  }
}

const callShiftNotificationsAPI = ({ token, date, locationId }) =>
  call(api.get, `/shift_notifications?ts=${+(new Date())}`, {
    token,
    params: {
      date,
      locationId
    }
  })

export function * loadAllShiftNotifications () {
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadShiftNotifications, ids[i])
  }
}

export function * loadShiftNotifications (locationId) {
  const scope = yield select(selectNavigationScope)
  if (scope !== 'day') return

  const token = yield select(selectToken)
  const { from: date } = yield select(selectNavigationRange)
  const shiftNotifications = yield callShiftNotificationsAPI({ token, date, locationId })
  yield put(receiveShiftNotifications({ locationId, shiftNotifications }))
}

export function * doConfirmShiftNotification ({ payload: { id, confirmShift: shift } }) {
  const token = yield select(selectToken)

  const location = yield select(selectSelectedLocation)
  const locationId = location.id
  let date = yield select(selectNavigationDate)
  date = moment(date).format('YYYY-MM-DD')

  yield call(api.put, `/shift_notifications/${id}/confirm`, { locationId, date, shift }, { token })
  yield fork(loadShiftNotifications, locationId)
}
