import { call, fork, put, select, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import { receiveRealizationSummaries as receive, requestRealizationSummaries as request } from 'workspace/actions'
import { selectToken, selectTimezone } from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'

const summaryDecorator = ({ startedAt, finishedAt, ...summary }, timezone) => ({
  ...summary,
  startedAt: moment.tz(startedAt, timezone),
  finishedAt: moment.tz(finishedAt, timezone)
})

const decorateSummaries = (summaries, timezone) => summaries.map(s => summaryDecorator(s, timezone))

const callRealizationsAPI = ({ token, from, to, locationId }) =>
  call(api.get, '/reports/realization_summaries', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

export function * requestLocationRealizationSummaries (locationId) {
  yield put(request(locationId))
}

export function * requestAllRealizationSummaries () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(request(ids[i]))
  }
}

export function * loadLocationRealizationSummaries (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)
  const timezone = yield select(selectTimezone)

  const summaries = yield callRealizationsAPI({ token, from, to, locationId })
  yield put(receive({ locationId, realizationSummaries: decorateSummaries(summaries, timezone) }))
}

export function * loadAllRealizationSummaries () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationRealizationSummaries, ids[i])
  }
}
