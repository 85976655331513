import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, defineMessages, intlShape, FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Button from 'components/Button'
import Icon from 'components/Icon'
import WorkspaceFormActionTaken from 'workspace/WorkspaceFormActionTaken'
import WorkspaceFormChangeoverDuration from 'workspace/WorkspaceFormChangeoverDuration'
import WorkspaceFormDate from 'workspace/WorkspaceFormDate'
import WorkspaceFormDescription from 'workspace/WorkspaceFormDescription'
import WorkspaceFormDivision from 'workspace/WorkspaceFormDivision'
import WorkspaceFormDuration from 'workspace/WorkspaceFormDuration'
import WorkspaceFormEngineeringFinishedAt from 'workspace/WorkspaceFormEngineeringFinishedAt'
import WorkspaceFormFinishedAt from 'workspace/WorkspaceFormFinishedAt'
import WorkspaceFormLocation from 'workspace/WorkspaceFormLocation'
import WorkspaceFormManualDuration from 'workspace/WorkspaceFormManualDuration'
import WorkspaceFormPredefinedRemark from 'workspace/WorkspaceFormPredefinedRemark'
import WorkspaceFormPriority from 'workspace/WorkspaceFormPriority'
import WorkspaceFormProduct from 'workspace/WorkspaceFormProduct'
import WorkspaceFormProductionPlan from 'workspace/WorkspaceFormProductionPlan'
import WorkspaceFormProject from 'workspace/WorkspaceFormProject'
import WorkspaceFormQuantity from 'workspace/WorkspaceFormQuantity'
import WorkspaceFormScrapQuantity from 'workspace/WorkspaceFormScrapQuantity'
import WorkspaceFormLength from 'workspace/WorkspaceFormLength'
import WorkspaceFormEkanbanReservationNumber from 'workspace/WorkspaceFormEkanbanReservationNumber'
import WorkspaceFormRemarks from 'workspace/WorkspaceFormRemarks'
import WorkspaceFormReportedAt from 'workspace/WorkspaceFormReportedAt'
import WorkspaceFormResolution from 'workspace/WorkspaceFormResolution'
import WorkspaceFormScrapType from 'workspace/WorkspaceFormScrapType'
import WorkspaceFormStartedAt from 'workspace/WorkspaceFormStartedAt'
import WorkspaceFormStoppageType from 'workspace/WorkspaceFormStoppageType'
import WorkspaceFormProductionCallNumber from 'workspace/WorkspaceFormProductionCallNumber'
import WorkspaceFormBatchNumber from 'workspace/WorkspaceFormBatchNumber'
import WorkspaceFormChargeNumber from 'workspace/WorkspaceFormChargeNumber'
import WorkspaceFormComment from 'workspace/WorkspaceFormComment'
import WorkspaceFormMachinery from 'workspace/WorkspaceFormMachinery'
import WorkspaceFormResponsible from 'workspace/WorkspaceFormResponsible'
import { selectLocations } from 'workspace/selectors'
import { closeForm, submitForm } from 'workspace/actions'
import { DATE_FORMAT } from 'lib/const'
import { FormTypes, Modes } from 'workspace/const'
import { selectMode } from './selectors'
import WorkspaceFormScrapSubtype from './WorkspaceFormScrapSubtype'

const messages = defineMessages({
  productionScheduled: {
    id: 'workspace.form.productionScheduled',
    defaultMessage:
      'Production scheduled at location {location} on {from} - {to}',
    description: 'Flash message that appears after successful submission'
  },
  stoppageScheduled: {
    id: 'workspace.form.stoppageScheduled',
    defaultMessage:
      'Stopppage scheduled at location {location} on {from} - {to}',
    description: 'Flash message that appears after successful submission'
  }
})

function configureField (flags) {
  return {
    [FormTypes.PRODUCTION]: flags[0] === 1,
    [FormTypes.STOPPAGE]: flags[1] === 1,
    [FormTypes.REALIZATION]: flags[2] === 1,
    [FormTypes.REALIZATION_STOPPAGE]: flags[3] === 1,
    [FormTypes.REALIZATION_CHANGEOVER]: flags[4] === 1,
    [FormTypes.REALIZATION_SCRAP]: flags[5] === 1,
    [FormTypes.REALIZATION_UNREPORTED]: flags[6] === 1,
    [FormTypes.BREAKDOWN_START]: flags[7] === 1,
    [FormTypes.BREAKDOWN_FINISH]: flags[8] === 1
  }
}

// P - production
// S - stoppage
// R - realization
// RS - realization stoppage
// RC - realization changeover
// RR - realization scrap
// RU - realization unreported
// BS - breakdown start
// BF - breakdown finish
// ---------------> P  S  R  RS RC RR RU BS BF
const FIELDS = {
  location:
    configureField([1, 1, 0, 0, 0, 0, 0, 0, 0]),
  project:
    configureField([1, 0, 0, 0, 1, 0, 0, 0, 0]),
  productionPlan:
    configureField([0, 0, 0, 1, 0, 0, 1, 0, 0]),
  product:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  stoppageType:
    configureField([0, 1, 0, 1, 0, 0, 1, 0, 0]),
  scrapType:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0]),
  scrapSubtype:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0]),
  remarks:
    configureField([1, 1, 1, 0, 0, 0, 0, 0, 0]),
  ekanbanReservationNumber:
    configureField([1, 0, 0, 0, 0, 0, 0, 0, 0]),
  predefinedRemark:
    configureField([0, 0, 0, 1, 1, 0, 1, 0, 0]),
  date:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  quantity:
    configureField([1, 0, 1, 0, 0, 0, 0, 0, 0]),
  scrapQuantity:
    configureField([1, 0, 0, 0, 0, 0, 0, 0, 0]),
  length:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0]),
  duration:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  manualDuration:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  changeoverDuration:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  priority:
    configureField([1, 1, 0, 0, 0, 0, 0, 0, 0]),
  startedAt:
    configureField([1, 1, 0, 1, 1, 0, 1, 0, 0]),
  engineeringFinishedAt:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  finishedAt:
    configureField([1, 1, 0, 1, 1, 0, 1, 0, 0]),
  reportedAt:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0]),
  division:
    configureField([0, 0, 0, 0, 0, 0, 0, 1, 1]),
  description:
    configureField([0, 0, 0, 0, 0, 0, 0, 1, 1]),
  resolution:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 1]),
  productionCallNumber:
    configureField([1, 0, 0, 0, 0, 0, 0, 0, 0]),
  batchNumber:
    configureField([0, 0, 0, 0, 0, 0, 0, 0, 0]),
  chargeNumber:
    configureField([0, 0, 1, 0, 0, 0, 0, 0, 0]),
  comment:
    configureField([0, 0, 0, 0, 0, 1, 0, 0, 0]),
  machinery:
    configureField([0, 0, 0, 1, 0, 0, 0, 0, 0]),
  responsible:
    configureField([0, 0, 0, 1, 0, 0, 0, 0, 0]),
  actionTaken:
    configureField([0, 0, 0, 1, 0, 0, 0, 0, 0])
}

const ActionButtons = ({ submit, cancel, isLoading, Submit }) => (
  <div className={classnames('workspace-form__menu')}>
    <Button onClick={submit} color='success' disabled={isLoading} isLoading={isLoading}>
      {Submit ? <Submit /> : <Icon name='check' />}
    </Button>
    <Button onClick={cancel} color='danger' disabled={isLoading} isLoading={isLoading}>
      <Icon name='times' />
    </Button>
  </div>
)

const BreakdownFinishActionsSubmit = () => (
  <FormattedMessage
    id='workspace.menu.finishBreakdown'
    defaultMessage='Resolve breakdown'
    description='Menu button that finishes a location breakdown'
  />
)

class WorkspaceForm extends React.Component {
  static defaultProps = {
    onCancel: () => {},
    onSubmit: () => {},
    data: {},
    locations: [],
    errors: {}
  }

  static propTypes = {
    intl: intlShape,
    type: FormTypes.propType.isRequired,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    data: PropTypes.object,
    locations: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })
    ),
    errors: PropTypes.object,
    isLoading: PropTypes.bool,
    mode: PropTypes.string
  }

  successfulMessage = () => {
    if (
      [FormTypes.PRODUCTION, FormTypes.STOPPAGE].includes(this.props.type) &&
      this.props.data.locationId
    ) {
      return this.props.intl.formatMessage(
        messages[this.props.type + 'Scheduled'],
        {
          location: this.props.locations.find(
            ({ id }) => id === this.props.data.locationId
          ).symbol,
          from: this.props.data.date
            .clone()
            .startOf('isoweek')
            .format(DATE_FORMAT),
          to: this.props.data.date
            .clone()
            .endOf('isoweek')
            .format(DATE_FORMAT)
        }
      )
    }
  }

  cancel = e => {
    e.preventDefault()
    this.props.onCancel()
  }

  submit = e => {
    e.preventDefault()
    this.props.onSubmit(this.props.data, this.successfulMessage())
  }

  isInputVisible = attr => FIELDS[attr][this.props.type]

  isPlanningMultiline = () => (this.props.mode === Modes.PLANNING && this.props.type === FormTypes.PRODUCTION) ||
                                (this.props.mode === Modes.REALIZATION && this.props.type === FormTypes.REALIZATION_STOPPAGE)
  isPlanningMultilineStyles = () => this.isPlanningMultiline() ? { marginLeft: 0 } : {}

  getSubmitIfBreakdownFinish = () => {
    if (this.props.type === FormTypes.BREAKDOWN_FINISH) {
      return BreakdownFinishActionsSubmit
    }
  }

  render () {
    const { type, isLoading } = this.props
    const unreported = type === FormTypes.REALIZATION_UNREPORTED

    return (
      <form className='workspace-form'>
        {this.isInputVisible('location') && <WorkspaceFormLocation />}
        {this.isInputVisible('project') && <WorkspaceFormProject />}
        {this.isInputVisible('productionPlan') && (
          <WorkspaceFormProductionPlan />
        )}
        {this.isInputVisible('product') && <WorkspaceFormProduct />}
        {this.isInputVisible('productionCallNumber') && (
          <WorkspaceFormProductionCallNumber />
        )}
        {this.isInputVisible('stoppageType') && <WorkspaceFormStoppageType />}
        {this.isInputVisible('date') && <WorkspaceFormDate />}
        {this.isInputVisible('batchNumber') && <WorkspaceFormBatchNumber />}
        {this.isInputVisible('chargeNumber') && <WorkspaceFormChargeNumber />}
        {this.isInputVisible('scrapType') && <WorkspaceFormScrapType />}
        {this.isInputVisible('scrapSubtype') && <WorkspaceFormScrapSubtype />}
        {this.isInputVisible('startedAt') && (
          <WorkspaceFormStartedAt disabled={unreported} />
        )}
        {this.isInputVisible('engineeringFinishedAt') && (
          <WorkspaceFormEngineeringFinishedAt />
        )}
        {this.isInputVisible('finishedAt') && (
          <WorkspaceFormFinishedAt disabled={unreported} />
        )}
        {this.isInputVisible('duration') && <WorkspaceFormDuration />}
        {this.isInputVisible('manualDuration') && (
          <WorkspaceFormManualDuration />
        )}
        {this.isInputVisible('changeoverDuration') && (
          <WorkspaceFormChangeoverDuration />
        )}
        { this.isPlanningMultiline() && <div style={{ width: '100%', marginTop: 5 }} /> }
        {this.isInputVisible('quantity') && <WorkspaceFormQuantity style={this.isPlanningMultilineStyles()} />}
        {this.isInputVisible('scrapQuantity') && <WorkspaceFormScrapQuantity />}
        {this.isInputVisible('comment') && <WorkspaceFormComment />}
        {this.isInputVisible('length') && <WorkspaceFormLength />}
        {this.isInputVisible('reportedAt') && <WorkspaceFormReportedAt />}
        {this.isInputVisible('division') && (
          <WorkspaceFormDivision disabled={!!this.props.data.id} />
        )}
        {this.isInputVisible('description') && (
          <WorkspaceFormDescription disabled={!!this.props.data.id} />
        )}
        {this.isInputVisible('resolution') && <WorkspaceFormResolution />}
        {this.isInputVisible('ekanbanReservationNumber') && <WorkspaceFormEkanbanReservationNumber /> }
        {this.isInputVisible('remarks') && (
          <div className='input input--nested workspace-form__control' style={{ display: 'flex', minWidth: 180 }}>
            {this.isInputVisible('priority') && <WorkspaceFormPriority /> }
            <WorkspaceFormRemarks />
            <ActionButtons submit={this.submit} cancel={this.cancel} isLoading={isLoading} isMultiline={false} />
          </div>
        )}
        {this.isInputVisible('predefinedRemark') && (
          <WorkspaceFormPredefinedRemark style={this.isPlanningMultilineStyles()} />
        )}
        {this.isInputVisible('machinery') && (
          <WorkspaceFormMachinery />
        )}
        {this.isInputVisible('responsible') && (
          <WorkspaceFormResponsible />
        )}
        {this.isInputVisible('actionTaken') && (
          <WorkspaceFormActionTaken />
        )}

        {!this.isInputVisible('remarks') && (
          <ActionButtons
            submit={this.submit}
            cancel={this.cancel}
            isLoading={isLoading}
            Submit={this.getSubmitIfBreakdownFinish()}
          />
        )}
        {this.props.errors.base && (
          <div className='workspace-form__base-error'>
            {(this.props.errors.base || [])[0]}
          </div>
        )}
      </form>
    )
  }
}

const mapStateToProps = state => ({
  locations: selectLocations(state),
  errors: state.form.errors,
  type: state.form.type,
  data: state.form.data,
  isLoading: state.form.isLoading,
  mode: selectMode(state)
})

const mapDispatchToProps = {
  onSubmit: submitForm,
  onCancel: closeForm
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(WorkspaceForm)
