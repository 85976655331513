import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import Input from 'components/Input'
import { selectProductionPlansForProductionRun } from 'workspace/selectors'
import { changeProductionRunFormData } from './actions'
import {
  selectProductionRunFormErrors,
  selectCurrentProductionRun
} from './selectors'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.planLabel',
    defaultMessage: 'Production plan'
  },
  selectLabel: {
    id: 'workspace.menu.productionRunForm.planSelectLabel',
    defaultMessage: 'Production plan select'
  },
  unknownPlan: {
    id: 'workspace.form.unknownPlan',
    defaultMessage: 'Unknown Plan',
    description:
      'Message displayed when the selected production plan is not visible on the current timeline'
  },
  productLength: {
    id: 'workspace.menu.productionRunForm.lengthLabel',
    defaultMessage: 'Length [mm]',
    description: 'Lenght of product description'
  }
})

const NO_ERRORS = []

const ProductionRunFormProductionPlan = ({
  id,
  intl,
  productionPlans,
  onChange,
  error,
  readOnly
}) => (
  <Input
    label={intl.formatMessage(messages.label)}
    attribute='productionPlan'
    error={error}
    nested
    className='workspace-form__control'
  >
    <AssociationSelect
      isClearable
      isDisabled={readOnly}
      options={
        !productionPlans.find(p => p.id === id) && id
          ? [...productionPlans, { id }]
          : productionPlans
      }
      value={id}
      onChange={(newId, _action, data) =>
        onChange({
          productionPlanId: newId,
          projectId: data ? data.projectId : null
        })
      }
      labelMapper={plan =>
        plan.productionCallNumber
          ? intl.formatMessage(messages.selectLabel, {
            productionCallNumber: plan.productionCallNumber,
            profile: plan.productProfile,
            productLength: plan.productLength,
            from: plan.formattedStartedAt,
            to: plan.formattedFinishedAt
          })
          : intl.formatMessage(messages.unknownPlan)
      }
    />
  </Input>
)

ProductionRunFormProductionPlan.propTypes = {
  intl: intlShape.isRequired,
  productionPlans: PropTypes.arrayOf(
    PropTypes.shape({
      projectName: PropTypes.string,
      id: PropTypes.number
    })
  ),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  readOnly: PropTypes.bool
}

const mapStateToProps = state => {
  const readOnly = !!selectCurrentProductionRun(state)
  const productionPlans = selectProductionPlansForProductionRun(state)

  return {
    productionPlans,
    id: state.productionRunForm.data.productionPlanId,
    error: (selectProductionRunFormErrors.productionPlan || NO_ERRORS)[0],
    readOnly
  }
}

const mapDispatchToProps = {
  onChange: changeProductionRunFormData
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProductionRunFormProductionPlan)
