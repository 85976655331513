import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Barcode from 'react-barcode'
import Modal from 'components/Modal'
import { showRealizationBarcode } from 'workspace/actions'
import { selectBarcodeModalProduct } from 'workspace/selectors'
import { FormattedMessage } from 'react-intl'

const WorkspaceBarcodeModal = ({ product, hide }) => {
  if (product) {
    return (
      <Modal onClose={hide} className='label-modal'>
        <div className='label-modal__line'>
          <FormattedMessage id='workspace.realization.tooltip.barcode.huCard' />
        </div>
        <Barcode
          value={product.code}
          format='CODE39'
          height={200}
          width={2}
          displayValue
        />
      </Modal>
    )
  } else {
    return null
  }
}

WorkspaceBarcodeModal.propTypes = {
  product: PropTypes.shape({
    code: PropTypes.string.isRequired
  })
}

const mapStateToProps = state => ({
  product: selectBarcodeModalProduct(state)
})

const mapDispatchToProps = {
  hide: () => showRealizationBarcode(null)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceBarcodeModal)
