import React from 'react'
import PropTypes from 'prop-types'
import Unit from 'components/Unit'

const PlanTimelineRealization = ({ piecesQuantity, realizedQuantity }) => {
  if (realizedQuantity === null || piecesQuantity === 0) {
    return '---'
  } else {
    return (
      <React.Fragment>
        {(100 * realizedQuantity / piecesQuantity).toFixed(0)}% (<Unit value={realizedQuantity} unit='piece' />)
      </React.Fragment>
    )
  }
}

PlanTimelineRealization.propTypes = {
  realizedQuantity: PropTypes.number,
  piecesQuantity: PropTypes.number.isRequired
}

export default PlanTimelineRealization
