import { call, put, select, spawn, delay } from 'redux-saga/effects'
import api from 'lib/api/client'
import {
  receiveActiveness,
  addActivenessErrors,
  deleteFinishedActiveness
} from 'workspace/actions'
import { selectToken } from 'workspace/selectors'
import {
  selectActivenessFormParams,
  selectCurrentActiveness
} from '../selectors'
import { requestActiveness } from '../actions'

export function * start () {
  const token = yield select(selectToken)
  const params = yield select(selectActivenessFormParams)

  try {
    const activeness = yield call(api.post, 'activeness_periods', params, {
      token
    })
    yield put(receiveActiveness(activeness))
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addActivenessErrors(e.response.data.errors))
    } else {
      throw e
    }
  }
}

export function * requestCurrentActiveness () {
  yield delay(250)

  const token = yield select(selectToken)
  const currentActiveness = yield select(selectCurrentActiveness)

  if (currentActiveness) {
    yield put(requestActiveness())
    const currentActivenessDetails = yield call(
      api.get,
      `/activeness_periods/${currentActiveness.id}`,
      { token }
    )

    yield put(receiveActiveness(currentActivenessDetails))
  }
}

export function * update ({ payload }) {
  const token = yield select(selectToken)
  const params = yield select(selectActivenessFormParams)

  try {
    const activeness = yield call(
      api.put,
      `/activeness_periods/${payload.id}`,
      params,
      { token }
    )
    yield put(receiveActiveness(activeness))
    if (payload.finishAfter) {
      yield spawn(finish, { payload })
    } else {
      const closeFunc = window.closeActivenessFinishForm
      closeFunc && closeFunc()
    }
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addActivenessErrors(e.response.data.errors))
    } else {
      throw e
    }
  }
}

export function * finish ({ payload: { id, ...params } }) {
  const token = yield select(selectToken)

  try {
    const activeness = yield call(
      api.put,
      'activeness_periods/' + id + '/finish',
      params,
      { token }
    )
    yield put(deleteFinishedActiveness(activeness))
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addActivenessErrors(e.response.data.errors))
    } else {
      throw e
    }
  }
}
