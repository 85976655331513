import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormDuration = ({ duration, onChange }) => (
  <WorkspaceFormInput attribute='duration'>
    <input
      type='number'
      className='workspace-form__input'
      value={duration || ''}
      onChange={(e) => onChange({ duration: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormDuration.propTypes = {
  duration: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  duration: state.form.data.duration
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormDuration)
