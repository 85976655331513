import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProductionRunFormData, selectProductionPlansForRealization } from './selectors'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.quantityLabel',
    defaultMessage: 'Quantity'
  }
})

const ProductionRunFormQuantity = ({ id, intl, quantity }) => (
  <Input
    nested
    label={intl.formatMessage(messages.label)}
    className='workspace-form__control'
  >
    <input
      type='text'
      className='workspace-form__input'
      style={{ width: '100%', boxSizing: 'border-box' }}
      value={quantity || ''}
      readOnly
    />
  </Input>
)

ProductionRunFormQuantity.propTypes = {
  intl: intlShape.isRequired,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number
}

const mapStateToProps = state => {
  const id = selectProductionRunFormData(state).productionPlanId
  const plan = selectProductionPlansForRealization(state).find(e => e.id === id)

  return {
    quantity: (plan || {}).quantity
  }
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ProductionRunFormQuantity)
