import React from 'react'
import Input from 'components/Input'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import DatePicker from 'components/DatePicker'
import {
  selectProductionRunFormLastGoodPartAt,
  selectProductionRunFormErrors
} from './selectors'
import { changeProductionRunFormData } from './actions'
import moment from 'moment'
import classnames from 'classnames'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.lastPartLabel',
    defaultMessage: 'Date'
  }
})

const ProductionRunLastGoodPartAt = ({
  intl,
  onChange,
  lastGoodPartAt,
  error,
  persisted
}) => (
  <Input
    label={intl.formatMessage(messages.label)}
    error={error}
    nested
    className={classnames({
      'workspace-form__control': true,
      'input--accented': !error && persisted
    })}
    style={{ maxWidth: 200, marginLeft: 79 }}
  >
    <DatePicker
      className='workspace-form__input'
      timeIntervals={1}
      showTimeSelect
      precision='minute'
      onChange={newValue => onChange({ lastGoodPartAt: newValue })}
      selected={lastGoodPartAt}
    />
  </Input>
)

ProductionRunLastGoodPartAt.propTypes = {
  intl: intlShape.isRequired,
  lastGoodPartAt: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  persisted: PropTypes.bool
}

const mapStateToProps = state => {
  const { date, persisted } = selectProductionRunFormLastGoodPartAt(state)

  return {
    lastGoodPartAt: date ? moment(date) : null,
    persisted,
    error: (selectProductionRunFormErrors(state).lastGoodPartAt || [])[0]
  }
}

const mapDispatchToProps = dispatch => ({
  onChange: data =>
    dispatch(
      changeProductionRunFormData({
        ...data,
        lastGoodPartAtDirty: true
      })
    )
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProductionRunLastGoodPartAt)
