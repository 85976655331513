import React from 'react'
import { connect } from 'react-redux'
import PlanMenu from 'workspace/PlanMenu'
import RealizationMenu from 'workspace/RealizationMenu'
import { selectMode } from 'workspace/selectors'
import { Modes } from 'workspace/const'

const WorkspaceMenu = ({ mode }) => (
  mode === 'realization' ? <RealizationMenu /> : <PlanMenu />
)

WorkspaceMenu.propTypes = {
  mode: Modes.propType
}

const mapStateToProps = state => ({
  mode: selectMode(state)
})

export default connect(mapStateToProps)(WorkspaceMenu)
