import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'
import DatePicker from 'components/DatePicker'

const WorkspaceFormFinishedAt = ({ finishedAt, onChange, disabled }) => (
  <WorkspaceFormInput attribute='finishedAt'>
    <DatePicker
      className='workspace-form__input'
      selected={finishedAt}
      onChange={value => onChange({ finishedAt: value })}
      showTimeSelect
      timeIntervals={1}
      precision='minute'
      disabled={disabled}
    />
  </WorkspaceFormInput>
)

WorkspaceFormFinishedAt.propTypes = {
  finishedAt: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

const mapStateToProps = state => ({
  finishedAt: state.form.data.finishedAt
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormFinishedAt)
