import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProductionRunFormData, selectProductionRunFormErrors } from './selectors'
import { changeProductionRunFormData } from './actions'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.productionSpeedLabel',
    defaultMessage: 'Speed'
  }
})

const NO_ERRORS = []

const ProductionRunFormProductionSpeed = ({ intl, productionSpeed, onChange, error }) => (
  <Input
    nested
    error={error}
    label={intl.formatMessage(messages.label)}
    className='workspace-form__control'
    style={{ maxWidth: 200, marginLeft: 79, boxSizing: 'border-box' }}
  >
    <input
      onChange={e => onChange({ productionSpeed: e.target.value })}
      type='number'
      className='workspace-form__input'
      style={{ height: 38 }}
      value={productionSpeed || ''}
    />
  </Input>
)

ProductionRunFormProductionSpeed.propTypes = {
  intl: intlShape.isRequired,
  error: PropTypes.string,
  productionSpeed: PropTypes.string,
  onChange: PropTypes.func
}

const mapStateToProps = state => {
  return {
    productionSpeed: selectProductionRunFormData(state).productionSpeed,
    error: (selectProductionRunFormErrors(state).productionSpeed || NO_ERRORS)[0]
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChange: data =>
    dispatch(changeProductionRunFormData(data))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProductionRunFormProductionSpeed)
