import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

class TimelineExtraContent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      outerWidth: 0,
      innerWidth: 0
    }

    this.outerRef = React.createRef()
    this.innerRef = React.createRef()
  }

  componentDidUpdate () {
    if (!this.outerRef.current || !this.innerRef.current) {
      return
    }

    if (this.state.outerWidth === this.outerRef.current.offsetWidth && this.state.innerWidth === this.innerRef.current.offsetWidth) {
      return
    }

    this.setState({
      outerWidth: this.outerRef.current.offsetWidth,
      innerWidth: this.innerRef.current.offsetWidth
    })
  }

  render () {
    const element = document.getElementById(this.props.containerId);
    if (!element) {
      return null
    }

    return ReactDOM.createPortal(
      <div
        ref={this.outerRef}
        className='timeline-extra-content'
        style={{
          '--translate': -(Math.max(this.state.innerWidth - this.state.outerWidth, 0)) + 'px',
          '--duration': ((this.state.innerWidth - this.state.outerWidth) / 50) + 's',
          backgroundColor: 'yellow',
          padding: 2
        }}
      >
        <div
          ref={this.innerRef}
          className='timeline-extra-content__inner'
        >
          {this.props.children}
        </div>
      </div>,
      document.getElementById(this.props.containerId)
    )
  }
}

TimelineExtraContent.propTypes = {
  children: PropTypes.node.isRequired
}

export default TimelineExtraContent
