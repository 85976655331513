import React from 'react'
import PropTypes from 'prop-types'
import DropdownButton from 'components/DropdownButton'
import Icon from 'components/Icon'
import Badge from 'components/Badge'
import { selectShiftBookNoteUnreadCount } from './selectors'
import RealizationMenuShiftBookNotes from './RealizationMenuShiftBookNotes'
import { connect } from 'react-redux'
import { openShiftBook } from './actions'

const dropDownStyle = { fontSize: 21 }
const badgeStyle = { marginLeft: 9 }

class RealizationMenuShiftBookDropdown extends React.Component {
  constructor (props) {
    super(props)

    this.dropdownRef = React.createRef()
  }

  componentDidMount () {
    const url = new URL(window.location.href)
    const topicId = url.searchParams.get('topic_id')

    if (topicId) {
      this.dropdownRef.current && this.dropdownRef.current.openMenu()
      this.props.onClick && this.props.onClick('open')
    }
  }

  render () {
    const { unreadCount, onClick } = this.props

    return (
      <DropdownButton
        ref={this.dropdownRef}
        color='blue'
        dropdownColor='blue'
        className='workspace-menu__element'
        style={dropDownStyle}
        onClick={onClick}
        popperConfig={{ placement: 'bottom-end' }}
        content={({ close }) => (
          <RealizationMenuShiftBookNotes onClose={close} />
        )}
      >
        <div className='flex-center'>
          <Icon name='file-text-o' />
          {!!unreadCount && <Badge text={unreadCount} size={21} style={badgeStyle} />}
        </div>
      </DropdownButton>
    )
  }
}

RealizationMenuShiftBookDropdown.propTypes = {
  unreadCount: PropTypes.number,
  onClick: PropTypes.func
}

const mapStateToProps = state => {
  return {
    unreadCount: selectShiftBookNoteUnreadCount(state)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClick: eventType =>
      eventType === 'open' && dispatch(openShiftBook())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationMenuShiftBookDropdown)
