import React from 'react'
import moment from 'moment-timezone'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectMode, selectTimezone } from 'workspace/selectors'
import {
  selectNavigationRange,
  selectNavigationShift
} from 'containers/Navigation/selectors'
import { Modes } from 'workspace/const'

class WorkspaceNavigationNotCurrentDateAlert extends React.Component {
  shouldComponentUpdate (nextProps) {
    return !(
      this.props.navigationRange.from.isSame(nextProps.navigationRange.from) &&
      this.props.navigationRange.to.isSame(nextProps.navigationRange.to) &&
      this.props.mode === nextProps.mode &&
      this.props.timezone === nextProps.timezone
    )
  }

  render () {
    const { mode, timezone, scope, shift } = this.props
    const navigationRange = this.props.navigationRange

    if (mode !== Modes.REALIZATION) {
      return null
    }

    const now = moment.tz(timezone)
    if (now.isBetween(navigationRange.from, navigationRange.to) && !shift) {
      return null
    }

    const today = now.format('DMMYYYY')
    if (shift && navigationRange.from.format('DMMYYYY') === today) {
      return null
    }

    if (scope === 'day') {
      return (
        <FormattedMessage
          id='workspace.navigation.notCurrentDayAlert'
          defaultMessage='This is not the current day'
        />
      )
    } else {
      return (
        <FormattedMessage
          id='workspace.navigation.notCurrentWeekAlert'
          defaultMessage='This is not the current week'
        />
      )
    }
  }
}

WorkspaceNavigationNotCurrentDateAlert.propTypes = {
  mode: Modes.propType,
  timezone: PropTypes.string,
  navigationRange: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object
  }),
  scope: PropTypes.oneOf(['day', 'week']),
  shift: PropTypes.number
}

const mapStateToProps = state => ({
  mode: selectMode(state),
  timezone: selectTimezone(state),
  navigationRange: selectNavigationRange(state),
  scope: state.navigation.scope,
  shift: selectNavigationShift(state)
})

export default connect(mapStateToProps)(WorkspaceNavigationNotCurrentDateAlert)
