import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ShiftNavigationButton from 'workspace/ShiftNavigationButton'
import ToggleButton from 'components/ToggleButton'

class Shift extends Component {
  setShift = number => {
    if (this.props.disabled) {
      return
    }

    this.props.setShift(number)
  }

  setAllShifts = e => this.setShift(null)

  render () {
    const { disabled, shift, setShift, ts } = this.props

    return (
      <Fragment>
        <ShiftNavigationButton
          shift={1}
          currentShift={shift}
          disabled={disabled}
          setShift={setShift}
          ts={ts}
        />
        <ShiftNavigationButton
          shift={2}
          currentShift={shift}
          disabled={disabled}
          setShift={setShift}
          ts={ts}
        />
        <ShiftNavigationButton
          shift={3}
          currentShift={shift}
          disabled={disabled}
          setShift={setShift}
          ts={ts}
        />
        <ToggleButton
          color='default'
          isToggled={!shift}
          onToggleOn={this.setAllShifts}
          disabled={disabled}
        >
          1 - 3
        </ToggleButton>
      </Fragment>
    )
  }
}

Shift.propTypes = {
  shift: PropTypes.oneOf([1, 2, 3]),
  disabled: PropTypes.bool,
  setShift: PropTypes.func.isRequired,
  ts: PropTypes.number
}

export default Shift
