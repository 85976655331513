import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProduct } from './selectors'

const NO_PRODUCT = {}

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.profileLabel',
    defaultMessage: 'Profile'
  }
})

const ProductionRunFormProfile = ({ intl, profile }) => (
  <Input
    nested
    label={intl.formatMessage(messages.label)}
    className='workspace-form__control'
  >
    <input
      type='text'
      className='workspace-form__input'
      style={{ width: '100%', boxSizing: 'border-box' }}
      value={profile || ''}
      readOnly
    />
  </Input>
)

ProductionRunFormProfile.propTypes = {
  intl: intlShape.isRequired,
  profile: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

const mapStateToProps = state => {
  const id = state.productionRunForm.data.productId

  return {
    profile: (selectProduct(state, id) || NO_PRODUCT).profile
  }
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ProductionRunFormProfile)
