import React from 'react'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectCurrentActiveness,
  selectShiftBookNoteUnreadCount,
  selectShiftBookEditingNoteId,
  selectShiftBookReplyingNoteId
} from './selectors'
import {
  submitEditShiftBookNote,
  cancelEditShiftBookNote,
  submitReplyShiftBookNote,
  cancelReplyShiftBookNote,
  createShiftBookNote,
  markAllShiftBookNotesAsRead
} from './actions'
import { FormattedMessage } from 'react-intl'

const RealizationMenuShiftBookActions = ({
  isEditing,
  isReplying,
  hasAnyUnread,
  hasActiveness,
  onSave,
  onEdit,
  onEditCancel,
  onReply,
  onReplyCancel,
  markAllAsRead
}) => {
  return (
    <React.Fragment>
      {hasAnyUnread && (
        <Button
          size='small'
          onClick={markAllAsRead}
          className='shift-book-notes__read-all'
        >
          <FormattedMessage id='workspace.menu.shiftBook.markAllAsRead' />
        </Button>
      )}
      {hasActiveness && !isEditing && !isReplying && (
        <Button size='small' onClick={onSave}>
          <FormattedMessage id='workspace.menu.shiftBook.save' />
        </Button>
      )}
      {isEditing && (
        <React.Fragment>
          <Button size='small' onClick={onEditCancel}>
            <FormattedMessage id='workspace.menu.shiftBook.cancelEdit' />
          </Button>
          <Button size='small' onClick={onEdit}>
            <FormattedMessage id='workspace.menu.shiftBook.edit' />
          </Button>
        </React.Fragment>
      )}
      {isReplying && (
        <React.Fragment>
          <Button size='small' onClick={onReplyCancel}>
            <FormattedMessage id='workspace.menu.shiftBook.cancelReply' />
          </Button>
          <Button size='small' onClick={onReply}>
            <FormattedMessage id='workspace.menu.shiftBook.reply' />
          </Button>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RealizationMenuShiftBookActions.propTypes = {
  isEditing: PropTypes.bool,
  isReplying: PropTypes.bool,
  hasAnyUnread: PropTypes.bool,
  hasActiveness: PropTypes.bool,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onEditCancel: PropTypes.func,
  onReply: PropTypes.func,
  onReplyCancel: PropTypes.func,
  markAllAsRead: PropTypes.func
}

const mapStateToProps = state => {
  return {
    isEditing: !!selectShiftBookEditingNoteId(state),
    isReplying: !!selectShiftBookReplyingNoteId(state),
    hasAnyUnread: !!selectShiftBookNoteUnreadCount(state),
    hasActiveness: selectCurrentActiveness(state) !== null
  }
}

const mapDispatchToProps = {
  onSave: createShiftBookNote,
  onEdit: submitEditShiftBookNote,
  onEditCancel: cancelEditShiftBookNote,
  onReply: submitReplyShiftBookNote,
  onReplyCancel: cancelReplyShiftBookNote,
  markAllAsRead: markAllShiftBookNotesAsRead
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationMenuShiftBookActions)
