import React from 'react'
import PropTypes from 'prop-types'
import RealizationMenuUserInput from './RealizationMenuUserInput'
import RealizationMenuUserRoleInput from './RealizationMenuUserRoleInput'
import RealizationMenuUserStartedAtInput from './RealizationMenuUserStartedAtInput'
import RealizationMenuUserFinishedAtInput from './RealizationMenuUserFinishedAtInput'

const RealizationMenuUserWorkPeriodForm = ({ idx, withFinishedAt }) => (
  <form
    style={{
      margin: '0 2px',
      padding: 5,
      backgroundColor: 'white',
      width: 'initial'
    }}
    className='workspace-form'
  >
    <RealizationMenuUserInput idx={idx} />
    <RealizationMenuUserRoleInput idx={idx} />
    <RealizationMenuUserStartedAtInput idx={idx} />
    {withFinishedAt && <RealizationMenuUserFinishedAtInput idx={idx} />}
  </form>
)

RealizationMenuUserWorkPeriodForm.propTypes = {
  idx: PropTypes.number,
  withFinishedAt: PropTypes.bool
}

export default RealizationMenuUserWorkPeriodForm
