import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProductionRunFormData, selectProductionRunFormErrors } from './selectors'
import { changeProductionRunFormData } from './actions'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.remarksLabel',
    defaultMessage: 'Remarks'
  }
})

const NO_ERRORS = []

const ProductionRunFormRemarks = ({ intl, remarks, onChange, error }) => (
  <Input
    nested
    label={intl.formatMessage(messages.label)}
    className='workspace-form__control'
  >
    <input
      error={error}
      onChange={e => onChange({ remarks: e.target.value })}
      type='text'
      className='workspace-form__input'
      style={{ width: '100%', boxSizing: 'border-box' }}
      value={remarks || ''}
    />
  </Input>
)

ProductionRunFormRemarks.propTypes = {
  intl: intlShape.isRequired,
  error: PropTypes.string,
  remarks: PropTypes.string,
  onChange: PropTypes.func
}

const mapStateToProps = state => {
  return {
    remarks: selectProductionRunFormData(state).remarks,
    error: (selectProductionRunFormErrors.remarks || NO_ERRORS)[0]
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChange: data =>
    dispatch(changeProductionRunFormData(data))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ProductionRunFormRemarks)
