import React from 'react'
import PropTypes from 'prop-types'
import { Manager, Reference, Popper } from 'react-popper'
import classnames from 'classnames'
import Button from 'components/Button'

const popperConfig = {
  placement: 'bottom-center',
  modifiers: {
    preventOverflow: {
      enabled: true,
      boundariesElement: 'window'
    }
  }
}

class DropdownButton extends React.Component {
  state = { isOpen: false }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef = ref => {
    this.wrapperRef = ref
    return true
  }

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target) && this.state.isOpen) {
      this.closeMenu()
    }
  }

  toggleMenu = e => {
    e && e.preventDefault()

    const { onClick } = this.props

    if (this.state.isOpen) {
      onClick && onClick('close')
      this.closeMenu()
    } else {
      onClick && onClick('open')
      this.openMenu()
    }
  }

  openMenu = e => {
    e && e.preventDefault()
    this.setState({ isOpen: true })
    this.props.onStateChanged && this.props.onStateChanged(true)
  }

  closeMenu = e => {
    e && e.preventDefault()
    this.setState({ isOpen: false })
    this.props.onStateChanged && this.props.onStateChanged(false)
  }

  render () {
    const { content, dropdownColor, ...rest } = this.props

    return (
      <div ref={this.setWrapperRef} className={this.props.className}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                <Button {...rest} onClick={this.toggleMenu} />
              </div>
            )}
          </Reference>

          {
            this.state.isOpen &&
            <Popper {...popperConfig} {...this.props.popperConfig}>

              {({ ref, style, placement, arrowProps }) => (
                <div
                  className={classnames('btn-dropdown', 'btn-dropdown--popper', `btn-dropdown--${dropdownColor}`)}
                  ref={ref}
                  style={style}
                  data-placement={placement}
                >
                  { content({ close: this.closeMenu, open: this.openMenu }) }
                  <div className='btn-dropdown__arrow' ref={arrowProps.ref} style={arrowProps.style} />
                </div>
              )}
            </Popper>
          }
        </Manager>
      </div>
    )
  }
}

DropdownButton.defaultProps = {
  color: 'success',
  dropdownColor: 'default'
}

DropdownButton.propTypes = {
  ...Button.propTypes,
  dropdownColor: Button.propTypes.color,
  popperConfig: PropTypes.object,
  content: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onStateChanged: PropTypes.func
}

export default DropdownButton
