import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectProductionRunFormDataComponent } from './selectors'

const ProductionRunFormComponentGear = ({ idx, name }) => (
  <b
    style={{
      alignSelf: 'center',
      width: 64,
      textAlign: 'right'
    }}
  >
    {name}
  </b>
)

ProductionRunFormComponentGear.propTypes = {
  idx: PropTypes.number,
  name: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const { gearName } = selectProductionRunFormDataComponent(state, ownProps.idx)

  return {
    name: gearName
  }
}

export default connect(mapStateToProps)(ProductionRunFormComponentGear)
