import { put, select, call } from 'redux-saga/effects'
import {
  selectAvailableProductsForProductionRun,
  selectToken,
  selectProductionRunFormParams,
  selectCurrentProductionRun
} from '../selectors'
import Actions, {
  changeProductionRunFormData,
  receiveProductionRun,
  addProductionRunErrors,
  deleteFinishedProductionRun,
  deleteProductionRunErrors,
  initProductionRunForm as initProductionRunFormAction,
  setFirstGoodPart,
  setLastGoodPart
} from '../actions'
import api from 'lib/api/client'
import { DEFAULT_PRODUCTION_RUN_FORM_STATE } from '../reducers'

export function * initProductionRunForm () {
  const currentProductionRun = yield select(selectCurrentProductionRun)

  if (currentProductionRun) {
    const { productId, id } = currentProductionRun
    const token = yield select(selectToken)
    const productionRunComponents = yield call(
      api.get,
      '/production_runs/form',
      { token, params: { productId, productionRunId: id } }
    )
    yield put(
      initProductionRunFormAction({
        ...DEFAULT_PRODUCTION_RUN_FORM_STATE,
        data: {
          ...DEFAULT_PRODUCTION_RUN_FORM_STATE.data,
          ...currentProductionRun,
          productionRunComponents
        }
      })
    )
  } else {
    yield put(initProductionRunFormAction(DEFAULT_PRODUCTION_RUN_FORM_STATE))
  }
}

export function * productionRunFormChange (action) {
  const { productId, productionRunId } = yield select(
    state => state.productionRunForm.data
  )

  if (action.payload.firstGoodPartAt) {
    yield put(setFirstGoodPart())
    return
  }

  if (action.payload.lastGoodPartAt) {
    yield put(setLastGoodPart())
    return
  }

  const availableProduct = (yield select(
    selectAvailableProductsForProductionRun
  ) || [])[0]
  const newProductId = availableProduct && availableProduct.id
  if (newProductId && newProductId !== productId) {
    yield put(
      changeProductionRunFormData({
        productId: newProductId,
        productionRunComponents: []
      })
    )

    const token = yield select(selectToken)
    const productionRunComponents = yield call(
      api.get,
      `/production_runs/form?ts=${+(new Date())}`,
      { token, params: { productId: availableProduct.id, productionRunId } }
    )
    yield put(changeProductionRunFormData({ productionRunComponents }))
    yield put(deleteProductionRunErrors())
  }
}

export function * productionRunFormSubmit (action) {
  const token = yield select(selectToken)
  let params = yield select(selectProductionRunFormParams)
  let closeOnDone = true

  try {
    const apiMethod = params.id ? api.put : api.post
    const apiPath = params.id
      ? `production_runs/${params.id}`
      : 'production_runs'

    if (action.type === Actions.SET_FIRST_GOOD_PART) {
      const { id, firstGoodPartAt } = params
      params = { id, firstGoodPartAt }
      closeOnDone = false
    }

    if (action.type === Actions.SET_LAST_GOOD_PART) {
      const { id, lastGoodPartAt } = params
      params = { id, lastGoodPartAt }
      closeOnDone = false
    }

    const productionRun = yield call(apiMethod, apiPath, params, {
      token
    })
    yield put(receiveProductionRun(productionRun))
    closeOnDone &&
      window.closeProductionRunForm &&
      window.closeProductionRunForm()
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addProductionRunErrors(e.response.data.errors))
    } else {
      throw e
    }
  }
}

export function * finish ({ payload: id }) {
  try {
    const token = yield select(selectToken)
    const params = yield select(selectProductionRunFormParams)
    const productionRun = yield call(
      api.put,
      `production_runs/${id}/finish`,
      params,
      { token }
    )

    yield put(deleteFinishedProductionRun(productionRun))
    window.closeProductionRunForm && window.closeProductionRunForm()
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addProductionRunErrors(e.response.data.errors))
    } else {
      throw e
    }
  }
}
