import React from 'react'
import PropTypes from 'prop-types'
import Unit from 'components/Unit'

const PlanTimelineComponentRealization = ({
  piecesQuantity,
  realizedQuantity,
  realizedScrapQuantity,
  quantity,
  unit
}) => {
  if (realizedQuantity === null || piecesQuantity === 0) {
    return '---'
  } else {
    const toRealizeAmount = piecesQuantity * quantity
    const realizedAmount = (realizedQuantity + realizedScrapQuantity) * quantity
    const realizedPercentage = (
      (realizedAmount / toRealizeAmount) *
      100
    ).toFixed(0)

    return (
      <React.Fragment>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            background: '#e6e6e6',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: `${Math.min(realizedPercentage, 100)}%`,
              background: 'orange'
            }}
          />
          <span style={{ zIndex: 10 }}>
            {realizedAmount.toFixed(0)} / <Unit value={toRealizeAmount.toFixed(0)} unit={unit} />
          </span>
        </div>
      </React.Fragment>
    )
  }
}

PlanTimelineComponentRealization.propTypes = {
  realizedQuantity: PropTypes.number,
  piecesQuantity: PropTypes.number,
  quantity: PropTypes.number,
  unit: PropTypes.string
}

export default PlanTimelineComponentRealization
