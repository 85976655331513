import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormComment = ({ comment, onChange, style }) => (
  <WorkspaceFormInput attribute='comment' extraErrorsAttribute='comment' style={style}>
    <input
      className='workspace-form__input'
      value={comment}
      onChange={(e) => onChange({ comment: e.target.value })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormComment.propTypes = {
  comment: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
}

WorkspaceFormComment.defaultProps = {
  style: {}
}

const mapStateToProps = (state) => ({
  comment: state.form.data.comment
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormComment)
