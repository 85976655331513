import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectProductionPlansForRealization } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noProductionPlans: {
    id: 'workspace.form.noProductionPlans',
    defaultMessage: 'No matching production plans',
    description: 'Message displayed when there are no production plans for the selected location or search term'
  },
  unknownPlan: {
    id: 'workspace.form.unknownPlan',
    defaultMessage: 'Unknown Plan',
    description: 'Message displayed when the selected production plan is not visible on the current timeline'
  }
})

const WorkspaceFormProductionPlan = ({ id, intl, productionPlans, onChange }) => (
  <WorkspaceFormInput attribute='productionPlan'>
    <AssociationSelect
      isClearable
      options={!productionPlans.find(p => p.id === id) && id ? [...productionPlans, { id }] : productionPlans}
      value={id}
      onChange={(newId, _action, data) => onChange({ productionPlanId: newId, projectId: data ? data.projectId : null })}
      labelMapper={plan => plan.productionCallNumber || intl.formatMessage(messages.unknownPlan)}
      noOptionsMessage={() => intl.formatMessage(messages.noProductionPlans)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormProductionPlan.propTypes = {
  intl: intlShape.isRequired,
  productionPlans: PropTypes.arrayOf(PropTypes.shape({
    projectName: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  productionPlans: selectProductionPlansForRealization(state),
  id: state.form.data.productionPlanId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormProductionPlan)
