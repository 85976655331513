import React from 'react'
import PropTypes from 'prop-types'
import Loader from 'components/Loader'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  selectCurrentActiveness,
  selectShiftBookNoteRootIds,
  selectShiftBookNoteLoading,
  selectShiftBookEditingNoteId,
  selectShiftBookReplyingNoteId
} from './selectors'
import RealizationMenuShiftBookNote from './RealizationMenuShiftBookNote'
import { selectNavigationRangeDates } from 'containers/Navigation/selectors'
import { createShiftBookNote } from './actions'
import RealizationMenuShiftBookDraft from './RealizationMenuShiftBookDraft'
import RealizationMenuShiftBookActions from './RealizationMenuShiftBookActions'
import { FormattedMessage } from 'react-intl'

const RealizationMenuShiftBookNotes = ({ dateRange, hasActiveness, isEditing, isReplying, onClose, noteIds, loading }) => {
  return (
    <div className='shift-book-notes'>
      <div className='shift-book-notes__actions'>
        <span className='shift-book-notes__title'>
          <span className='shift-book-notes__title--accented'>
            <FormattedMessage id='workspace.menu.shiftBook.title' />
            &nbsp;
            (
            {dateRange.from !== dateRange.to && <React.Fragment>{dateRange.from} - {dateRange.to}</React.Fragment>}
            {dateRange.from === dateRange.to && <React.Fragment>{dateRange.from}</React.Fragment>}
            )
          </span>
        </span>
        <div className='shift-book-notes__close' onClick={onClose} />
      </div>
      <div className='shift-book-notes__notes'>
        {loading && <Loader transparent full />}
        {noteIds.map(id => (
          <RealizationMenuShiftBookNote id={id} key={id} />
        ))}
      </div>
      <div className='shift-book-notes__notebook'>
        <React.Fragment>
          {hasActiveness || isEditing ? <RealizationMenuShiftBookDraft /> : (
            <div className='shift-book-notes__activeness-error-container'>
              <p className='shift-book-notes__activeness-error'>
                <FormattedMessage id='workspace.menu.shiftBook.noActiveness' />
              </p>
            </div>
          )}
          <div className='shift-book-notes__notebook-actions'>
            <RealizationMenuShiftBookActions />
          </div>
        </React.Fragment>
      </div>
    </div>
  )
}

RealizationMenuShiftBookNotes.propTypes = {
  dateRange: PropTypes.object,
  hasActiveness: PropTypes.bool,
  isEditing: PropTypes.bool,
  isReplying: PropTypes.bool,
  onClose: PropTypes.func,
  noteIds: PropTypes.array,
  loading: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    dateRange: selectNavigationRangeDates(state),
    hasActiveness: selectCurrentActiveness(state) !== null,
    isEditing: selectShiftBookEditingNoteId(state) !== null,
    isReplying: selectShiftBookReplyingNoteId(state) !== null,
    noteIds: selectShiftBookNoteRootIds(state),
    loading: selectShiftBookNoteLoading(state)
  }
}

const mapDispatchToProps = {
  onSave: createShiftBookNote
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RealizationMenuShiftBookNotes)
