import React from 'react'
import PropTypes from 'prop-types'
import Select from 'components/Select'

const upcaseFirst = str => str.charAt(0).toUpperCase() + str.slice(1)
const labelMapper = value => typeof value === 'string' ? upcaseFirst(value) : value
const optionMapper = value => ({ value, label: labelMapper(value) })

const SimpleSelect = ({ options, value, onChange, ...props }) => {
  return (
    <Select
      {...props}
      onChange={(selected, action) => onChange(selected ? selected.value : selected, action)}
      value={optionMapper(value)}
      options={options.map(optionMapper)}
    />
  )
}

SimpleSelect.defaultProps = {
  options: []
}

SimpleSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any)
}

export default SimpleSelect
