import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import TetherComponent from 'react-tether'
import { connect } from 'react-redux'
import Button from 'components/Button'
import ToggleButton from 'components/ToggleButton'
import { selectRelevantShiftNotification } from './selectors'
import { confirmShiftNotification } from './actions'

const shiftNotificationColor = (shiftNotification) => {
  if (!shiftNotification) return undefined

  switch (shiftNotification.severity) {
    case 1:
      return 'red'
    case 2:
      return 'orange'
    case 3:
      return 'yellow'
    default:
      return undefined
  }
}

class ShiftNavigationButton extends Component {
  state = {
    hovered: false
  }

  setShift = () => {
    if (this.props.disabled) {
      return
    }

    this.props.setShift(
      this.props.shift === this.props.currentShift ? null : this.props.shift
    )
  }

  onMouseEnter = () => {
    this.setState({ hovered: true })
  }

  onMouseLeave = () => {
    this.setState({ hovered: false })
  }

  render () {
    const { currentShift, shift, disabled, shiftNotification, ts } = this.props

    return (
      <div
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <TetherComponent
          attachment='bottom center'
          renderElementTo='#workspace-tether'
        >
          <ToggleButton
            color='default'
            isToggled={currentShift === shift}
            onToggleOn={this.setShift}
            onToggleOff={this.setShift}
            disabled={disabled}
            key={ts}
            style={{
              background: shiftNotificationColor(shiftNotification),
              animation: shiftNotification ? 'blink 1s infinite linear' : undefined
            }}
          >
            {shift}
          </ToggleButton>
          {shiftNotification && (
            <div
              style={{
                display: this.state.hovered ? 'block' : 'none',
                width: 250,
                padding: 10,
                background: 'white',
                borderStyle: 'solid',
                borderColor: shiftNotificationColor(shiftNotification),
                borderWidth: 5
              }}
            >
              <p>
                {shiftNotification.message}
              </p>
              <p style={{ fontSize: '0.8em' }}>
                {shiftNotification.author.firstName}{' '}
                {shiftNotification.author.lastName},{' '}
                <i>{(new Date(Date.parse(shiftNotification.createdAt))).toLocaleString()}</i>
              </p>

              <div style={{ textAlign: 'right' }}>
                <Button
                  style={{ background: 'purple' }}
                  size='small'
                  onClick={this.props.confirmShiftNotification}
                >
                  <FormattedMessage id='workspace.scheduleUpdates.confirm' />
                </Button>
              </div>
            </div>
          )}
        </TetherComponent>
      </div>
    )
  }
}

ShiftNavigationButton.propTypes = {
  shift: PropTypes.oneOf([1, 2, 3]),
  currentShift: PropTypes.oneOf([1, 2, 3]),
  disabled: PropTypes.bool,
  setShift: PropTypes.func.isRequired,
  shiftNotification: PropTypes.object,
  confirmShiftNotification: PropTypes.func,
  ts: PropTypes.number
}

const mapStateToProps = (state, ownProps) => ({
  shiftNotification: selectRelevantShiftNotification(state, ownProps.shift)
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps

  return {
    ...stateProps,
    ...ownProps,
    confirmShiftNotification: () => {
      dispatch(confirmShiftNotification({ ...stateProps.shiftNotification, confirmShift: ownProps.shift }))
    }
  }
}

export default connect(
  mapStateToProps, null, mergeProps
)(ShiftNavigationButton)
