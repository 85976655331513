import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import TimelineBlock from 'components/TimelineBlock'
import PlanTimelineBlockContent from 'workspace/PlanTimelineBlockContent'
import PlanTimelineTooltip from 'workspace/PlanTimelineTooltip'
import { selectTimelineBlock } from 'workspace/actions'
import {
  selectSchedule,
  selectScheduleDetails,
  selectCurrentProductionRun
} from 'workspace/selectors'
import { Modes } from 'workspace/const'
import { Characters } from 'lib/const'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary'
}

const getRawColor = (character, project, stoppageType) => {
  if (character === Characters.PRODUCTION && project && project.blockColor) {
    return '#' + project.blockColor
  } else if (character === Characters.STOPPAGE && stoppageType && stoppageType.blockColor) {
    return '#' + stoppageType.blockColor
  } else {
    return null
  }
}

const PlanTimelineBlock = ({
  id,
  project,
  stoppageType,
  character,
  selectTimelineBlock,
  productionRun,
  productionPlan,
  ...rest
}) => {
  const isNotAllowedToClick =
    character === Characters.PRODUCTION &&
    productionPlan &&
    productionRun &&
    productionRun.productionPlanId !== productionPlan.id

  const extraConatainerId = `plan-tineline-extra-${id}`

  return (
    <TimelineBlock
      {...rest}
      notAllowed={isNotAllowedToClick}
      color={CHARACTER_COLOR[character]}
      significant={stoppageType && stoppageType.isSignificant}
      extraContainerId={extraConatainerId}
    >
      <PlanTimelineBlockContent
        id={id}
        extraContainerId={extraConatainerId}
        onClick={e =>
          selectTimelineBlock({ mode: Modes.PLANNING, character, id })
        }
        rawColor={getRawColor(character, project, stoppageType)}
      />
      <PlanTimelineTooltip
        id={id}
        rawColor={getRawColor(character, project, stoppageType)}
      />
    </TimelineBlock>
  )
}

PlanTimelineBlock.propTypes = {
  id: PropTypes.number.isRequired,
  project: PropTypes.shape({
    blockColor: PropTypes.string,
    id: PropTypes.number.isRequired
  }),
  stoppageType: PropTypes.shape({
    blockColor: PropTypes.string
  }),
  character: Characters.propType,
  productionRun: PropTypes.shape({
    productionPlanId: PropTypes.number
  })
}

const mapStateToProps = (state, { id }) => ({
  ...selectSchedule(state, id),
  ...selectScheduleDetails(state, id),
  productionRun: selectCurrentProductionRun(state)
})

const mapDispatchToProps = {
  selectTimelineBlock
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanTimelineBlock)
