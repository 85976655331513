import React from 'react'
import { FormattedMessage } from 'react-intl'

export default () => (
  <h2 className='text-center'>
    <FormattedMessage
      id='workspace.locationsEmpty'
      defaultMessage='No locations selected'
      description='Message displayed instead of timelines when no location are selected' />
  </h2>
)
