import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Input = ({ render, label, nested, error, required, className, children, ...rest }) => {
  if (render) {
    return (
      <div className={classnames('input', { 'input--nested': nested, 'input--error': error }, className)} {...rest}>
        { label && <label>{ required && '* ' }{ label }</label> }
        { children }
        { error && <span className='input__error'>{ error }</span>}
      </div>
    )
  } else {
    return null
  }
}

Input.defaultProps = {
  nested: false,
  required: false,
  render: true
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.any.isRequired,
  nested: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  render: PropTypes.bool
}

export default Input
