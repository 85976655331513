import { put, call, select } from 'redux-saga/effects'
import api from 'lib/api/client'
import { selectMode, selectToken, selectSelectedLocation } from 'workspace/selectors'
import { receiveNotDownloadedAttachmentsCount } from 'workspace/actions'

export function * fetchNotDownloadedAttachmentsCount () {
  const mode = yield select(selectMode)
  if (mode !== 'realization') {
    return
  }

  const { id: locationId } = (yield select(selectSelectedLocation)) || {}
  if (!locationId) {
    return
  }

  const token = yield select(selectToken)
  const count = yield call(
    api.get,
    `/attachments/not_downloaded_count?location_id=${locationId}`,
    { token }
  )
  yield put(receiveNotDownloadedAttachmentsCount(count))
}
