import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableScrapSubtypes } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormScrapType = ({ id, scrapSubtypes, onChange }) => (
  <WorkspaceFormInput attribute='scrapSubtype'>
    <AssociationSelect
      isClearable={false}
      backspaceRemovesValue={false}
      options={scrapSubtypes}
      value={id}
      onChange={newId => onChange({ scrapSubtypeId: newId })}
      labelMapper={st => `${st.symbol} - ${st.description}`}
    />
  </WorkspaceFormInput>
)

WorkspaceFormScrapType.propTypes = {
  scrapSubtypes: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  scrapSubtypes: selectAvailableScrapSubtypes(state),
  id: state.form.data.scrapSubtypeId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormScrapType)
