import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectNotDownloadedAttachmentsCount, selectSelectedLocation } from 'workspace/selectors'
import Button from 'components/Button'
import Badge from 'components/Badge'
import Icon from 'components/Icon'

const badgeStyle = { marginLeft: 9 }

const RealizationMenuAttachments = ({ count, locationId }) => {
  return (
    <a
      className='workspace-menu__element'
      href={`/data/attachments?q[locations_id_eq]=${locationId}`}
      data-turbolinks='false'
    >
      <Button
        color='blue'
        style={{ fontSize: 21 }}
      >
        <div className='flex-center'>
          <Icon name='download' />
          {!!count && <Badge text={count} size={21} style={badgeStyle} />}
        </div>
      </Button>
    </a>
  )
}

const mapStateToProps = state => {
  return {
    count: selectNotDownloadedAttachmentsCount(state),
    locationId: (selectSelectedLocation(state) || {}).id
  }
}

RealizationMenuAttachments.propTypes = {
  count: PropTypes.number,
  locationId: PropTypes.number
}

export default connect(mapStateToProps)(RealizationMenuAttachments)
