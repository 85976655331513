import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RealizationTimeline from 'workspace/RealizationTimeline'
import WorkspaceLocationsEmpty from 'workspace/WorkspaceLocationsEmpty'

const RealizationTimelines = ({ ids }) => (
  <div>
    {ids.map(id => <RealizationTimeline key={id} id={id} />)}
    {ids.length === 0 && <WorkspaceLocationsEmpty />}
  </div>
)

RealizationTimelines.defaultProps = {
  ids: []
}

RealizationTimelines.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = (state) => ({
  ids: state.locations.selectedIds
})

export default connect(mapStateToProps)(RealizationTimelines)
