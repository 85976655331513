import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Barcode from 'react-barcode'
import Modal from 'components/Modal'
import { showMixPlanBarcode } from 'workspace/actions'
import { selectBarcodeModalMixPlan } from 'workspace/selectors'
import { FormattedMessage } from 'react-intl'

const WorkspaceBarcodeMixModal = ({ plan, hide }) => {
  if (plan) {
    return (
      <Modal
        onClose={hide}
        className='label-modal'
        style={{ backgroundColor: '#ddd', display: 'block', textAlign: 'center', overflowX: 'hidden', overflowY: 'auto' }}
      >
        <div style={{ marginBottom: 15 }}>
          <Barcode
            value='MENLS24           '
            format='CODE39'
            background='#ddd'
            height={150}
            width={2}
            displayValue
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <Barcode
            value='0012'
            format='CODE39'
            background='#ddd'
            height={150}
            width={2}
            displayValue
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          <Barcode
            value='0022'
            format='CODE39'
            background='#ddd'
            height={150}
            width={2}
            displayValue
          />
        </div>
        { plan.extruderComponentCodes.map((component) => {
          const [code, description] = component

          return (
            <div style={{ marginBottom: 15 }}>
              <Barcode
                key={code}
                value={`${code}          `}
                format='CODE39'
                background='#ddd'
                height={150}
                width={2}
                displayValue
              />
              <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: -10 }}>{description}</div>
            </div>
          )
        }) }
      </Modal>
    )
  } else {
    return null
  }
}

WorkspaceBarcodeMixModal.propTypes = {
  plan: PropTypes.shape({
    ekanbanReservationNumber: PropTypes.string.isRequired
  })
}

const mapStateToProps = state => ({
  plan: selectBarcodeModalMixPlan(state)
})

const mapDispatchToProps = {
  hide: () => showMixPlanBarcode(null)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceBarcodeMixModal)
