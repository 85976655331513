import { call, fork, put, select, delay } from 'redux-saga/effects'
import moment from 'moment-timezone'
import api from 'lib/api/client'
import {
  receiveSchedules,
  requestSchedules,
  receiveScheduleUpdates,
  requestScheduleUpdates
} from 'workspace/actions'
import {
  selectMode,
  selectToken,
  selectTimezone
} from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { WEEK } from 'containers/Navigation/const'
import { Modes } from 'workspace/const'

const planDecorator = (plan, timezone) => {
  if (!plan) {
    return plan
  } else {
    return {
      ...plan,
      date: moment.tz(plan.date, timezone),
      startedAt: moment.tz(plan.startedAt, timezone),
      finishedAt: moment.tz(plan.finishedAt, timezone)
    }
  }
}

const scheduleDecorator = ({ date, startsAt, endsAt, updatedAt, productionPlan, stoppagePlan, ...schedule }, timezone) => ({
  ...schedule,
  startsAt: moment.tz(startsAt, timezone),
  endsAt: moment.tz(endsAt, timezone),
  date: moment.tz(date, timezone),
  updatedAt: moment.tz(updatedAt, timezone),
  productionPlan: planDecorator(productionPlan, timezone),
  stoppagePlan: planDecorator(stoppagePlan, timezone)
})

const decorateSchedules = (schedules, timezone) => schedules.map(s => scheduleDecorator(s, timezone))

const callSchedulesAPI = ({ token, from, to, locationId }) =>
  call(api.get, '/location_schedules', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

  const callScheduleUpdatesAPI = ({ token, from, to, locationId }) =>
  call(api.get, '/location_schedule_updates', {
    token,
    params: {
      from,
      to,
      locationId
    }
  })

export function * requestLocationSchedules (locationId) {
  yield put(requestSchedules(locationId))
}

export function * requestAllSchedules () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestSchedules(ids[i]))
  }

  return true
}

export function * requestAllScheduleUpdates () {
  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield put(requestScheduleUpdates(ids[i]))
  }

  return true
}

export function * loadLocationSchedules (locationId) {
  const token = yield select(selectToken)
  const mode = yield select(selectMode)
  const { from, to } = yield select(selectNavigationRange, mode === Modes.REALIZATION ? { scope: WEEK } : {})
  const timezone = yield select(selectTimezone)

  const schedules = yield callSchedulesAPI({ token, from, to, locationId })
  yield put(receiveSchedules({ locationId, schedules: decorateSchedules(schedules, timezone) }))
}

export function * loadAllSchedules () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationSchedules, ids[i])
  }
}

export function * loadLocationScheduleUpdates (locationId) {
  const token = yield select(selectToken)
  const { from, to } = yield select(selectNavigationRange)

  const scheduleUpdates = yield callScheduleUpdatesAPI({ token, from, to, locationId })
  yield put(receiveScheduleUpdates({ locationId, scheduleUpdates }))
}

export function * loadAllScheduleUpdates () {
  // used together with takeLatest this creates a "debounce" effect to stop too many requests from running at once
  yield delay(250)

  const ids = yield select(state => state.locations.selectedIds)

  for (var i = 0; i < ids.length; i++) {
    yield fork(loadLocationScheduleUpdates, ids[i])
  }
}
