import React from 'react'
import Input from 'components/Input'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import { changeActivenessFormUserWorkPeriod } from 'workspace/actions'
import {
  selectActivenessFormDataUserWorkPeriod,
  selectUserWorkPeriodFieldError,
  selectAvailableUsersForUserWorkPeriodRow
} from './selectors'

const messages = defineMessages({
  noUsers: {
    id: 'workspace.form.noUsers',
    defaultMessage: 'No matching users'
  },
  label: {
    id: 'workspace.menu.activenessForm.userLabel',
    defaultMessage: 'Operator'
  }
})

const RealizationMenuUserInput = ({ id, intl, users, onChange, error }) => (
  <Input
    label={intl.formatMessage(messages.label)}
    error={error}
    nested
    style={{ minWidth: 200 }}
    className='workspace-form__control'
  >
    <AssociationSelect
      isClearable
      options={users}
      value={id}
      onChange={newId => onChange({ userId: newId })}
      labelMapper={user => `${user.firstName} ${user.lastName}`}
      noOptionsMessage={() => intl.formatMessage(messages.noUsers)}
    />
  </Input>
)

RealizationMenuUserInput.propTypes = {
  intl: intlShape.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, id: PropTypes.number })
  ),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const data = selectActivenessFormDataUserWorkPeriod(state, ownProps.idx)

  return {
    users: selectAvailableUsersForUserWorkPeriodRow(state, ownProps.idx),
    id: data.userId,
    error: selectUserWorkPeriodFieldError(state, ownProps.idx, 'User')
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: data =>
    dispatch(changeActivenessFormUserWorkPeriod({ idx: ownProps.idx, data }))
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(RealizationMenuUserInput)
