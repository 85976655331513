import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProductionRunFormDataComponent, selectProductionRunFormComponentFieldError } from './selectors'
import { changeProductionRunFormComponent } from './actions'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.deliveryNumberLabel',
    defaultMessage: 'Delivery number'
  }
})

const ProductionRunFormComponentDeliveryNumber = ({
  intl,
  idx,
  deliveryNumber,
  componentDescription,
  onChange,
  error
}) => (
  <Input
    nested
    label={intl.formatMessage(messages.label)}
    className='workspace-form__control'
    error={error}
    style={{ flexGrow: 1 }}
  >
    <input
      type='text'
      className='workspace-form__input'
      style={{ width: '100%', boxSizing: 'border-box' }}
      value={deliveryNumber || ''}
      onChange={e => onChange({ deliveryNumber: e.target.value })}
      readOnly={!componentDescription}
    />
  </Input>
)

ProductionRunFormComponentDeliveryNumber.propTypes = {
  idx: PropTypes.number,
  deliveryNumber: PropTypes.string,
  componentDescription: PropTypes.any,
  intl: intlShape,
  onChange: PropTypes.func,
  error: PropTypes.string
}

const mapStateToProps = (state, ownProps) => {
  const { deliveryNumber, componentDescriptions } = selectProductionRunFormDataComponent(
    state,
    ownProps.idx
  )

  return {
    deliveryNumber,
    componentDescription: componentDescriptions[0],
    error: selectProductionRunFormComponentFieldError(state, ownProps.idx, 'DeliveryNumber')
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: data =>
    dispatch(changeProductionRunFormComponent({ idx: ownProps.idx, data }))
})

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps)
)(ProductionRunFormComponentDeliveryNumber)
