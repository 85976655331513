import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import RealizationScrapTable from 'workspace/RealizationScrapTable'
import WorkspaceLocationsEmpty from 'workspace/WorkspaceLocationsEmpty'

const RealizationScrapTables = ({ ids, sealCare }) => (
  <div className='timeline-group__extra'>
    {ids.map(id => <RealizationScrapTable key={id} id={id} skipActions={(sealed) => sealCare && sealed} />)}
    {ids.length === 0 && <WorkspaceLocationsEmpty />}
  </div>
)

RealizationScrapTables.defaultProps = {
  ids: []
}

RealizationScrapTables.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  sealCare: PropTypes.bool
}

const mapStateToProps = (state) => ({
  ids: state.locations.selectedIds,
  sealCare: state.permissions.realization.sealScrap
})

export default connect(mapStateToProps)(RealizationScrapTables)
