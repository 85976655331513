import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Barcode from 'react-barcode'
import Modal from 'components/Modal'
import { showPlanBarcode } from 'workspace/actions'
import { selectBarcodeModalPlan } from 'workspace/selectors'
import { FormattedMessage } from 'react-intl'

const WorkspaceBarcodePlanModal = ({ plan, hide }) => {
  if (plan) {
    return (
      <Modal
        onClose={hide}
        className='label-modal'
        style={{ backgroundColor: '#ddd' }}
      >
        <div className='label-modal__line label-modal__line--flex'>
          <div>
            <FormattedMessage id='workspace.plan.tooltip.barcode.reservation' />
          </div>
          <div />
          {plan.productionCallNumber}
        </div>
        <Barcode
          value={`RES${plan.ekanbanReservationNumber}${'      '}`}
          format='CODE39'
          background='#ddd'
          height={200}
          width={2}
          displayValue
        />
      </Modal>
    )
  } else {
    return null
  }
}

WorkspaceBarcodePlanModal.propTypes = {
  plan: PropTypes.shape({
    ekanbanReservationNumber: PropTypes.string.isRequired
  })
}

const mapStateToProps = state => ({
  plan: selectBarcodeModalPlan(state)
})

const mapDispatchToProps = {
  hide: () => showPlanBarcode(null)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkspaceBarcodePlanModal)
