import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectRealization, selectRealizationDetails } from 'workspace/selectors'
import Color from 'color'
import { durationInMinutes, formatDuration } from 'workspace/lib'

const RealizationTimelineBlockContent = ({ id, quantity, chargeNumber, product, project, productionPlan, stoppageType, startedAt, finishedAt, duration, onClick, rawColor }) => {
  rawColor = rawColor && Color(rawColor)
  return (
    <div
      className='timeline-block__content'
      onClick={onClick}
      style={{
        borderColor: rawColor && rawColor.darken(0.5),
        backgroundColor: rawColor
      }}
    >
      {
        product &&
        <React.Fragment>
          <p className='timeline-block__line'>{product.profile}, {product.length} mm, {productionPlan.productionCallNumber}</p>
          <p className='timeline-block__line'><code>{chargeNumber}, {quantity}x</code></p>
        </React.Fragment>
      }
      {
        stoppageType &&
        <React.Fragment>
          <p className='timeline-block__line'>{stoppageType.symbol}</p>
          <p className='timeline-block__line'><code>{formatDuration(duration)} min.</code></p>
        </React.Fragment>
      }
    </div>
  )
}

RealizationTimelineBlockContent.propTypes = {
  id: PropTypes.number.isRequired,
  quantity: PropTypes.number,
  chargeNumber: PropTypes.number,
  startedAt: PropTypes.object.isRequired,
  finishedAt: PropTypes.object.isRequired,
  project: PropTypes.shape({
    name: PropTypes.string
  }),
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    productionTime: PropTypes.number.isRequired
  }),
  productionPlan: PropTypes.shape({
    productionCallNumber: PropTypes.any
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string.isRequired
  }),
  duration: PropTypes.number,
  onClick: PropTypes.func,
  rawColor: PropTypes.string
}

const mapStateToProps = (state, { id }) => ({
  ...selectRealization(state, id),
  ...selectRealizationDetails(state, id)
})

export default connect(mapStateToProps)(RealizationTimelineBlockContent)
