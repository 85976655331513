import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  selectShiftBookNote,
  selectShiftBookEditingNoteId,
  selectShiftBookReplyingNoteId,
  selectShiftBookNoteReplyIds
} from './selectors'
import classnames from 'classnames'
import RealizationMenuShiftBookNoteActions from './RealizationMenuShiftBookNoteActions'
import { DATETIME_FORMAT } from 'lib/const'
import moment from 'moment'
import RealizationMenuShiftBookNoteReply from './RealizationMenuShiftBookNoteReply'
import { setShiftBookNoteAsResolved } from './actions'
import Icon from 'components/Icon'
import Button from 'components/Button'
import { FormattedMessage } from 'react-intl'

const RealizationMenuShiftBookNote = ({ id, note, isEditing, isReplying, replyIds, onResolve }) => {
  const toResolve = note.canUpdate && !note.resolved

  return (
    <React.Fragment>
      <div className={classnames('shift-book-notes__note-break-line', { 'shift-book-notes__note-break-line--with-actions': toResolve })} />
      <div id={`shiftBookNote${id}`} className={classnames('shift-book-note', { 'shift-book-note--editing': isEditing || isReplying })}>
        { toResolve && (
          <Button onClick={onResolve} className='shift-book-note__resolve' size='small' color='blue'>
            <Icon name='expand' />
            &nbsp;&nbsp;
            <FormattedMessage id='workspace.menu.shiftBook.resolve' />
          </Button>
        )}
        <div className='shift-book-note__meta'>
          <span className='shift-book-note__info'>
            {note.locationSymbol && <strong>{note.locationSymbol}: </strong>}
            {moment(note.createdAt).format(DATETIME_FORMAT)},
            <strong> {note.userName}</strong>
          </span>
          <RealizationMenuShiftBookNoteActions id={id} />
        </div>
        <div className='shift-book-note__body'>{note.body}</div>
        { replyIds && replyIds.map(replyId => <RealizationMenuShiftBookNoteReply key={replyId} id={replyId} />) }
      </div>
    </React.Fragment>
  )
}

RealizationMenuShiftBookNote.propTypes = {
  id: PropTypes.number,
  isEditing: PropTypes.bool,
  isReplying: PropTypes.bool,
  onResolve: PropTypes.func,
  note: PropTypes.shape({
    userName: PropTypes.string,
    createdAt: PropTypes.string,
    body: PropTypes.string
  }),
  replyIds: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = (state, ownProps) => {
  const note = selectShiftBookNote(state, ownProps.id)
  const editingId = selectShiftBookEditingNoteId(state)
  const replyingId = selectShiftBookReplyingNoteId(state)

  return {
    note,
    isEditing: editingId === ownProps.id,
    isReplying: replyingId === ownProps.id,
    replyIds: selectShiftBookNoteReplyIds(state, ownProps.id)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onResolve: () => dispatch(setShiftBookNoteAsResolved(ownProps.id))
})

export default connect(mapStateToProps, mapDispatchToProps)(RealizationMenuShiftBookNote)
