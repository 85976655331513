import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import classnames from 'classnames'
import Input from 'components/Input'

const messages = defineMessages({
  actionTaken: {
    id: 'workspace.form.actionTaken',
    defaultMessage: 'Action',
    description: 'Workspace form input label'
  },
  changeoverDuration: {
    id: 'workspace.form.changeoverDuration',
    defaultMessage: 'Changeover duration',
    description: 'Workspace form input label'
  },
  date: {
    id: 'workspace.form.date',
    defaultMessage: 'Date',
    description: 'Workspace form input label'
  },
  description: {
    id: 'workspace.form.description',
    defaultMessage: 'Description',
    description: 'Workspace form input label'
  },
  division: {
    id: 'workspace.form.division',
    defaultMessage: 'Breakdown type',
    description: 'Workspace form input label'
  },
  duration: {
    id: 'workspace.form.duration',
    defaultMessage: 'Duration',
    description: 'Workspace form input label'
  },
  engineeringFinishedAt: {
    id: 'workspace.form.engineeringFinishedAt',
    defaultMessage: 'Eng. end / setup',
    description: 'Workspace form input label'
  },
  finishedAt: {
    id: 'workspace.form.finishedAt',
    defaultMessage: 'Finish',
    description: 'Workspace form input label'
  },
  location: {
    id: 'workspace.form.location',
    defaultMessage: 'Location',
    description: 'Workspace form input label'
  },
  manualDuration: {
    id: 'workspace.form.manualDuration',
    defaultMessage: 'Manual duration',
    description: 'Workspace form input label'
  },
  priority: {
    id: 'workspace.form.priority',
    defaultMessage: 'Priority',
    description: 'Workspace form input label'
  },
  product: {
    id: 'workspace.form.product',
    defaultMessage: 'Product',
    description: 'Workspace form input label'
  },
  project: {
    id: 'workspace.form.project',
    defaultMessage: 'Project',
    description: 'Workspace form input label'
  },
  productionPlan: {
    id: 'workspace.form.productionPlan',
    defaultMessage: 'Production Plan',
    description: 'Workspace form input label'
  },
  productionCallNumber: {
    id: 'workspace.form.productionCallNumber',
    defaultMessage: 'Production Call Number',
    description: 'Workspace form input label'
  },
  quantity: {
    id: 'workspace.form.quantity',
    defaultMessage: 'Quantity',
    description: 'Workspace form input label'
  },
  scrapQuantity: {
    id: 'workspace.form.scrapQuantity',
    defaultMessage: 'Scrap quantity',
    description: 'Workspace form input label'
  },
  length: {
    id: 'workspace.form.length',
    defaultMessage: 'Length',
    description: 'Workspace form input label'
  },
  ekanbanReservationNumber: {
    id: 'workspace.form.ekanbanReservationNumber',
    defaultMessage: 'eKANBAN reservation number',
    description: 'Workspace form input label'
  },
  remarks: {
    id: 'workspace.form.remarks',
    defaultMessage: 'Remarks',
    description: 'Workspace form input label'
  },
  machinery: {
    id: 'workspace.form.machinery',
    defaultMessage: 'Machinery',
    description: 'Workspace form input label'
  },
  responsible: {
    id: 'workspace.form.responsible',
    defaultMessage: 'Responsible',
    description: 'Workspace form input label'
  },
  predefinedRemark: {
    id: 'workspace.form.predefinedRemark',
    defaultMessage: 'Remarks',
    description: 'Workspace form input label'
  },
  reportedAt: {
    id: 'workspace.form.reportedAt',
    defaultMessage: 'Reported at',
    description: 'Workspace form input label'
  },
  resolution: {
    id: 'workspace.form.resolution',
    defaultMessage: 'Resolution',
    description: 'Workspace form input label'
  },
  startedAt: {
    id: 'workspace.form.startedAt',
    defaultMessage: 'Start',
    description: 'Workspace form input label'
  },
  scrapType: {
    id: 'workspace.form.scrapType',
    defaultMessage: 'Scrap type',
    description: 'Workspace form input label'
  },
  scrapSubtype: {
    id: 'workspace.form.scrapSubtype',
    defaultMessage: 'Scrap subtype',
    description: 'Workspace form input label'
  },
  stoppageType: {
    id: 'workspace.form.stoppageType',
    defaultMessage: 'Stoppage type',
    description: 'Workspace form input label'
  },
  batchNumber: {
    id: 'workspace.form.batchNumber',
    defaultMessage: 'Batch number',
    description: 'Workspace form input label'
  },
  chargeNumber: {
    id: 'workspace.form.chargeNumber',
    defaultMessage: 'Charge number',
    description: 'Workspace form input label'
  },
  comment: {
    id: 'workspace.form.comment',
    defaultMessage: 'Comment',
    description: 'Workspace form input label'
  }
})

const Sizes = {
  changeoverDuration: null,
  date: null,
  description: 3,
  division: 2,
  duration: 1,
  engineeringFinishedAt: 1,
  finishedAt: 1,
  manualDuration: null,
  predefinedRemark: null,
  priority: 1,
  product: 3,
  project: 1,
  quantity: 1,
  length: 1,
  remarks: null,
  resolution: 3,
  startedAt: 1,
  stoppageType: 3,
  location: 1,
  productionCallNumber: 1,
  scrapQuantity: 1,
  ekanbanReservationNumber: 1,
  comment: 2
}

const WorkspaceFormInput = ({ intl, attribute, errors, className, children, style }) => (
  <Input
    nested
    label={intl.formatMessage(messages[attribute])}
    error={(errors || [])[0]}
    className={classnames('workspace-form__control', className)}
    style={{ ...style, flexGrow: Sizes[attribute] }}
    children={children}
  />
)

WorkspaceFormInput.defaultProps = {
  errors: [],
  style: {}
}

WorkspaceFormInput.propTypes = {
  attribute: PropTypes.string.isRequired,
  extraErrorsAttribute: PropTypes.string,
  errors: PropTypes.array,
  intl: intlShape,
  className: PropTypes.any,
  children: PropTypes.any,
  style: PropTypes.object
}

const mapStateToProps = (state, { attribute, extraErrorsAttribute }) => ({
  errors: [
    ...(state.form.errors[attribute] || []),
    ...(state.form.errors[attribute + 'Id'] || []),
    ...(state.form.errors[extraErrorsAttribute] || [])
  ]
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(WorkspaceFormInput)
