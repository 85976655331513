import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import RealizationMenuUserWorkPeriodForm from './RealizationMenuUserWorkPeriodForm'
import { selectActivenessFormDataUserWorkPeriods } from './selectors'

const UserWorkPeriods = ({ userWorkPeriods, withFinishedAt }) => {
  return (
    <>
      {userWorkPeriods.map((e, idx) => (
        <RealizationMenuUserWorkPeriodForm
          idx={idx}
          key={idx}
          withFinishedAt={withFinishedAt}
        />
      ))}
    </>
  )
}

UserWorkPeriods.propTypes = {
  userWorkPeriods: PropTypes.array,
  withFinishedAt: PropTypes.bool
}

const mapStateToProps = state => ({
  userWorkPeriods: selectActivenessFormDataUserWorkPeriods(state)
})

export default connect(mapStateToProps)(UserWorkPeriods)
