import { call, put, all, select } from 'redux-saga/effects'
import api from 'lib/api/client'
import {
  startInit,
  finishInit,
  receiveActivenesses,
  receiveBreakdowns,
  receiveCurrentUser,
  receiveLocations,
  receiveProjects,
  receiveProducts,
  receiveStoppageTypes,
  receiveScrapTypes,
  receivePermissions,
  receivePredefinedRemarks,
  receiveMachineries,
  receiveUsers,
  receiveUserWorkPeriodRoles
} from 'workspace/actions'
import { selectMode, selectToken, selectPermissions } from 'workspace/selectors'
import { receiveProductionRuns } from '../actions'

function * init () {
  yield put(startInit())
  const token = yield select(selectToken)
  const mode = yield select(selectMode)

  let [currentUser, permissions, locations, projects, products, stoppageTypes, scrapTypes, predefinedRemarks, machineries] = yield all([
    call(api.get, '/users/me', { token }),
    call(api.get, '/users/policies', { token }),
    call(api.get, '/locations', { token }),
    call(api.get, '/projects', { token }),
    call(api.get, '/products', { token }),
    call(api.get, '/stoppage_types', { token }),
    call(api.get, '/scrap_types', { token }),
    call(api.get, '/predefined_remarks', { token }),
    call(api.get, '/machineries', { token })
  ])

  yield all([
    put(receiveCurrentUser(currentUser)),
    put(receivePermissions(permissions)),
    put(receiveLocations(locations)),
    put(receiveProjects(projects)),
    put(receiveProducts(products)),
    put(receiveStoppageTypes(stoppageTypes)),
    put(receiveScrapTypes(scrapTypes)),
    put(receivePredefinedRemarks(predefinedRemarks)),
    put(receiveMachineries(machineries))
  ])

  permissions = yield select(selectPermissions)

  if (mode === 'realization') {
    const [activenesses, productionRuns] = yield all([
      call(api.get, '/activeness_periods', { token }),
      call(api.get, '/production_runs', { token })
    ])

    yield put(receiveActivenesses(activenesses))
    yield put(receiveProductionRuns(productionRuns))
  }

  if (mode === 'realization' && permissions.realization.write) {
    const [breakdowns, users, userWorkPeriodRoles] = yield all([
      call(api.get, '/breakdown_issues', { token }),
      call(api.get, '/users', { token }),
      call(api.get, '/user_work_periods/roles', { token })
    ])

    yield put(receiveBreakdowns(breakdowns))
    yield put(receiveUsers(users))
    yield put(receiveUserWorkPeriodRoles(userWorkPeriodRoles))
  }

  yield put(finishInit())
}

export default init
