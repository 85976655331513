import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectLocationScrapPercentage } from 'workspace/selectors'
import Icon from 'components/Icon'

const RealizationScrapPercentage = ({ percentage }) => (
  <span className='small'>
    &nbsp;
    [
    { percentage === null && <Icon name='cog' spin /> }
    { percentage !== null && percentage === -1 && '---' }
    { percentage !== null && percentage > -1 && <code>{(Math.round(1000 * percentage) / 10).toFixed(1)}%</code> }
    ]
  </span>
)

RealizationScrapPercentage.propTypes = {
  percentage: PropTypes.number
}

const mapStateToProps = (state) => ({
  percentage: selectLocationScrapPercentage(state)
})

export default connect(mapStateToProps)(RealizationScrapPercentage)
