import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormScrapQuantity = ({ scrapQuantity, onChange, style }) => (
  <WorkspaceFormInput attribute='scrapQuantity' extraErrorsAttribute='scrapQuantity' style={style}>
    <input
      type='number'
      min='0'
      className='workspace-form__input'
      value={(scrapQuantity || scrapQuantity === 0) ? scrapQuantity : ''}
      onChange={(e) => onChange({ scrapQuantity: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormScrapQuantity.propTypes = {
  scrapQuantity: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
}

WorkspaceFormScrapQuantity.defaultProps = {
  style: {}
}

const mapStateToProps = (state, { quantityType }) => ({
  scrapQuantity: state.form.data.scrapQuantity
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormScrapQuantity)
