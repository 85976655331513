import { put, call, select, all } from 'redux-saga/effects'
import moment from 'moment'
import {
  receiveCurrentUser,
  receiveShiftBook,
  setErrorShiftBook,
  clearDraftShiftBook,
  markShiftBookNoteAsReadSuccess,
  markAllShiftBookNotesAsReadSuccess,
  replyShiftBookNote
} from 'workspace/actions'
import {
  selectToken,
  selectMode,
  selectSelectedLocation,
  selectCurrentActiveness,
  selectShiftBookNoteUnreadIds,
  selectShiftBookNote,
  selectShiftBookNoteDraft,
  selectShiftBookEditingNote,
  selectShiftBookReplyingNoteId
} from 'workspace/selectors'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import api from 'lib/api/client'
import { setShiftBookNoteAsResolvedSuccess } from '../actions'

export function * fetchShiftBookNotes () {
  yield all([
    call(fetchCurrentUser),
    call(fetchNotes)
  ])
}

function * fetchCurrentUser () {
  const token = yield select(selectToken)
  const currentUser = yield call(api.get, '/users/me', { token })
  yield put(receiveCurrentUser(currentUser))
}

function * fetchNotes () {
  const mode = yield select(selectMode)
  if (mode !== 'realization') {
    return
  }

  const { locationId, from, to } = yield call(getFetchParams)
  if (!locationId || !from || !to) {
    return
  }

  const token = yield select(selectToken)
  const notes = yield call(
    api.get,
    `/locations/${locationId}/shift_book?from=${from}&to=${to}`,
    { token }
  )
  yield put(receiveShiftBook(notes))
}

function * getFetchParams () {
  const { id: locationId } = (yield select(selectSelectedLocation)) || {}
  const { from, to } = (yield select(selectNavigationRange)) || {}
  return {
    locationId,
    from: from && moment(from).toISOString(),
    to: to && moment(to).toISOString()
  }
}

export function * createNote () {
  const { id: activenessId } = (yield select(selectCurrentActiveness)) || {}
  if (!activenessId) {
    return
  }

  const token = yield select(selectToken)
  const body = yield select(selectShiftBookNoteDraft)
  const topicId = yield select(selectShiftBookReplyingNoteId)
  const params = { shiftBookNote: { body, topicId } }

  try {
    yield call(
      api.post,
      `activeness_periods/${activenessId}/shift_book_note`,
      params,
      { token }
    )

    yield call(fetchNotes)
    yield put(clearDraftShiftBook())
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(setErrorShiftBook())
    }
  }
}

export function * updateNote () {
  const { id: noteId, activenessPeriodId } = (yield select(selectShiftBookEditingNote)) || {}
  if (!noteId || !activenessPeriodId) {
    return
  }

  const token = yield select(selectToken)
  const body = yield select(selectShiftBookNoteDraft)
  const params = { shiftBookNote: { body } }

  try {
    yield call(
      api.put,
      `activeness_periods/${activenessPeriodId}/shift_book_note/${noteId}`,
      params,
      { token }
    )

    yield call(fetchNotes)
    yield put(clearDraftShiftBook())
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(setErrorShiftBook())
    }
  }
}

export function * destroyNote ({ payload: noteId }) {
  const { activenessPeriodId } = (yield select(state => selectShiftBookNote(state, noteId))) || {}
  if (!noteId || !activenessPeriodId) {
    return
  }
  const token = yield select(selectToken)

  try {
    yield call(
      api.delete,
      `activeness_periods/${activenessPeriodId}/shift_book_note/${noteId}`,
      { token }
    )

    yield call(fetchNotes)
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(setErrorShiftBook())
    }
  }
}

export function * markAsRead ({ payload: noteId }) {
  try {
    yield call(markAsReadByIds, [noteId])
    yield put(markShiftBookNoteAsReadSuccess([noteId]))
  } catch (e) {}
}

export function * markAllAsRead () {
  const noteIds = yield select(selectShiftBookNoteUnreadIds)

  try {
    yield call(markAsReadByIds, noteIds)
    yield put(markAllShiftBookNotesAsReadSuccess(noteIds))
  } catch (e) {}
}

export function * markAsReadByIds (noteIds) {
  const token = yield select(selectToken)
  yield call(
    api.post,
    'shift_book_notes/set_read',
    { shiftBookNoteIds: noteIds },
    { token }
  )
}

export function * setResolved ({ payload: id }) {
  const token = yield select(selectToken)
  const response = yield call(
    api.put,
    `shift_book_notes/${id}/set_resolved`,
    {},
    { token }
  )
  const relatedIds = response.map(note => note.id)
  yield put(setShiftBookNoteAsResolvedSuccess(id))
  yield put(markShiftBookNoteAsReadSuccess(relatedIds))
}

export function * setReplyFromUrl () {
  const url = new URL(window.location.href)
  const topicId = url.searchParams.get('topic_id')

  if (topicId) {
    const { resolved } = yield select(state => selectShiftBookNote(state, topicId))

    if (!resolved) yield put(replyShiftBookNote(+topicId))
  }
}
