import moment, { duration } from 'moment-timezone'
import { createSelector } from 'reselect'
import { Modes } from 'workspace/const'
import { Characters, SIMPLIFIED_DATETIME_FORMAT } from 'lib/const'
import { selectNavigationDate } from '../containers/Navigation/selectors'

const USER_WORK_PERIOD_REGEXP = /userWorkPeriods\[([[0-9]*)\](.*)$/
const PRODUCTION_RUN_COMPONENT_ERROR_REGEXP = /productionRunComponents\[([[0-9]*)\](.*)$/

// Config
export const selectMode = state => state.config.mode
export const selectToken = state => state.config.token
export const selectTimezone = state => state.config.timezone
export const selectLocale = state => state.config.locale

// Form
export const selectForm = state => state.form
export const selectFormType = state => state.form.type
export const selectIsFormActive = state => state.form.isActive
export const selectFormCharacter = state => state.form.data.character
export const selectFormProjectId = state => state.form.data.projectId
export const selectFormScrapTypeId = state => state.form.data.scrapTypeId
export const selectFormMachineryId = state => state.form.data.machineryId

// Attachments
export const selectNotDownloadedAttachmentsCount = state => state.notDownloadedAttachmentsCount

// Locations
export const selectLocations = state => state.locations.allIds.map(id => state.locations.allById[id])
export const selectSelectedLocation = state => state.locations.allById[state.locations.selectedIds[0]]
export const selectSelectedLocations = state => state.locations.selectedIds.map(id => state.locations.allById[id])
export const selectLocation = (state, id) => state.locations.allById[id]
export const isLocationLoadingSchedules = (state, id) => (state.locations.isLoadingSchedulesById[id])

// Projects
export const selectProjects = state => state.projects.allIds.map(id => state.projects.allById[id])
export const selectAvailableProjects = state => {
  let projects = state.projects.allIds.map(id => state.projects.allById[id])

  const locationId = state.form.data.locationId
  if (locationId) {
    projects = projects.filter(p => p.locationIds.includes(locationId))
  }

  return projects
}
export const selectProject = (state, id) => state.projects.allById[id]
const selectProjectsAllById = state => state.projects.allById

// Products
export const selectProduct = (state, id) => state.products.allById[id]
export const selectProductIdsByProject = (state, id) => state.products.idsByProject
export const selectProductsById = (state, id) => state.products.allById
export const selectProductIds = (state, id) => state.products.allIds
export const selectProducts = createSelector(
  selectProductIds,
  state => state.products.allById,
  (ids, allById) => ids.map(id => allById[id])
)
export const selectAvailableProducts = createSelector(
  selectFormProjectId,
  selectProducts,
  (projectId, products) => (projectId ? products.filter(p => p.projectIds.includes(projectId)) : products)
)
export const selectAvailableProductsCount = createSelector(
  selectAvailableProducts,
  products => products.length
)
export const selectProductByProjectId = (state, projectId) => {
  const productIds = state.products.idsByProject[projectId]

  return selectProduct(state, productIds[0])
}

// Stoppage types
export const selectStoppageTypes = state => state.stoppageTypes.allIds.map(id => state.stoppageTypes.allById[id])
export const selectAvailableStoppageTypes = (state) => {
  let stoppageTypes = state.stoppageTypes.allIds.map(id => state.stoppageTypes.allById[id])

  const locationId = state.form.data.locationId
  if (locationId) {
    stoppageTypes = stoppageTypes.filter(p => p.locationIds.includes(locationId))
  }

  return stoppageTypes
}
export const selectStoppageTypesForLocation = (state, locationId) => (
  state
    .stoppageTypes
    .allIds
    .map(id => state.stoppageTypes.allById[id])
    .filter(p => p.locationIds.includes(locationId))
)
export const selectStoppageType = (state, id) => state.stoppageTypes.allById[id]

// Scrap types
export const selectScrapType = (state, id) => state.scrapTypes.allById[id]
export const selectAvailableScrapTypes = (state) => {
  let scrapTypes = state.scrapTypes.allIds.map(id => state.scrapTypes.allById[id])

  const locationId = state.form.data.locationId
  if (locationId) {
    scrapTypes = scrapTypes.filter(p => p.locationIds.includes(locationId)/* && !p.parentId */)
  }

  return scrapTypes
}
export const selectAvailableScrapSubtypes = createSelector(
  selectFormScrapTypeId,
  state => state.scrapTypes.allById,
  (scrapTypeId, scrapsById) => {
    if (!scrapTypeId) {
      return []
    }

    return Object.values(scrapsById).filter(s => s.parentId === scrapTypeId)
  }
)

// Schedules
export const selectSchedule = (state, id) => state.schedules.allById[id]
export const selectScheduleDetails = (state, id) => {
  const schedule = selectSchedule(state, id)

  if (schedule.character === Characters.PRODUCTION) {
    const project = selectProject(state, schedule.productionPlan.projectId)

    return {
      project,
      product: selectProduct(state, project.productIds[0]),
      priority: schedule.priority
    }
  } else if (schedule.character === Characters.STOPPAGE) {
    return {
      priority: schedule.priority,
      stoppageType: selectStoppageType(state, schedule.stoppagePlan.stoppageTypeId)
    }
  } else {
    return {}
  }
}
export const selectScheduleProjectId = (state, id) => {
  const schedule = selectSchedule(state, id)

  if (schedule.character !== Characters.PRODUCTION) {
    return null
  }

  return schedule.productionPlan.projectId
}
export const selectIsScheduleEditable = (state, id) => {
  const timezone = selectTimezone(state)
  const now = moment.tz(timezone)
  const schedule = selectSchedule(state, id)
  const endsAt = moment.tz(schedule.endsAt, timezone)

  return (
    selectMode(state) === Modes.PLANNING &&
    selectPermissions(state).planning.write &&
    (
      (endsAt.year() === now.year() && endsAt.week() >= now.week()) ||
        endsAt.year() > now.year()
    )
  )
}
export const selectPlanTimestamp = (state, locationId) => {
  const schedules = state.schedules.idsByLocation[locationId]
  const timezone = selectTimezone(state)
  let updatedAt

  schedules.forEach(id => {
    const schedule = selectSchedule(state, id)
    if (!updatedAt || schedule.updatedAt.isAfter(updatedAt)) {
      updatedAt = schedule.updatedAt
    }
  })

  return updatedAt ? updatedAt.clone().tz(timezone) : null
}
const selectSchedulesIdsByLocation = state => state.schedules.idsByLocation
const selectSchedulesAllById = state => state.schedules.allById
const selectLocationProductionSchedules = createSelector(
  selectSelectedLocation,
  selectSchedulesIdsByLocation,
  selectSchedulesAllById,
  (location, idsByLocation, allById) => {
    const ids = idsByLocation[location.id]
    return ids.map(id => allById[id]).filter(schedule => schedule.character === Characters.PRODUCTION)
  }
)

export const selectProductionPlansForRealization = createSelector(
  selectLocationProductionSchedules,
  selectProjectsAllById,
  state => state.products.allById,
  (schedules, projectsById, products) => {
    const plans = schedules.map(schedule => schedule.productionPlan)

    return plans.map(plan => {
      const materialId = projectsById[plan.projectId].productIds[0]

      return {
        ...plan,
        materialCode: products[materialId].code
      }
    })
  }
)

export const selectProductionPlansById = createSelector(
  state => state.schedules.allById,
  schedules => {
    return Object.values(schedules)
      .filter(e => e.character === 'production')
      .map(e => e.productionPlan)
      .reduce((acc, plan) => {
        acc[plan.id] = plan
        return acc
      }, {})
  }
)

export const selectProductionPlansForProductionRun = createSelector(
  selectProductionPlansForRealization,
  selectProductIdsByProject,
  selectProductsById,
  (productionPlans, productIdsByProject, productsById) => {
    return productionPlans.map(plan => {
      const { projectId } = plan
      const [productId] = productIdsByProject[projectId]
      const product = productsById[productId]

      return {
        ...plan,
        formattedStartedAt: plan.startedAt.format(SIMPLIFIED_DATETIME_FORMAT),
        formattedFinishedAt: plan.finishedAt.format(SIMPLIFIED_DATETIME_FORMAT),
        productLength: product.length,
        productProfile: product.profile
      }
    }).sort((a, b) => a.startedAt.diff(b.startedAt))
  }
)

export const selectScheduleUpdate = (state, id) => state.scheduleUpdates.allById[id]
export const selectManyScheduleUpdates = createSelector(
  selectSelectedLocation,
  state => state.scheduleUpdates.idsByLocation,
  (location, ids) => ids[location.id].length > 1
)
export const selectScheduleUpdates = createSelector(
  selectSelectedLocation,
  state => state.scheduleUpdates.allById,
  state => state.scheduleUpdates.idsByLocation,
  (location, allById, idsByLocation) => idsByLocation[location.id].map((id) => allById[id])
)
export const selectCurrentScheduleUpdate = createSelector(
  selectSelectedLocation,
  state => state.scheduleUpdates.offset,
  state => state.scheduleUpdates.idsByLocation,
  state => state.scheduleUpdates.allById,
  (location, offset, idsByLocation, allById) => {
    const ids = idsByLocation[location.id] || []
    const id = ids[Math.min(offset, ids.length - 1)]

    if (id) {
      return allById[id]
    }

    return null
  }
)

export const selectProductionPlanForProductionRun = (state, id) => {
  const allPlans = selectProductionPlansForProductionRun(state)

  return allPlans.find(plan => plan.id === id)
}

export const selectProductionRunConfirmed = createSelector(
  selectProductionPlansForProductionRun
)

// Realizations
export const selectRealization = (state, id) => state.realizations.allById[id]
export const selectRealizationDetails = (state, id) => {
  const realization = selectRealization(state, id)
  let details = {}

  if (realization.projectId) {
    details.project = selectProject(state, realization.projectId)
  }

  if (realization.productId) {
    details.product = selectProduct(state, realization.productId)
  } else if (realization.stoppageTypeId) {
    details.stoppageType = selectStoppageType(state, realization.stoppageTypeId)
  }

  if (realization.predefinedRemarkId) {
    details.predefinedRemark = selectPredefinedRemark(state, realization.predefinedRemarkId)
  }

  return details
}
export const selectLocationRealizationsPerformance = state => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected

  if (state.locations.isLoadingRealizationsById[locationId]) {
    return null
  }

  const realizations = state.realizations.idsByLocation[locationId]
    .map(id => selectRealization(state, id))
    .filter(realization => realization.productId)

  if (realizations.length === 0) {
    return -1
  }

  const requiredTime = realizations.reduce(
    (sum, { quantity, productId }) => sum + quantity * selectProduct(state, productId).productionTime,
    0
  )
  const usedTime = realizations.reduce(
    (sum, realization) => sum + (realization.duration / 60.0),
    0
  )
  return requiredTime / usedTime
}

// Scraps
export const selectScrap = (state, id) => state.scraps.allById[id]
export const selectScrapDetails = (state, id) => {
  const scrap = selectScrap(state, id)

  return {
    ...scrap,
    product: selectProduct(state, scrap.productId),
    project: selectProject(state, scrap.projectId),
    scrapType: selectScrapType(state, scrap.scrapTypeId),
    scrapSubtype: selectScrapType(state, scrap.scrapSubtypeId)
  }
}
export const selectLocationScrapPercentage = (state) => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected
  const percentage = state.scraps.percentageByLocation[locationId]

  if (state.locations.isLoadingScrapsById[locationId]) {
    return null
  }

  if (percentage.length === 0) {
    return -1
  } else {
    const [scrap, realization] = percentage

    if (realization === 0) {
      return 1.0
    } else {
      return scrap / realization
    }
  }
}

// Activenesses
export const selectCurrentActiveness = state => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected
  const activenessIds = state.activenesses.idsByLocation[locationId]

  if (!activenessIds || activenessIds.length === 0) {
    return null
  } else {
    return state.activenesses.allById[activenessIds[0]]
  }
}
export const selectActivenessErrors = state => state.activenesses.errors

// Permissions
export const selectPermissions = state => state.permissions

// Predefined remarks
export const selectPredefinedRemark = (state, id) => state.predefinedRemarks.allById[id]
export const selectPredefinedRemarks = state => state.predefinedRemarks.allIds.map(id => state.predefinedRemarks.allById[id])
export const selectAvailablePredefinedRemarks = (state) => {
  let predefinedRemarks = state.predefinedRemarks.allIds.map(id => state.predefinedRemarks.allById[id])

  const locationId = state.form.data.locationId
  if (locationId) {
    predefinedRemarks = predefinedRemarks.filter(p => p.locationIds.includes(locationId))
  }

  const stoppageTypeId = state.form.data.stoppageTypeId
  if (stoppageTypeId) {
    predefinedRemarks = predefinedRemarks.filter(p => p.stoppageTypeIds.includes(stoppageTypeId))
  }

  return predefinedRemarks
}

// Machinery
export const selectMachineries = state => state.machineries.allIds.map(id => state.machineries.allById[id])
export const selectAvailableMachineries = (state) => {
  let machineries = selectMachineries(state)

  const stoppageTypeId = state.form.data.stoppageTypeId
  if (stoppageTypeId) {
    machineries = machineries.filter(p => p.stoppageTypeIds.includes(stoppageTypeId))
  }

  return machineries
}

// Barcode modal
export const selectBarcodeModalProduct = (state) => {
  if (!state.barcodeModal) return null

  const realization = selectRealization(state, state.barcodeModal.realizationId)
  if (!realization) return null

  return selectProduct(state, realization.productId)
}
export const selectBarcodeModalPlan = (state) => state.barcodeModal ? selectProductionPlansById(state)[state.barcodeModal.planId] : null
export const selectBarcodeModalMixPlan = (state) => state.barcodeModal ? selectProductionPlansById(state)[state.barcodeModal.mixPlanId] : null

// Breakdowns
export const selectBreakdowns = state => {
  const locationId = state.locations.selectedIds[0] // realization view allows only 1 location to be selected
  const breakdownIds = state.breakdowns.idsByLocation[locationId]

  if (!breakdownIds || breakdownIds.length === 0) {
    return null
  } else {
    return breakdownIds.map(id => state.breakdowns.allById[id])
  }
}

// Realization summaries
export const selectRealizationSummary = (state, id) => state.realizationSummaries.allById[id]
export const selectRealizationSummaryProject = (state, id) =>
  selectProject(state, selectRealizationSummary(state, id).projectId)
const calculateRealizationDuration = ({ startedAt, finishedAt }) => duration(finishedAt.diff(startedAt)).asSeconds()
export const selectRealizationSummaryDuration = (state, id) =>
  calculateRealizationDuration(selectRealizationSummary(state, id))

// Users
export const selectUsers = state => Object.values(state.users.allById)
export const selectAvailableUsers = createSelector(
  selectSelectedLocation,
  selectUsers,
  (location, users) => {
    const sortedUsers = users.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
    const locationId = location.id

    if (!locationId) {
      return sortedUsers
    }

    return sortedUsers.filter(u => !u.inactive && u.locationIds.includes(locationId))
  }
)
export const selectUsersForMachinery = createSelector(
  selectAvailableUsers,
  selectFormMachineryId,
  state => state.machineries.allById,
  (availableUsers, machineryId, machineries) => {
    if (!machineryId) {
      return availableUsers
    }

    const userIds = machineries[machineryId].userIds
    return availableUsers.filter(u => userIds.includes(u.id))
  }

)

// Current User
export const selectCurrentUser = state => state.currentUser
export const selectCurrentUserUnreadShiftBookNoteIds = state =>
  (selectCurrentUser(state) || {}).unreadShiftBookNoteIds || []

// User work periods
export const selectUserWorkPeriodRoles = state => state.userWorkPeriods.roles
export const selectUserWorkPeriodsErrors = createSelector(
  selectActivenessErrors,
  (errors) => {
    return Object.keys(errors).reduce((acc, val) => {
      if (!val.startsWith('userWorkPeriods')) {
        return acc
      }

      let [, idx, field] = USER_WORK_PERIOD_REGEXP.exec(val)
      acc[field][Number(idx)] = errors[val][0]
      return acc
    }, { User: {}, Role: {}, StartedAt: {}, FinishedAt: {} })
  }
)
export const selectUserWorkPeriodFieldError = (state, idx, field) => {
  return selectUserWorkPeriodsErrors(state)[field][idx]
}
export const selectAvailableUsersForUserWorkPeriodRow = (state, idx) => {
  const users = selectAvailableUsers(state)
  const roles = state.userWorkPeriods.roles
  const activenessData = state.activenessForm.data

  return users.filter(user => {
    const selectedRole = roles[+activenessData.userWorkPeriods[idx].role].value
    return user.operatorFunction === selectedRole && !user.inactive
  })
}

// Activeness form
export const selectActivenessForm = state => state.activenessForm
export const selectActivenessFormData = state => selectActivenessForm(state).data
export const selectActivenessFormDataUserWorkPeriods = state => selectActivenessFormData(state).userWorkPeriods
export const selectActivenessFormDataUserWorkPeriod = (state, idx) => selectActivenessFormDataUserWorkPeriods(state)[idx]
export const selectActivenessFormParams = createSelector(
  selectSelectedLocation,
  selectActivenessFormData,
  (location, data) => {
    const userWorkPeriodsAttributes =
      data.userWorkPeriods
        .map((e, idx) => {
          const withIdx = { ...e, _index: idx, ordr: idx }
          if (e.id && !e.userId) {
            return { ...withIdx, _destroy: true }
          }

          return withIdx
        })
        .filter(dataSet => dataSet.userId || dataSet._destroy)

    return {
      locationId: location.id,
      userWorkPeriodsAttributes
    }
  }
)

// Production run form
export const selectProductionRunFormData = state => state.productionRunForm.data
export const selectProductionRunFormProjectId = state => selectProductionRunFormData(state).projectId
export const selectProductionRunFormFirstGoodPartAt = state => {
  const data = selectProductionRunFormData(state)

  return {
    persisted: data.firstGoodPartAtPresent,
    date: data.firstGoodPartAt
  }
}
export const selectProductionRunFormLastGoodPartAt = state => {
  const data = selectProductionRunFormData(state)

  return {
    persisted: data.lastGoodPartAtPresent,
    date: data.lastGoodPartAt
  }
}
export const selectProductionRunFormDataComponents = state => selectProductionRunFormData(state).productionRunComponents
export const selectProductionRunFormErrors = state => state.productionRunForm.errors
export const selectProductionRunFormDataComponent = (state, idx) => selectProductionRunFormDataComponents(state)[idx]
export const selectProductionRunFormComponentsErrors = createSelector(
  selectProductionRunFormDataComponents,
  selectProductionRunFormErrors,
  (components, errors) => {
    return Object.keys(errors).reduce((acc, val) => {
      if (!val.startsWith('productionRunComponents')) {
        return acc
      }

      let [, idx, field] = PRODUCTION_RUN_COMPONENT_ERROR_REGEXP.exec(val)
      acc[field][Number(idx)] = errors[val][0]
      return acc
    }, { DeliveryNumber: {}, ProductComponent: {}, PremixQuantity: {} })
  }
)
export const selectProductionRunFormComponentFieldError = (state, idx, field) => (
  selectProductionRunFormComponentsErrors(state)[field][Number(idx)]
)
export const selectProductionRunFormParams = createSelector(
  selectSelectedLocation,
  selectProductionRunFormData,
  (location, data) => {
    const { productionRunComponents, ...restData } = data
    const productionRunComponentsAttributes = productionRunComponents.map((component, idx) => {
      const result = { ...component, _index: idx }

      if (!result.productComponentId) {
        result._destroy = true
      }

      return result
    })

    return {
      ...restData,
      locationId: location.id,
      productionRunComponentsAttributes
    }
  }
)
export const selectProductionRunComponentAvailableOptions = createSelector(
  selectProductionRunFormDataComponents,
  selectProductionRunFormDataComponent,
  (components, component) => {
    const takenIds = components.reduce((acc, el) => {
      if (el.productComponentId) {
        if (el.productComponentId === component.productComponentId) {
        } else {
          acc.push(el.productComponentId)
        }
      }

      return acc
    }, [])

    const options = component.productComponentIds.map((id, idx) => {
      return {
        value: id,
        label: component.componentDescriptions[idx]
      }
    })

    const nullOption = { value: null, label: '---' }
    return [nullOption, ...options.filter(el => takenIds.indexOf(el.value) === -1)]
  }
)

export const selectProductionRunComponentAllOptions = createSelector(
  selectProductionRunFormDataComponent,
  (component) => {
    const options = component.productComponentIds.map((id, idx) => {
      return {
        value: id,
        label: component.componentDescriptions[idx]
      }
    })

    const nullOption = { value: null, label: '---' }
    return [nullOption, ...options]
  }
)

// Production runs
export const selectCurrentProductionRun = createSelector(
  selectSelectedLocation,
  state => state.productionRuns.idsByLocation,
  state => state.productionRuns.allById,
  (selectedLocation, productionRunsByLocation, productionRunsById) => {
    const locationId = selectedLocation.id
    const ids = productionRunsByLocation[locationId]

    if (!ids || ids.length === 0) {
      return null
    } else {
      return productionRunsById[ids[0]]
    }
  }
)

export const selectCurrentProductionRunConfirmed = createSelector(
  selectCurrentProductionRun,
  (productionRun) => !productionRun || productionRun.confirmed
)

export const selectAvailableProductsForProductionRun = createSelector(
  selectProductionRunFormProjectId,
  selectProducts,
  (projectId, products) => (projectId ? products.filter(p => p.projectIds.includes(projectId)) : products)
)

// Shift book
export const selectShiftBookNoteIds = (state) => state.shiftBookNotes.allIds
export const selectShiftBookNoteRootIds = (state) => state.shiftBookNotes.rootIds
export const selectShiftBookNote = (state, id) => state.shiftBookNotes.allById[id]
export const selectShiftBookNoteLoading = (state) => state.shiftBookNotes.loading
export const selectShiftBookNoteDraft = state => state.shiftBookNotes.draft
export const selectShiftBookReplyingNoteId = state => state.shiftBookNotes.replyingId
export const selectShiftBookEditingNoteId = state => state.shiftBookNotes.editingId
export const selectShiftBookEditingNote = state =>
  selectShiftBookNote(state, selectShiftBookEditingNoteId(state))
export const selectShiftBookError = (state) => state.shiftBookNotes.error
export const selectShiftBookProcessing = (state) => state.shiftBookNotes.processing
export const selectShiftBookNoteUnreadIds = createSelector(
  selectShiftBookNoteIds,
  selectCurrentUserUnreadShiftBookNoteIds,
  (noteIds, unreadIds) => noteIds && unreadIds &&
    noteIds.filter(noteId => unreadIds.includes(noteId))
)
export const selectShiftBookNoteUnreadCount = createSelector(
  selectShiftBookNoteUnreadIds,
  unreadIds => (unreadIds || []).length
)
export const selectShiftBookNoteIsRead = (state, id) =>
  !selectCurrentUserUnreadShiftBookNoteIds(state).includes(id)
export const selectShiftBookNoteReplyIds = (state, id) =>
  state.shiftBookNotes.idsByTopic[id]

// Shift notifications
export const selectRelevantShiftNotification = createSelector(
  selectSelectedLocation,
  selectNavigationDate,
  state => selectCurrentUser(state).id,
  state => state.shiftNotifications.allById,
  state => state.shiftNotifications.idsByLocation,
  (_state, shift) => shift,
  (location, date, currentUserId, allById, idsByLocation, shift) => {
    const locationIds = idsByLocation[location.id]
    let allNotifications = locationIds.map((id) => allById[id])
    allNotifications = allNotifications.sort((a, b) => a.severity > b.severity ? 1 : -1)

    return allNotifications.find((notification) => {
      const isoWeekday = date.isoWeekday()
      const weekDay = isoWeekday === 7 ? 0 : isoWeekday

      if (notification.calendarWeek !== date.week() || notification.year !== date.year()) {
        return false
      }

      if (!notification.schedule || !(notification.schedule[weekDay] || {})[shift]) {
        return false
      }

      const reception = notification.receptions.find(reception => reception.locationId === location.id)
      if (!reception) return false

      const confirmation = notification.confirmations.find((confirmation) => {
        return confirmation.receptionId === reception.id &&
          date.isSame(confirmation.date, 'day') &&
          confirmation.confirmatorId === currentUserId &&
          confirmation.shift === shift
      })
      if (confirmation) return false

      return true
    })
  }
)
