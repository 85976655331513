import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import moment from 'moment'
import { selectShiftBookNote, selectShiftBookEditingNoteId } from './selectors'
import RealizationMenuShiftBookNoteActions from './RealizationMenuShiftBookNoteActions'
import { DATETIME_FORMAT } from 'lib/const'

const RealizationMenuShiftBookNoteReply = ({ id, note, isEditing }) => {
  return (
    <div className={classnames('shift-book-note', 'shift-book-note--reply', { 'shift-book-note--editing': isEditing })}>
      <div className='reply' />
      <div className='shift-book-note__body shift-book-note__body--reply'>
        <i>{moment(note.createdAt).format(DATETIME_FORMAT)} {note.userName}: </i>
        {note.body}
      </div>
      <div className='shift-book-note__meta'>
        <RealizationMenuShiftBookNoteActions id={id} />
      </div>
    </div>
  )
}

RealizationMenuShiftBookNoteReply.propTypes = {
  id: PropTypes.number,
  isEditing: PropTypes.bool,
  note: PropTypes.shape({
    body: PropTypes.string,
    createdAt: PropTypes.string,
    userName: PropTypes.string
  })
}

const mapStateToProps = (state, ownProps) => ({
  note: selectShiftBookNote(state, ownProps.id),
  isEditing: selectShiftBookEditingNoteId(state) === ownProps.id
})

export default connect(mapStateToProps)(RealizationMenuShiftBookNoteReply)
