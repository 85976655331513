import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape } from 'react-intl'
import { selectCurrentScheduleUpdate, selectManyScheduleUpdates, selectProject } from './selectors'
import { confirmScheduleUpdate, nextScheduleUpdate, prevScheduleUpdate } from './actions'

const DATE_FORMAT = 'DD/MM, H:mm'

class PlanUpdates extends React.Component {
  constructor (props) {
    super(props)
  }

  currentUpdate() {
    const update = this.props.update
    if (!update) return null

    const project = this.props.project.name || this.props.intl.formatMessage({ id: "workspace.plan.tooltip.stoppage" })

    switch(update.kind) {
      case 'priority':
        return this.props.intl.formatMessage(
          { id: 'workspace.scheduleUpdates.priority' },
          { project, from: update.from[0], to: update.to[0] }
        )
        case 'quantity':
          return this.props.intl.formatMessage(
            { id: 'workspace.scheduleUpdates.quantity' },
            { project, from: update.from[0], to: update.to[0] }
          )
        case 'time_range':
          return this.props.intl.formatMessage(
            { id: 'workspace.scheduleUpdates.timeRange' },
            {
              project,
              fromStart: moment(new Date(+update.from[0] * 1000)).format(DATE_FORMAT),
              fromEnd: moment(new Date(+update.from[1] * 1000)).format(DATE_FORMAT),
              toStart: moment(new Date(+update.to[0] * 1000)).format(DATE_FORMAT),
              toEnd: moment(new Date(+update.to[1] * 1000)).format(DATE_FORMAT),
            }
          )
        case 'significant':
          return this.props.intl.formatMessage(
            { id: 'workspace.scheduleUpdates.significant' }
          )
        default:
          return null
    }
  }

  onConfirm () {
    this.props.onConfirm(this.props.update.id)
  }

  onPrev () {
    this.props.onPrev(this.props.update.locationId)
  }

  onNext () {
    this.props.onNext(this.props.update.locationId)
  }

  render () {
    const update = this.currentUpdate()
    if (!update)
      return null

    return (
      <small>
        {update}
        &nbsp;&nbsp;
        <div className="btn btn--small" onClick={this.onConfirm.bind(this)}>
          {this.props.intl.formatMessage({ id: 'workspace.scheduleUpdates.confirm' })}
        </div>
        { this.props.many && <React.Fragment>
          &nbsp;&nbsp;
          <div className="btn btn--small" onClick={this.onPrev.bind(this)}>&lt;</div>
          &nbsp;
          <div className="btn btn--small" onClick={this.onNext.bind(this)}>&gt;</div>
        </React.Fragment> }
      </small>
    )
  }
}

PlanUpdates.propTypes = {
  update: PropTypes.object,
  project: PropTypes.object,
  onConfirm: PropTypes.func,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  intl: intlShape
}

const mapStateToProps = state => {
  const update = selectCurrentScheduleUpdate(state)
  let project = {}

  if (update && update.projectId) {
    project = selectProject(state, update.projectId)
  }

  return ({
    update,
    project,
    many: selectManyScheduleUpdates(state)
  })
}

const mapDispatchToProps = {
  onConfirm: confirmScheduleUpdate,
  onPrev: prevScheduleUpdate,
  onNext: nextScheduleUpdate
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PlanUpdates)
