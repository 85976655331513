import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, intlShape, defineMessages } from 'react-intl'
import Input from 'components/Input'
import { selectProduct, selectProductionRunFormData } from './selectors'

const messages = defineMessages({
  label: {
    id: 'workspace.menu.productionRunForm.lengthLabel',
    defaultMessage: 'Length'
  }
})

const ProductionRunFormLength = ({ id, intl, length }) => (
  <Input
    nested
    label={intl.formatMessage(messages.label)}
    className='workspace-form__control'
  >
    <input
      type='text'
      className='workspace-form__input'
      style={{ width: '100%', boxSizing: 'border-box' }}
      value={length || ''}
      readOnly
    />
  </Input>
)

ProductionRunFormLength.propTypes = {
  intl: intlShape.isRequired,
  length: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number
}

const mapStateToProps = state => {
  const id = selectProductionRunFormData(state).productId

  return {
    length: (selectProduct(state, id) || {}).length
  }
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(ProductionRunFormLength)
