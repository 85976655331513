import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import WorkspaceLocationToggle from 'workspace/WorkspaceLocationToggle'

const WorkspaceLocations = ({ ids }) => (
  <div className='btn-group btn-group--flex'>
    {ids.map(id => <WorkspaceLocationToggle key={id} id={id} />)}
  </div>
)

WorkspaceLocations.defaultProps = {
  ids: []
}

WorkspaceLocations.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = (state) => ({
  ids: state.locations.allIds
})

export default connect(mapStateToProps)(WorkspaceLocations)
