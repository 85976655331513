import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableProducts } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noProducts: {
    id: 'workspace.form.noProducts',
    defaultMessage: 'No matching products',
    description: 'Message displayed when there are no products for the selected location or search term'
  }
})

const WorkspaceFormProduct = ({ id, intl, products, onChange }) => (
  <WorkspaceFormInput attribute='product'>
    <AssociationSelect
      isClearable
      options={products}
      value={id}
      onChange={(newId, _, product) => onChange({ productId: newId, quantity: product ? product.packQuantity : '' })}
      labelMapper={prod => `${prod.description} (${prod.code})`}
      noOptionsMessage={() => intl.formatMessage(messages.noProducts)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormProduct.propTypes = {
  intl: intlShape.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    code: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  products: selectAvailableProducts(state),
  id: state.form.data.productId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormProduct)
