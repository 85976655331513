import { call, fork, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import Actions from 'workspace/actions'
import formDefaults from './formDefaults'
import formSubmission from './formSubmission'
import formChange from './formChange'
import formPrefill from './formPrefill'
import heartbeat from 'lib/saga/heartbeat'
import takeLeadingWithPayload from 'lib/saga/takeLeadingWithPayload'
import init from './init'
import navigation from './navigation'
import navigationInit from './navigationInit'
import history from './history'
import { edit as scheduleEdit, destroy as scheduleDestroy, confirmUpdate } from './schedule'
import {
  edit as realizationEdit,
  destroy as realizationDestroy
} from './realization'
import { edit as scrapEdit, destroy as scrapDestroy } from './scrap'
import {
  start as activenessStart,
  update as activenessUpdate,
  finish as activenessFinish
} from './activeness'
import {
  productionRunFormSubmit as productionRunStart,
  finish as productionRunFinish,
  productionRunFormChange,
  initProductionRunForm
} from './productionRunFormChange'
import { loadTimelines } from './timelines'
import reload from './reload'
import { SET_NAVIGATION } from 'containers/Navigation/actions'
import {
  fetchShiftBookNotes,
  createNote as createShiftBookNote,
  updateNote as updateShiftBookNote,
  destroyNote as destroyShiftBookNote,
  markAsRead as markShiftBookNoteAsRead,
  markAllAsRead as markAllShiftBookNotesAsRead,
  setReplyFromUrl,
  setResolved
} from './shiftBook'
import { fetchNotDownloadedAttachmentsCount } from './attachments'
import { doConfirmShiftNotification } from './realizations'

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'Workspace')
  }

  // Setup
  yield call(navigationInit)
  yield fork(reload)
  yield call(init)
  yield fork(history)
  yield call(loadTimelines)
  yield call(initProductionRunForm)

  // Side effects
  yield takeLatest([SET_NAVIGATION, Actions.SELECT_SINGLE_LOCATION], navigation)

  yield takeLatest(Actions.SET_MODE, navigationInit)

  yield takeEvery(Actions.SELECT_LOCATION, navigation)

  yield takeLeading(Actions.START_ACTIVENESS, activenessStart)
  yield takeLeading(Actions.UPDATE_ACTIVENESS, activenessUpdate)
  yield takeLeading(Actions.FINISH_ACTIVENESS, activenessFinish)

  yield takeLeading(
    [
      Actions.START_PRODUCTION_RUN,
      Actions.SET_FIRST_GOOD_PART,
      Actions.SET_LAST_GOOD_PART
    ],
    productionRunStart
  )
  yield takeLeading(Actions.FINISH_PRODUCTION_RUN, productionRunFinish)

  yield takeEvery(Actions.EDIT_SCHEDULE, scheduleEdit)
  yield takeEvery(Actions.DESTROY_SCHEDULE, scheduleDestroy)
  yield takeEvery(Actions.CONFIRM_SCHEDULE_UPDATE, confirmUpdate)
  yield takeEvery(Actions.CONFIRM_SHIFT_NOTIFICATION, doConfirmShiftNotification)

  yield takeEvery(Actions.EDIT_REALIZATION, realizationEdit)
  yield takeLeading(Actions.DESTROY_REALIZATION, realizationDestroy)

  yield takeEvery(Actions.EDIT_SCRAP, scrapEdit)
  yield takeLeading(Actions.DESTROY_SCRAP, scrapDestroy)

  yield takeEvery(Actions.OPEN_NEW_FORM, formDefaults)
  yield takeEvery(Actions.CHANGE_FORM_DATA, formChange)
  yield takeEvery(
    Actions.CHANGE_PRODUCTION_RUN_FORM_DATA,
    productionRunFormChange
  )
  yield takeEvery(Actions.SELECT_TIMELINE_BLOCK, formPrefill)
  yield takeLeading(Actions.SUBMIT_FORM, formSubmission)

  yield takeLatest(
    [
      SET_NAVIGATION,
      Actions.SELECT_SINGLE_LOCATION,
      Actions.RECEIVE_PRODUCTION_RUN
    ],
    initProductionRunForm
  )

  yield fork(fetchShiftBookNotes)
  yield fork(fetchNotDownloadedAttachmentsCount)
  yield takeLeading(Actions.RECEIVE_SHIFT_BOOK, setReplyFromUrl)
  yield takeEvery(
    [SET_NAVIGATION, Actions.SELECT_SINGLE_LOCATION],
    fetchNotDownloadedAttachmentsCount
  )
  yield takeEvery(
    [SET_NAVIGATION, Actions.SELECT_SINGLE_LOCATION, Actions.OPEN_SHIFT_BOOK],
    fetchShiftBookNotes
  )
  yield takeLeading([
    Actions.CREATE_SHIFT_BOOK_NOTE,
    Actions.SUBMIT_REPLY_SHIFT_BOOK_NOTE
  ], createShiftBookNote)
  yield takeLeading(Actions.SUBMIT_EDIT_SHIFT_BOOK_NOTE, updateShiftBookNote)
  yield takeLeading(Actions.DESTROY_SHIFT_BOOK_NOTE, destroyShiftBookNote)
  yield fork(
    takeLeadingWithPayload,
    Actions.MARK_SHIFT_BOOK_NOTE_AS_READ,
    markShiftBookNoteAsRead,
    JSON.stringify
  )
  yield takeLeading(Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ, markAllShiftBookNotesAsRead)
  yield takeLeading(Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED, setResolved)
}
