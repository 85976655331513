import { call, put, race, select, take, delay } from 'redux-saga/effects'
import {
  selectMode,
  selectSelectedLocation,
} from 'workspace/selectors'
import {
  requestAllSchedules,
  loadAllSchedules,
  loadAllScheduleUpdates,
} from './schedules'
import Actions from 'workspace/actions'
import { Modes } from 'workspace/const'
import { loadAllShiftNotifications } from './realizations'

const INTERVAL = 60 * 1000

export default function * reload () {
  const mode = yield select(selectMode)
  let location, result

  if (mode !== Modes.REALIZATION) {
    return
  }

  yield take(Actions.RECEIVE_SCHEDULES)
  location = yield select(selectSelectedLocation)

  while (true) {
    result = yield race({
      action: take(Actions.RECEIVE_SCHEDULES),
      timeout: delay(INTERVAL)
    })

    if (result.timeout) {
      yield call(requestAllSchedules)
      yield call(loadAllSchedules)
      yield call(loadAllScheduleUpdates)
      yield call(loadAllShiftNotifications)
    }
  }
}
