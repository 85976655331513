import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Timeline from 'components/Timeline'
import RealizationSummaryTimelineBlock from 'workspace/RealizationSummaryTimelineBlock'
import { selectNavigationRange } from 'containers/Navigation/selectors'

const RealizationSummaryTimeline = ({ id, date, scope, symbol, realizationSummaryIds, isLoading, lunchBreaks, start, finish }) => (
  <Timeline height={100} scope={scope} date={date} header={symbol} isLoading={isLoading} size={1} lunchBreaks={lunchBreaks} start={start} finish={finish}>
    {realizationSummaryIds.map(sid => <RealizationSummaryTimelineBlock key={sid} id={sid} />)}
  </Timeline>
)

RealizationSummaryTimeline.propTypes = {
  id: PropTypes.number.isRequired,
  symbol: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  scope: PropTypes.oneOf(['day', 'week', 'range']).isRequired,
  realizationSummaryIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  lunchBreaks: PropTypes.arrayOf(PropTypes.shape({
    startsAfterMidnight: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired
  })),
  isLoading: PropTypes.bool,
  start: PropTypes.object,
  finish: PropTypes.object
}

const mapStateToProps = (state, { id }) => ({
  date: selectNavigationRange(state).from,
  scope: state.navigation.shift ? 'range' : state.navigation.scope,
  start: state.navigation.shift ? selectNavigationRange(state).from : undefined,
  finish: state.navigation.shift ? selectNavigationRange(state).to : undefined,
  ...state.locations.allById[id],
  realizationSummaryIds: state.realizationSummaries.idsByLocation[id],
  isLoading: state.locations.isLoadingRealizationSummariesById[id]
})

export default connect(mapStateToProps)(RealizationSummaryTimeline)
