import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormChargeNumber = ({ chargeNumber, onChange }) => (
  <WorkspaceFormInput attribute='chargeNumber'>
    <input
      type='number'
      min='0'
      className='workspace-form__input'
      value={(chargeNumber || '')}
      onChange={(e) => onChange({ chargeNumber: e.target.value ? Number(e.target.value) : null })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormChargeNumber.propTypes = {
  chargeNumber: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  chargeNumber: state.form.data.chargeNumber
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormChargeNumber)
