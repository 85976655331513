import React from 'react'
import PropTypes from 'prop-types'
import { TIME_FORMAT } from 'lib/const'
import { shift } from 'lib/factoryTime'

const TimelineLabel = ({ date, showTime, showShift, showWeekday, showDate }) => (
  <div className='timeline-cell__label'>
    {showTime ? date.format(TIME_FORMAT) : ''}

    {showTime && showWeekday ? <br /> : ''}
    {showWeekday ? date.format('ddd').toUpperCase() : ''}
    {showShift ? ` ${shift(date.hour())}` : ''}

    {(showTime || showShift || showWeekday) && showDate ? <br /> : ''}
    {showDate ? date.format('DD-MM').toUpperCase() : ''}
  </div>
)

TimelineLabel.propTypes = {
  date: PropTypes.object,
  showTime: PropTypes.bool,
  showShift: PropTypes.bool,
  showWeekday: PropTypes.bool,
  showDate: PropTypes.bool
}

export default TimelineLabel
