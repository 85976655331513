import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Color from 'color'
import { selectSchedule, selectScheduleDetails } from 'workspace/selectors'
import { Characters } from 'lib/const'
import Unit from 'components/Unit'
import TimelineExtraContent from 'components/TimelineExtraContent'
import PlanTimelineRealization from 'workspace/PlanTimelineRealization'
import { formatDuration } from 'workspace/lib'

const PlanTimelineBlockContent = ({
  id,
  character,
  extraContainerId,
  project,
  product,
  productionPlan,
  stoppageType,
  stoppagePlan,
  onClick,
  rawColor,
  priority
}) => (
  <div
    className='timeline-block__content'
    onClick={onClick}
    style={{
      borderColor: rawColor && Color(rawColor).darken(0.5),
      backgroundColor: rawColor && Color(rawColor)
    }}
  >
    { character === 'changeover' && <div /> }
    {
      character === Characters.PRODUCTION &&
      <React.Fragment>
        <p className='timeline-block__line'>{priority}. {product.profile}, {product.length} mm, {productionPlan.productionCallNumber}</p>
        <p className='timeline-block__line'>
          <code>{productionPlan.quantity}x (<Unit value={productionPlan.piecesQuantity} unit='piece' />)</code>
        </p>
        <p className='timeline-block__line'><code><PlanTimelineRealization {...productionPlan} /></code></p>
      </React.Fragment>
    }
    {
      character === Characters.STOPPAGE &&
      <React.Fragment>
        <p className='timeline-block__line'>{priority}. {stoppageType.symbol} ({stoppageType.description})</p>
        <p className='timeline-block__line'><code>{formatDuration(stoppagePlan.duration)} min.</code></p>
      </React.Fragment>
    }
    {(stoppagePlan || productionPlan || {}).remarks && (
      <TimelineExtraContent containerId={`plan-tineline-extra-${id}`}>
        {(stoppagePlan || productionPlan || {}).remarks}
      </TimelineExtraContent>
    )}
  </div>
)

PlanTimelineBlockContent.propTypes = {
  id: PropTypes.number.isRequired,
  character: Characters.propType,
  project: PropTypes.shape({
    name: PropTypes.string
  }),
  productionPlan: PropTypes.shape({
    productionCallNumber: PropTypes.any,
    quantity: PropTypes.number,
    piecesQuantity: PropTypes.number,
    realizedQuantity: PropTypes.number
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string
  }),
  stoppagePlan: PropTypes.shape({
    duration: PropTypes.number
  }),
  onClick: PropTypes.func,
  rawColor: PropTypes.string,
  priority: PropTypes.number,
  extraContainerId: PropTypes.string
}

const mapStateToProps = (state, { id }) => ({
  ...selectSchedule(state, id),
  ...selectScheduleDetails(state, id)
})

export default connect(mapStateToProps)(PlanTimelineBlockContent)
