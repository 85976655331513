import { createAction } from 'redux-actions'

const Actions = {
  SET_TOKEN: 'SET_TOKEN',
  SET_MODE: 'SET_MODE',
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  SELECT_LOCATION: 'SELECT_LOCATION',
  SELECT_SINGLE_LOCATION: 'SELECT_SINGLE_LOCATION',
  UNSELECT_LOCATION: 'UNSELECT_LOCATION',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  RECEIVE_PRODUCTS: 'RECEIVE_PRODUCTS',
  REQUEST_SCHEDULES: 'REQUEST_SCHEDULES',
  REQUEST_SCHEDULE_UPDATES: 'REQUEST_SCHEDULE_UPDATES',
  RECEIVE_SCHEDULES: 'RECEIVE_SCHEDULES',
  RECEIVE_SCHEDULE_UPDATES: 'RECEIVE_SCHEDULE_UPDATES',
  CONFIRM_SCHEDULE_UPDATE: 'CONFIRM_SCHEDULE_UPDATE',
  PREV_SCHEDULE_UPDATE: 'PREV_SCHEDULE_UPDATE',
  NEXT_SCHEDULE_UPDATE: 'NEXT_SCHEDULE_UPDATE',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  DESTROY_SCHEDULE: 'DESTROY_SCHEDULE',
  REQUEST_REALIZATIONS: 'REQUEST_REALIZATIONS',
  RECEIVE_REALIZATIONS: 'RECEIVE_REALIZATIONS',
  EDIT_REALIZATION: 'EDIT_REALIZATION',
  DESTROY_REALIZATION: 'DESTROY_REALIZATION',
  OPEN_NEW_FORM: 'OPEN_NEW_FORM',
  OPEN_FORM: 'OPEN_FORM',
  CLOSE_FORM: 'CLOSE_FORM',
  SUBMIT_FORM: 'SUBMIT_FORM',
  ADD_FORM_ERRORS: 'ADD_FORM_ERRORS',
  CHANGE_FORM_DATA: 'CHANGE_FORM_DATA',
  RECEIVE_STOPPAGE_TYPES: 'RECEIVE_STOPPAGE_TYPES',
  RECEIVE_SCRAP_TYPES: 'RECEIVE_SCRAP_TYPES',
  RECEIVE_ACTIVENESSES: 'RECEIVE_ACTIVENESSES',
  REQUEST_ACTIVENESS: 'REQUEST_ACTIVENESS',
  RECEIVE_ACTIVENESS: 'RECEIVE_ACTIVENESS',
  START_ACTIVENESS: 'START_ACTIVENESS',
  ADD_ACTIVENESS_ERRORS: 'ADD_ACTIVENESS_ERRORS',
  FINISH_ACTIVENESS: 'FINISH_ACTIVENESS',
  UPDATE_ACTIVENESS: 'UPDATE_ACTIVENESS',
  DELETE_FINISHED_ACTIVENESS: 'DELETE_FINISHED_ACTIVENESS',
  RECEIVE_PERMISSIONS: 'RECEIVE_PERMISSIONS',
  SELECT_TIMELINE_BLOCK: 'SELECT_TIMELINE_BLOCK',
  RECEIVE_PREDEFINED_REMARKS: 'RECEIVE_PREDEFINED_REMARKS',
  RECEIVE_MACHINERIES: 'RECEIVE_MACHINERIES',
  SHOW_REALIZATION_BARCODE: 'SHOW_REALIZATION_BARCODE',
  SHOW_PLAN_BARCODE: 'SHOW_PLAN_BARCODE',
  SHOW_MIX_PLAN_BARCODE: 'SHOW_MIX_PLAN_BARCODE',
  UPDATE_PLAN_CHANGED: 'UPDATE_PLAN_CHANGED',
  RECEIVE_BREAKDOWNS: 'RECEIVE_BREAKDOWNS',
  RECEIVE_BREAKDOWN: 'RECEIVE_BREAKDOWN',
  START_BREAKDOWN: 'START_BREAKDOWN',
  FINISH_BREAKDOWN: 'FINISH_BREAKDOWN',
  REQUEST_SCRAPS: 'REQUEST_SCRAPS',
  RECEIVE_SCRAPS: 'RECEIVE_SCRAPS',
  EDIT_SCRAP: 'EDIT_SCRAP',
  DESTROY_SCRAP: 'DESTROY_SCRAP',
  REQUEST_REALIZATION_SUMMARIES: 'REQUEST_REALIZATION_SUMMARIES',
  RECEIVE_REALIZATION_SUMMARIES: 'RECEIVE_REALIZATION_SUMMARIES',
  RECEIVE_USERS: 'RECEIVE_USERS',
  RECEIVE_CURRENT_USER: 'RECEIVE_CURRENT_USER',
  RECEIVE_USER_WORK_PERIOD_ROLES: 'RECEIVE_USER_WORK_PERIOD_ROLES',
  CHANGE_ACTIVENESS_FORM_USER_WORK_PERIOD: 'CHANGE_ACTIVENESS_FORM_USER_WORK_PERIOD',
  ADD_ACTIVENESS_FORM_USER_WORK_PERIOD: 'ADD_ACTIVENESS_FORM_USER_WORK_PERIOD',
  DELETE_ACTIVENESS_FORM_USER_WORK_PERIOD: 'DELETE_ACTIVENESS_FORM_USER_WORK_PERIOD',
  RECEIVE_PRODUCTION_RUNS: 'RECEIVE_PRODUCTION_RUNS',
  RECEIVE_PRODUCTION_RUN: 'RECEIVE_PRODUCTION_RUN',
  START_PRODUCTION_RUN: 'START_PRODUCTION_RUN',
  CONFIRM_PRODUCTION_RUN: 'CONFIRM_PRODUCTION_RUN',
  SET_FIRST_GOOD_PART: 'SET_FIRST_GOOD_PART',
  SET_LAST_GOOD_PART: 'SET_LAST_GOOD_PART',
  CHANGE_PRODUCTION_RUN_FORM_DATA: 'CHANGE_PRODUCTION_RUN_FORM_DATA',
  CHANGE_PRODUCTION_RUN_FORM_COMPONENT: 'CHANGE_PRODUCTION_RUN_FORM_COMPONENT',
  ADD_PRODUCTION_RUN_ERRORS: 'ADD_PRODUCTION_RUN_ERRORS',
  FINISH_PRODUCTION_RUN: 'FINISH_PRODUCTION_RUN',
  DELETE_FINISHED_PRODUCTION_RUN: 'DELETE_FINISHED_PRODUCTION_RUN',
  DELETE_PRODUCTION_RUN_ERRORS: 'DELETE_PRODUCTION_RUN_ERRORS',
  INIT_PRODUCTION_RUN_FORM: 'INIT_PRODUCTION_RUN_FORM',
  OPEN_SHIFT_BOOK: 'OPEN_SHIFT_BOOK',
  RECEIVE_SHIFT_BOOK: 'RECEIVE_SHIFT_BOOK',
  CHANGE_SHIFT_BOOK_NOTE: 'CHANGE_SHIFT_BOOK_NOTE',
  CREATE_SHIFT_BOOK_NOTE: 'CREATE_SHIFT_BOOK_NOTE',
  EDIT_SHIFT_BOOK_NOTE: 'EDIT_SHIFT_BOOK_NOTE',
  REPLY_SHIFT_BOOK_NOTE: 'REPLY_SHIFT_BOOK_NOTE',
  SUBMIT_EDIT_SHIFT_BOOK_NOTE: 'SUBMIT_EDIT_SHIFT_BOOK_NOTE',
  CANCEL_EDIT_SHIFT_BOOK_NOTE: 'CANCEL_EDIT_SHIFT_BOOK_NOTE',
  SUBMIT_REPLY_SHIFT_BOOK_NOTE: 'SUBMIT_REPLY_SHIFT_BOOK_NOTE',
  CANCEL_REPLY_SHIFT_BOOK_NOTE: 'CANCEL_REPLY_SHIFT_BOOK_NOTE',
  DESTROY_SHIFT_BOOK_NOTE: 'DESTROY_SHIFT_BOOK_NOTE',
  SET_ERROR_SHIFT_BOOK: 'SET_ERROR_SHIFT_BOOK',
  CLEAR_DRAFT_SHIFT_BOOK: 'CLEAR_DRAFT_SHIFT_BOOK',
  MARK_SHIFT_BOOK_NOTE_AS_READ: 'MARK_SHIFT_BOOK_NOTE_AS_READ',
  MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS: 'MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS',
  MARK_ALL_SHIFT_BOOK_NOTES_AS_READ: 'MARK_ALL_SHIFT_BOOK_NOTES_AS_READ',
  MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS: 'MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS',
  SET_SHIFT_BOOK_NOTE_AS_RESOLVED: 'SET_SHIFT_BOOK_NOTE_AS_RESOLVED',
  SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS: 'SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS',
  INIT_ACTIVENESS_FORM: 'INIT_ACTIVENESS_FORM',
  REQUEST_SHIFT_NOTIFICATIONS: 'REQUEST_SHIFT_NOTIFICATIONS',
  RECEIVE_SHIFT_NOTIFICATIONS: 'RECEIVE_SHIFT_NOTIFICATIONS',
  CONFIRM_SHIFT_NOTIFICATION: 'CONFIRM_SHIFT_NOTIFICATION',
  RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT: 'RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT'
}

export default Actions

export const setToken = createAction(Actions.SET_TOKEN)
export const setMode = createAction(Actions.SET_MODE)
export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const selectLocation = createAction(Actions.SELECT_LOCATION)
export const selectSingleLocation = createAction(Actions.SELECT_SINGLE_LOCATION)
export const unselectLocation = createAction(Actions.UNSELECT_LOCATION)
export const receiveProjects = createAction(Actions.RECEIVE_PROJECTS)
export const receiveProducts = createAction(Actions.RECEIVE_PRODUCTS)
export const requestSchedules = createAction(Actions.REQUEST_SCHEDULES)
export const requestScheduleUpdates = createAction(Actions.REQUEST_SCHEDULE_UPDATES)
export const receiveSchedules = createAction(Actions.RECEIVE_SCHEDULES)
export const receiveScheduleUpdates = createAction(Actions.RECEIVE_SCHEDULE_UPDATES)
export const requestShiftNotifications = createAction(Actions.REQUEST_SHIFT_NOTIFICATIONS)
export const receiveShiftNotifications = createAction(Actions.RECEIVE_SHIFT_NOTIFICATIONS)
export const confirmShiftNotification = createAction(Actions.CONFIRM_SHIFT_NOTIFICATION)
export const confirmScheduleUpdate = createAction(Actions.CONFIRM_SCHEDULE_UPDATE)
export const prevScheduleUpdate = createAction(Actions.PREV_SCHEDULE_UPDATE)
export const nextScheduleUpdate = createAction(Actions.NEXT_SCHEDULE_UPDATE)
export const editSchedule = createAction(Actions.EDIT_SCHEDULE)
export const destroySchedule = createAction(Actions.DESTROY_SCHEDULE)
export const requestRealizations = createAction(Actions.REQUEST_REALIZATIONS)
export const receiveRealizations = createAction(Actions.RECEIVE_REALIZATIONS)
export const editRealization = createAction(Actions.EDIT_REALIZATION)
export const destroyRealization = createAction(Actions.DESTROY_REALIZATION)
export const openForm = createAction(Actions.OPEN_FORM, (type, data) => ({ type, data }))
export const openNewForm = createAction(Actions.OPEN_NEW_FORM, (type, data) => ({ type, data }))
export const closeForm = createAction(Actions.CLOSE_FORM)
export const submitForm = createAction(
  Actions.SUBMIT_FORM,
  (data, successfulMessage) => ({ data, successfulMessage })
)
export const addFormErrors = createAction(Actions.ADD_FORM_ERRORS)
export const changeFormData = createAction(Actions.CHANGE_FORM_DATA)
export const receiveStoppageTypes = createAction(Actions.RECEIVE_STOPPAGE_TYPES)
export const receiveScrapTypes = createAction(Actions.RECEIVE_SCRAP_TYPES)
export const receiveActivenesses = createAction(Actions.RECEIVE_ACTIVENESSES)
export const requestActiveness = createAction(Actions.REQUEST_ACTIVENESS)
export const receiveActiveness = createAction(Actions.RECEIVE_ACTIVENESS)
export const startActiveness = createAction(Actions.START_ACTIVENESS)
export const addActivenessErrors = createAction(Actions.ADD_ACTIVENESS_ERRORS)
export const finishActiveness = createAction(Actions.FINISH_ACTIVENESS)
export const updateActiveness = createAction(Actions.UPDATE_ACTIVENESS)
export const deleteFinishedActiveness = createAction(Actions.DELETE_FINISHED_ACTIVENESS)
export const receivePermissions = createAction(Actions.RECEIVE_PERMISSIONS)
export const selectTimelineBlock = createAction(Actions.SELECT_TIMELINE_BLOCK)
export const receivePredefinedRemarks = createAction(Actions.RECEIVE_PREDEFINED_REMARKS)
export const receiveMachineries = createAction(Actions.RECEIVE_MACHINERIES)
export const showRealizationBarcode = createAction(Actions.SHOW_REALIZATION_BARCODE)
export const showPlanBarcode = createAction(Actions.SHOW_PLAN_BARCODE)
export const showMixPlanBarcode = createAction(Actions.SHOW_MIX_PLAN_BARCODE)
export const receiveBreakdowns = createAction(Actions.RECEIVE_BREAKDOWNS)
export const receiveBreakdown = createAction(Actions.RECEIVE_BREAKDOWN)
export const requestScraps = createAction(Actions.REQUEST_SCRAPS)
export const receiveScraps = createAction(Actions.RECEIVE_SCRAPS)
export const editScrap = createAction(Actions.EDIT_SCRAP)
export const destroyScrap = createAction(Actions.DESTROY_SCRAP)
export const requestRealizationSummaries = createAction(Actions.REQUEST_REALIZATION_SUMMARIES)
export const receiveRealizationSummaries = createAction(Actions.RECEIVE_REALIZATION_SUMMARIES)
export const receiveUsers = createAction(Actions.RECEIVE_USERS)
export const receiveCurrentUser = createAction(Actions.RECEIVE_CURRENT_USER)
export const receiveUserWorkPeriodRoles = createAction(Actions.RECEIVE_USER_WORK_PERIOD_ROLES)
export const initActivenessForm = createAction(Actions.INIT_ACTIVENESS_FORM)
export const changeActivenessFormUserWorkPeriod = createAction(Actions.CHANGE_ACTIVENESS_FORM_USER_WORK_PERIOD)
export const addActivenessFormUserWorkPeriod = createAction(Actions.ADD_ACTIVENESS_FORM_USER_WORK_PERIOD)
export const deleteActivenessFormUserWorkPeriod = createAction(Actions.DELETE_ACTIVENESS_FORM_USER_WORK_PERIOD)
export const receiveProductionRuns = createAction(Actions.RECEIVE_PRODUCTION_RUNS)
export const receiveProductionRun = createAction(Actions.RECEIVE_PRODUCTION_RUN)
export const addProductionRunErrors = createAction(Actions.ADD_PRODUCTION_RUN_ERRORS)
export const startProductionRun = createAction(Actions.START_PRODUCTION_RUN)
export const confirmProductionRun = createAction(Actions.CONFIRM_PRODUCTION_RUN)
export const setFirstGoodPart = createAction(Actions.SET_FIRST_GOOD_PART)
export const setLastGoodPart = createAction(Actions.SET_LAST_GOOD_PART)
export const changeProductionRunFormData = createAction(Actions.CHANGE_PRODUCTION_RUN_FORM_DATA)
export const changeProductionRunFormComponent = createAction(Actions.CHANGE_PRODUCTION_RUN_FORM_COMPONENT)
export const finishProductionRun = createAction(Actions.FINISH_PRODUCTION_RUN)
export const deleteFinishedProductionRun = createAction(Actions.DELETE_FINISHED_PRODUCTION_RUN)
export const deleteProductionRunErrors = createAction(Actions.DELETE_PRODUCTION_RUN_ERRORS)
export const initProductionRunForm = createAction(Actions.INIT_PRODUCTION_RUN_FORM)
export const openShiftBook = createAction(Actions.OPEN_SHIFT_BOOK)
export const receiveShiftBook = createAction(Actions.RECEIVE_SHIFT_BOOK)
export const changeShiftBookNote = createAction(Actions.CHANGE_SHIFT_BOOK_NOTE)
export const createShiftBookNote = createAction(Actions.CREATE_SHIFT_BOOK_NOTE)
export const editShiftBookNote = createAction(Actions.EDIT_SHIFT_BOOK_NOTE)
export const replyShiftBookNote = createAction(Actions.REPLY_SHIFT_BOOK_NOTE)
export const submitEditShiftBookNote = createAction(Actions.SUBMIT_EDIT_SHIFT_BOOK_NOTE)
export const cancelEditShiftBookNote = createAction(Actions.CANCEL_EDIT_SHIFT_BOOK_NOTE)
export const submitReplyShiftBookNote = createAction(Actions.SUBMIT_REPLY_SHIFT_BOOK_NOTE)
export const cancelReplyShiftBookNote = createAction(Actions.CANCEL_REPLY_SHIFT_BOOK_NOTE)
export const destroyShiftBookNote = createAction(Actions.DESTROY_SHIFT_BOOK_NOTE)
export const setErrorShiftBook = createAction(Actions.SET_ERROR_SHIFT_BOOK)
export const clearDraftShiftBook = createAction(Actions.CLEAR_DRAFT_SHIFT_BOOK)
export const markShiftBookNoteAsRead = createAction(Actions.MARK_SHIFT_BOOK_NOTE_AS_READ)
export const markShiftBookNoteAsReadSuccess = createAction(Actions.MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS)
export const markAllShiftBookNotesAsRead = createAction(Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ)
export const markAllShiftBookNotesAsReadSuccess =
  createAction(Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS)
export const setShiftBookNoteAsResolved = createAction(Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED)
export const setShiftBookNoteAsResolvedSuccess = createAction(Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS)
export const receiveNotDownloadedAttachmentsCount = createAction(Actions.RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT)
