import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectAvailableStoppageTypes } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormStoppageType = ({ id, stoppageTypes, onChange }) => (
  <WorkspaceFormInput attribute='stoppageType'>
    <AssociationSelect
      isClearable={false}
      backspaceRemovesValue={false}
      options={stoppageTypes}
      value={id}
      onChange={newId => onChange({ stoppageTypeId: newId })}
      labelMapper={st => `${st.symbol} - ${st.description}`}
    />
  </WorkspaceFormInput>
)

WorkspaceFormStoppageType.propTypes = {
  stoppageTypes: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  stoppageTypes: selectAvailableStoppageTypes(state),
  id: state.form.data.stoppageTypeId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormStoppageType)
