import { all, call, put, select } from 'redux-saga/effects'
import api from 'lib/api/client'
import { closeForm, addFormErrors, receiveBreakdowns } from 'workspace/actions'
import { selectToken, selectForm } from 'workspace/selectors'
import { requestLocationSchedules, loadLocationSchedules } from './schedules'
import { requestLocationRealizations, loadLocationRealizations } from './realizations'
import { requestLocationRealizationSummaries, loadLocationRealizationSummaries } from './realizationSummaries'
import { requestLocationScraps, loadLocationScraps } from './scraps'
import { DATE_FORMAT } from 'lib/const'
import Flash from 'ujs/Flash'
import { FormTypes } from 'workspace/const'

const PARAM_BASES = {
  [FormTypes.PRODUCTION]: 'production_plan',
  [FormTypes.STOPPAGE]: 'stoppage_plan',
  [FormTypes.REALIZATION]: 'location_realization',
  [FormTypes.REALIZATION_CHANGEOVER]: 'location_realization',
  [FormTypes.REALIZATION_STOPPAGE]: 'location_realization',
  [FormTypes.REALIZATION_SCRAP]: 'scrap',
  [FormTypes.REALIZATION_UNREPORTED]: 'location_realization',
  [FormTypes.BREAKDOWN_START]: 'breakdown_issue',
  [FormTypes.BREAKDOWN_FINISH]: 'breakdown_issue'
}

const URLS_BASES = {
  [FormTypes.PRODUCTION]: '/production_plans',
  [FormTypes.STOPPAGE]: '/stoppage_plans',
  [FormTypes.REALIZATION]: '/location_realizations',
  [FormTypes.REALIZATION_CHANGEOVER]: '/location_realizations',
  [FormTypes.REALIZATION_STOPPAGE]: '/location_realizations',
  [FormTypes.REALIZATION_SCRAP]: '/scraps',
  [FormTypes.REALIZATION_UNREPORTED]: '/location_realizations',
  [FormTypes.BREAKDOWN_START]: '/breakdown_issues',
  [FormTypes.BREAKDOWN_FINISH]: '/breakdown_issues'
}

function * formSubmission ({ payload: { data, successfulMessage } }) {
  const token = yield select(selectToken)
  const form = yield select(selectForm)

  const params = {
    [PARAM_BASES[form.type]]: {
      ...data,
      date: data.date && data.date.format ? data.date.format(DATE_FORMAT) : data.date,
      startedAt: data.startedAt ? data.startedAt.toISOString() : data.startedAt,
      finishedAt: data.finishedAt ? data.finishedAt.toISOString() : data.finishedAt,
      initialPriority: data.initialPriority || data.priority || undefined
    }
  }

  try {
    if (form.type === FormTypes.BREAKDOWN_FINISH) {
      yield call(api.put, URLS_BASES[form.type] + '/' + data.id + '/resolve', params, { token })
    } else if (data.id && form.type !== FormTypes.REALIZATION_UNREPORTED) {
      yield call(api.put, URLS_BASES[form.type] + '/' + data.id, params, { token })
    } else {
      yield call(api.post, URLS_BASES[form.type], params, { token })
    }

    if ([
      FormTypes.REALIZATION,
      FormTypes.REALIZATION_STOPPAGE,
      FormTypes.REALIZATION_CHANGEOVER,
      FormTypes.REALIZATION_SCRAP,
      FormTypes.REALIZATION_UNREPORTED
    ].includes(form.type)) {
      yield all([
        call(requestLocationSchedules, data.locationId),
        call(requestLocationRealizations, data.locationId),
        call(requestLocationRealizationSummaries, data.locationId),
        call(requestLocationScraps, data.locationId)
      ])
      yield all([
        call(loadLocationSchedules, data.locationId),
        call(loadLocationRealizationSummaries, data.locationId),
        call(loadLocationRealizations, data.locationId),
        call(loadLocationScraps, data.locationId)
      ])
    } else {
      yield call(requestLocationSchedules, data.locationId)
      yield call(loadLocationSchedules, data.locationId)
    }

    if ([FormTypes.PRODUCTION, FormTypes.STOPPAGE].includes(form.type)) {
      yield call(Flash.notice, successfulMessage)
    }

    if ([FormTypes.BREAKDOWN_START, FormTypes.BREAKDOWN_FINISH].includes(form.type)) {
      const breakdowns = yield call(api.get, '/breakdown_issues', { token })
      yield put(receiveBreakdowns(breakdowns))
    }

    yield put(closeForm())
  } catch (e) {
    if (e.response && e.response.status === 422 && e.response.data) {
      yield put(addFormErrors(e.response.data.errors))
    } else if (e.response) {
      yield put(closeForm())
    } else {
      throw e
    }
  }
}

export default formSubmission
