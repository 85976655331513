import { fork, take } from 'redux-saga/effects'

function * takeLeadingWithPayload (pattern, saga, transform = v => v) {
  const tasks = []
  while (true) {
    const action = yield take(pattern)
    const transformed = transform(action.payload)
    if (tasks.includes(transformed)) {
      continue
    }

    tasks.push(transformed)
    const task = yield fork(saga, action)
    task.toPromise().then(() => {
      tasks.splice(tasks.indexOf(transformed), 1)
    })
  }
}

export default takeLeadingWithPayload
