import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Unit from 'components/Unit'
import {
  selectRealizationSummary,
  selectRealizationSummaryProject,
  selectRealizationSummaryDuration,
  selectProductByProjectId
} from 'workspace/selectors'
import { DATETIME_FORMAT } from 'lib/const'
import { formatDuration } from 'workspace/lib'

const RealizationSummaryTimelineTooltip = ({
  changeover,
  duration,
  production,
  finishedAt,
  id,
  project,
  product,
  scrap,
  startedAt,
  stoppageDuration
}) => (
  <div
    className={classnames('timeline-tooltip', `timeline-tooltip--secondary`)}
  >
    <div className='timeline-tooltip__content'>
      <table className='timeline-tooltip__table'>
        <tbody>
          <tr>

            <th />
            <th>
              {
                changeover
                  ? <FormattedMessage id='workspace.realizationSummary.tooltip.changeover' defaultMessage='Changeover' />
                  : project.name
              }
            </th>
          </tr>
          {
            !changeover && <React.Fragment>
              <tr>
                <th><FormattedMessage id='workspace.realizationSummary.block.production' defaultMessage='Production' />:</th>
                <td>
                  <code>
                    <Unit value={production} unit='piece' />,{' '}
                    <Unit value={Math.round(((production * product.length) / 1000))} unit='meter' />
                  </code>
                </td>
              </tr>
              <tr>
                <th><FormattedMessage id='workspace.realizationSummary.tooltip.stoppageDuration' defaultMessage='Stoppage' />:</th>
                <td><code><Unit value={stoppageDuration.toFixed(0)} unit='minute' /></code></td>
              </tr>
              <tr>
                <th><FormattedMessage id='workspace.realizationSummary.tooltip.scrap' defaultMessage='Scrap' />:</th>
                <td><code><Unit value={scrap} unit='meter' /></code></td>
              </tr>
            </React.Fragment>
          }
          <tr>
            <th><FormattedMessage id='workspace.realizationSummary.tooltip.duration' defaultMessage='Duration' />:</th>
            <td><code><Unit value={formatDuration(duration)} unit='minute' /></code></td>
          </tr>
          <tr>
            <th><FormattedMessage id='workspace.realizationSummary.tooltip.start' defaultMessage='Start' />:</th>
            <td><code>{startedAt.format(DATETIME_FORMAT)}</code></td>
          </tr>
          <tr>
            <th><FormattedMessage id='workspace.realizationSummary.tooltip.end' defaultMessage='End' />:</th>
            <td><code>{finishedAt.format(DATETIME_FORMAT)}</code></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

RealizationSummaryTimelineTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  startedAt: PropTypes.object,
  finishedAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  stoppageDuration: PropTypes.number,
  scrap: PropTypes.number,
  duration: PropTypes.number.isRequired,
  changeover: PropTypes.bool.isRequired,
  production: PropTypes.number,
  product: PropTypes.object
}

const mapStateToProps = (state, { id }) => {
  const project = selectRealizationSummaryProject(state, id)

  return {
    ...selectRealizationSummary(state, id),
    project,
    product: selectProductByProjectId(state, project.id),
    duration: selectRealizationSummaryDuration(state, id)
  }
}

export default connect(mapStateToProps)(RealizationSummaryTimelineTooltip)
