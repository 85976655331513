import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { changeFormData } from 'workspace/actions'

const WorkspaceFormRemarks = ({ ekanbanReservationNumber, onChange }) => (
  <WorkspaceFormInput attribute='ekanbanReservationNumber'>
    <input
      type='text'
      className='workspace-form__input'
      value={ekanbanReservationNumber || ''}
      onChange={(e) => onChange({ ekanbanReservationNumber: e.target.value })}
    />
  </WorkspaceFormInput>
)

WorkspaceFormRemarks.propTypes = {
  ekanbanReservationNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  ekanbanReservationNumber: state.form.data.ekanbanReservationNumber
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFormRemarks)
