import React from 'react'
import Select from 'react-select'

export default (props) => (
  <Select
    {...props}
    className='react-select'
    classNamePrefix='react-select'
  />
)
