import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Icon from 'components/Icon'
import moment from 'moment-timezone'
import { isLocationLoadingSchedules, selectPlanTimestamp } from 'workspace/selectors'
import { DATETIME_FORMAT } from 'lib/const'

const RECENCY_THRESHOLD = 8

class PlanTimestamp extends React.Component {
  isRecent = () => {
    if (!this.props.updatedAt) {
      return false
    }

    const { updatedAt } = this.props
    const threshold = moment.tz(updatedAt.tz()).subtract(RECENCY_THRESHOLD, 'hours')

    return updatedAt.isAfter(threshold)
  }

  render () {
    const { updatedAt, isLoading } = this.props

    return (
      <span className='small' style={{ fontWeight: this.isRecent() ? 'bold' : 'normal', lineHeight: '26px' }}>
        &nbsp;
        [
        { isLoading && <Icon name='cog' spin /> }
        { !isLoading && updatedAt === null && '---' }
        { !isLoading && updatedAt && <code>{updatedAt.format(DATETIME_FORMAT)}</code> }
        ]
        &nbsp;
      </span>
    )
  }
}

PlanTimestamp.propTypes = {
  updatedAt: PropTypes.shape({ format: PropTypes.func.isRequired }),
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
  const locationId = state.locations.selectedIds[0]

  return {
    updatedAt: selectPlanTimestamp(state, locationId),
    isLoading: isLocationLoadingSchedules(state, locationId)
  }
}

export default compose(
  connect(mapStateToProps),
  injectIntl
)(PlanTimestamp)
