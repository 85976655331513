import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ButtonGroup = ({ children, flex, nested, compact, tableActions }) => (
  <div
    className={classnames('btn-group', {
      'btn-group--nested': nested,
      'btn-group--flex': flex,
      'btn-group--compact': compact,
      'btn-group--table-actions': tableActions
    })}
  >
    {children}
  </div>
)

ButtonGroup.propTypes = {
  children: PropTypes.any,
  flex: PropTypes.bool,
  nested: PropTypes.bool,
  compact: PropTypes.bool,
  tableActions: PropTypes.bool
}

export default ButtonGroup
