import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classnames from 'classnames'
import Color from 'color'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { editSchedule, destroySchedule, showPlanBarcode, showMixPlanBarcode } from 'workspace/actions'
import {
  selectIsScheduleEditable,
  selectSchedule,
  selectScheduleDetails
} from 'workspace/selectors'
import { Characters, DATETIME_FORMAT } from 'lib/const'
import Unit from 'components/Unit'
import PlanTimelineRealization from 'workspace/PlanTimelineRealization'
import { formatDuration } from 'workspace/lib'
import PlanTimelineComponentRealization from './PlanTimelineComponentRealization'

const CHARACTER_COLOR = {
  [Characters.PRODUCTION]: 'success',
  [Characters.STOPPAGE]: 'danger',
  [Characters.CHANGEOVER]: 'secondary'
}

const PlanTimelineTooltip = ({
  id,
  character,
  startsAt,
  endsAt,
  project,
  product,
  productionPlan,
  stoppageType,
  stoppagePlan,
  edit,
  destroy,
  rawColor,
  isEditable,
  showBarcode,
  showMixBarcode,
  priority,
  productionRunCount
}) => (
  <div
    className={classnames(
      'timeline-tooltip',
      `timeline-tooltip--${CHARACTER_COLOR[character]}`
    )}
  >
    <div
      className='timeline-tooltip__content'
      style={{ borderColor: rawColor && Color(rawColor).darken(0.5) }}
    >
      <table className='timeline-tooltip__table'>
        <tbody>
          <tr>
            <th />
            <th>
              {character === Characters.STOPPAGE && (
                <FormattedMessage
                  id='workspace.plan.tooltip.stoppage'
                  defaultMessage='Stoppage'
                />
              )}
              {character === Characters.PRODUCTION && (
                <FormattedMessage
                  id='workspace.plan.tooltip.production'
                  defaultMessage='Production'
                />
              )}
              {character === Characters.CHANGEOVER && (
                <FormattedMessage
                  id='workspace.plan.tooltip.changeover'
                  defaultMessage='Changeover'
                />
              )}
            </th>
          </tr>
          <tr>
            <th>
              <FormattedMessage
                id='workspace.plan.tooltip.priority'
                defaultMessage='Priority'
              />
            </th>
            <td>{priority}</td>
          </tr>
          {character === Characters.PRODUCTION && (
            <React.Fragment>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.profile'
                    defaultMessage='Profile'
                  />
                  :
                </th>
                <td>{product.profile}</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.length'
                    defaultMessage='Length'
                  />
                  :
                </th>
                <td>{product.length}</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.productionCallNumber'
                    defaultMessage='PCN'
                  />
                  :
                </th>
                <td>
                  {character === Characters.PRODUCTION &&
                    productionPlan.productionCallNumber}
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.ekanbanReservation'
                  />
                  :
                </th>
                <td>
                  {character === Characters.PRODUCTION && (
                    productionPlan.ekanbanReservationNumber || '---')}
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.project'
                    defaultMessage='Material'
                  />
                  :
                </th>
                <td>{product.description} ({product.code})</td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.quantity'
                    defaultMessage='Quantity'
                  />
                  :
                </th>
                <td>
                  <code>
                    {productionPlan.quantity}x (
                    <Unit value={productionPlan.piecesQuantity} unit='piece' />)
                  </code>
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.realizedQuantity'
                    defaultMessage='Realization'
                  />
                  :
                </th>
                <td>
                  <code>
                    <PlanTimelineRealization {...productionPlan} />
                  </code>
                </td>
              </tr>
              {product.extruderProductComponents.map((component) => (
                <tr key={component.id}>
                  <th>
                    { `${component.code} ${component.description.slice(0, 9)}` }
                  </th>
                  <td>
                    <PlanTimelineComponentRealization
                      unit={component.unit}
                      quantity={+component.quantity}
                      realizedQuantity={productionPlan.realizedQuantity}
                      realizedScrapQuantity={+productionPlan.realizedScrapQuantity}
                      piecesQuantity={productionPlan.piecesQuantity}
                    />
                  </td>
                </tr>
              ))}
            </React.Fragment>
          )}
          {character === Characters.STOPPAGE && (
            <React.Fragment>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.stoppageType'
                    defaultMessage='Type'
                  />
                  :
                </th>
                <td>
                  {stoppageType.symbol} - {stoppageType.description}
                </td>
              </tr>
              <tr>
                <th>
                  <FormattedMessage
                    id='workspace.plan.tooltip.duration'
                    defaultMessage='Duration'
                  />
                  :
                </th>
                <td>{formatDuration(stoppagePlan.duration)} min.</td>
              </tr>
            </React.Fragment>
          )}
          <tr>
            <th>
              <FormattedMessage
                id='workspace.plan.tooltip.remarks'
                defaultMessage='Remarks'
              />
              :
            </th>
            <td className='significant'>{(stoppagePlan || productionPlan).remarks}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage
                id='workspace.plan.tooltip.start'
                defaultMessage='Start'
              />
              :
            </th>
            <td>
              <code>{startsAt.format(DATETIME_FORMAT)}</code>
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage
                id='workspace.plan.tooltip.end'
                defaultMessage='End'
              />
              :
            </th>
            <td>
              <code>{endsAt.format(DATETIME_FORMAT)}</code>
            </td>
          </tr>
          {character === Characters.PRODUCTION && (
            <tr>
              <th>
                <FormattedMessage
                  id='workspace.plan.tooltip.duration'
                  defaultMessage='Duration'
                />
                :
              </th>
              <td>{formatDuration(productionPlan.duration)} min.</td>
            </tr>
          )}
          {isEditable && character !== Characters.CHANGEOVER && (
            <React.Fragment>
              <tr>
                <th />
                <td>
                  <Button size='small' onClick={e => edit(id)}>
                    <Icon name='pencil' />
                  </Button>
                  &nbsp;
                  {productionRunCount === 0 && (
                    <Button size='small' onClick={e => destroy(id)}>
                      <Icon name='trash' />
                    </Button>
                  )}
                </td>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
        {productionPlan && (
          <Button
            onClick={e => showMixBarcode(productionPlan.id)}
          >
            <FormattedMessage
              id='workspace.plan.tooltip.mixBarcode'
              defaultMessage='Mischungsbestand'
            />
          </Button>
        )}
        {productionPlan && productionPlan.ekanbanReservationNumber && (
          <Button
            onClick={e => showBarcode(productionPlan.id)}
          >
            <Icon name='barcode' />
          </Button>
        )}
      </div>
    </div>
  </div>
)

PlanTimelineTooltip.propTypes = {
  id: PropTypes.number.isRequired,
  character: Characters.propType,
  startsAt: PropTypes.object,
  endsAt: PropTypes.object,
  project: PropTypes.shape({
    name: PropTypes.string
  }),
  product: PropTypes.shape({
    profile: PropTypes.string
  }),
  productionPlan: PropTypes.shape({
    quantity: PropTypes.number,
    productionCallNumber: PropTypes.string
  }),
  stoppageType: PropTypes.shape({
    symbol: PropTypes.string,
    description: PropTypes.string
  }),
  stoppagePlan: PropTypes.shape({
    duration: PropTypes.number,
    remarks: PropTypes.string
  }),
  edit: PropTypes.func,
  destroy: PropTypes.func,
  isEditable: PropTypes.bool,
  rawColor: PropTypes.string,
  showBarcode: PropTypes.func,
  showMixBarcode: PropTypes.func,
  priority: PropTypes.number
}

const mapStateToProps = (state, { id }) => ({
  ...selectSchedule(state, id),
  ...selectScheduleDetails(state, id),
  isEditable: selectIsScheduleEditable(state, id)
})

const mapDispatchToProps = {
  edit: editSchedule,
  destroy: destroySchedule,
  showBarcode: showPlanBarcode,
  showMixBarcode: showMixPlanBarcode
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanTimelineTooltip)
