import { call, put, select } from 'redux-saga/effects'
import Actions, { closeForm } from 'workspace/actions'
import { selectMode } from 'workspace/selectors'
import { loadTimeline, loadTimelines } from './timelines'

function * navigation (action) {
  const mode = yield select(selectMode)

  if (mode === 'realization') {
    yield put(closeForm())
  }

  if (action.type === Actions.SELECT_LOCATION) {
    yield call(loadTimeline, action.payload)
  } else {
    yield call(loadTimelines)
  }
}

export default navigation
