import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectMode, selectSelectedLocation } from 'workspace/selectors'
import {
  selectNavigationDate,
  selectNavigationScope,
  selectNavigationShift
} from 'containers/Navigation/selectors'
import { Modes } from 'workspace/const'
import { FormattedMessage } from 'react-intl'
import DropdownButton from 'components/DropdownButton'
import Button from 'components/Button'
import Icon from 'components/Icon'

const sectionStyles = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  padding: 5,
  backgroundColor: 'white',
  margin: 2,
  marginTop: 0,
  marginBottom: 0
}

const WorkspaceReports = ({
  mode,
  navigationDate,
  navigationScope,
  location,
  shift
}) =>
  mode === 'realization' ? (
    <div style={{ display: 'flex', gap: 15 }}>
      {navigationScope === 'day' &&
        shiftReport(navigationDate, location, shift)}
      { location.active === 'active' && (
        <a href={`/reports/location_summary.xlsx?calendar_week=${navigationDate.week()}&year=${navigationDate.year()}`}>
          <Button color='accent'>
            <FormattedMessage
              id='workspace.reports.locationSummary'
            />
          </Button>
        </a>
      )}
    </div>
  ) : null

const shiftReport = (navigationDate, location, shift) => (
  <DropdownButton
    color='accent'
    dropdownColor='accent'
    className='workspace-menu__element'
    popperConfig={{ placement: 'bottom-start' }}
    style={{ marginLeft: 18 }}
    content={() => (
      <div style={{ marginTop: 2, marginBottom: 2, width: 200 }}>
        <div style={sectionStyles}>
          {shiftProtocolLinks(navigationDate, location, shift)}
        </div>
      </div>
    )}
  >
    <FormattedMessage
      id='workspace.reports.shiftProtocol'
      defaultMessage='Shift protocol'
    />
  </DropdownButton>
)

const shiftProtocolLinks = (date, location, shift) => {
  const shifts = shift === null ? [1, 2, 3] : [shift]

  return shifts.map(shiftNumber => (
    <>
      <span
        style={{
          color: '#666',
          fontSize: '0.875em',
          textTransform: 'uppercase'
        }}
      >
        <FormattedMessage id='workspace.reports.shift' defaultMessage='Shift' />{' '}
        {shiftNumber}
        {` (${date.format('YYYY-MM-DD')})`}
      </span>
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        key={shiftNumber}
      >
        <a
          href={
            '/reports/shift_protocol.xlsx?date=' +
            date.format('DD-MM-YYYY') +
            '&shift=' +
            shiftNumber +
            '&location=' +
            location.id
          }
          format='xlsx'
          style={{ display: 'contents' }}
          target='_blank'
        >
          <Button color='success' style={{ margin: 5 }}>
            <Icon name='file-excel-o' size='lg' />
          </Button>
        </a>
        <a
          href={
            '/reports/shift_protocol.pdf?date=' +
            date.format('DD-MM-YYYY') +
            '&shift=' +
            shiftNumber +
            '&location=' +
            location.id
          }
          format='xlsx'
          style={{ display: 'contents' }}
          target='_blank'
        >
          <Button color='danger' style={{ margin: 5 }}>
            <Icon name='file-pdf-o' size='lg' />
          </Button>
        </a>
      </div>
    </>
  ))
}

WorkspaceReports.propTypes = {
  mode: Modes.propType,
  location: PropTypes.object,
  navigationDate: PropTypes.object.isRequired,
  navigationScope: PropTypes.oneOf(['day', 'week']).isRequired,
  shift: PropTypes.number
}

const mapStateToProps = state => ({
  mode: selectMode(state),
  navigationDate: selectNavigationDate(state),
  navigationScope: selectNavigationScope(state),
  location: selectSelectedLocation(state),
  shift: selectNavigationShift(state)
})

export default connect(mapStateToProps)(WorkspaceReports)
