import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import AssociationSelect from 'components/AssociationSelect'
import WorkspaceFormInput from 'workspace/WorkspaceFormInput'
import { selectUsersForMachinery } from 'workspace/selectors'
import { changeFormData } from 'workspace/actions'

const messages = defineMessages({
  noResponsibles: {
    id: 'workspace.form.noResponsible',
    defaultMessage: 'No matching responsible',
    description: 'Message displayed when there are no responsible available for the selected location or search term'
  }
})

const WorkspaceFormResponsible = ({ id, intl, responsibles, onChange }) => (
  <WorkspaceFormInput attribute='responsible'>
    <AssociationSelect
      isClearable
      options={responsibles}
      value={id}
      onChange={(newId) => onChange({ responsibleId: newId })}
      labelMapper={responsible => `${responsible.lastName} ${responsible.firstName}`}
      noOptionsMessage={() => intl.formatMessage(messages.noResponsibles)}
    />
  </WorkspaceFormInput>
)

WorkspaceFormResponsible.propTypes = {
  intl: intlShape.isRequired,
  responsibles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastName: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired
  })),
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  responsibles: selectUsersForMachinery(state),
  id: state.form.data.responsibleId
})

const mapDispatchToProps = ({
  onChange: changeFormData
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(WorkspaceFormResponsible)
