import _ from 'lodash'
import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'

import { allById, allIds, customById, idsByCustom, idsByCustomCollection, receiveCollection } from 'lib/reducers'
import Actions from 'workspace/actions'
import navigation from 'containers/Navigation/reducer'
import { SET_NAVIGATION } from 'containers/Navigation/actions'
import moment from 'moment-timezone'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token }),
    [Actions.SET_MODE]: (state, { payload: mode }) => ({ ...state, mode })
  },
  { token: null, mode: null, locale: null, timezone: null }
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

export const locations = handleActions(
  {
    [Actions.SELECT_LOCATION]: ({ selectedIds, ...rest }, { payload: id }) => (
      {
        ...rest,
        selectedIds: _.uniq([...selectedIds, id])
      }
    ),
    [Actions.SELECT_SINGLE_LOCATION]: ({ selectedIds, ...rest }, { payload: id }) => (
      {
        ...rest,
        selectedIds: [id]
      }
    ),
    [Actions.UNSELECT_LOCATION]: ({ selectedIds, ...rest }, { payload: id }) => (
      {
        ...rest,
        selectedIds: _.without(selectedIds, id)
      }
    ),
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      allById: allById(locations),
      allIds: allIds(locations),
      selectedIds: [locations[0].id],
      isLoadingSchedulesById: customById(locations, false),
      isLoadingRealizationsById: customById(locations, false),
      isLoadingScrapsById: customById(locations, false)
    }),
    [Actions.REQUEST_SCHEDULES]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingSchedulesById: {
        ...state.isLoadingSchedulesById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_SCHEDULES]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingSchedulesById: {
        ...state.isLoadingSchedulesById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_REALIZATIONS]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingRealizationsById: {
        ...state.isLoadingRealizationsById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_REALIZATIONS]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingRealizationsById: {
        ...state.isLoadingRealizationsById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_SCRAPS]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingScrapsById: {
        ...state.isLoadingScrapsById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_SCRAPS]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingScrapsById: {
        ...state.isLoadingScrapsById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_REALIZATION_SUMMARIES]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingRealizationSummariesById: {
        ...state.isLoadingRealizationSummariesById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_REALIZATION_SUMMARIES]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingRealizationSummariesById: {
        ...state.isLoadingRealizationSummariesById,
        [locationId]: false
      }
    })
  },
  {
    allById: {},
    allIds: [],
    selectedIds: [],
    isLoadingSchedulesById: {},
    isLoadingRealizationsById: {},
    isLoadingScrapsById: {},
    isLoadingRealizationSummariesById: {}
  }
)

export const projects = handleActions(
  {
    [Actions.RECEIVE_PROJECTS]: (state, { payload: projects }) => ({
      allById: allById(projects),
      allIds: allIds(projects)
    })
  },
  { allById: {}, allIds: [] }
)

export const products = handleActions(
  {
    [Actions.RECEIVE_PRODUCTS]: (state, { payload: collection }) => ({
      ...state,
      allById: allById(collection),
      allIds: allIds(collection),
      idsByProject: idsByCustomCollection(collection, 'projectIds')
    })
  },
  { allById: {}, allIds: [], idsByProject: [] }
)

const stoppageTypes = handleActions(
  {
    [Actions.RECEIVE_STOPPAGE_TYPES]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

export const scrapTypes = handleActions(
  {
    [Actions.RECEIVE_SCRAP_TYPES]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const schedules = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, []),
    }),
    [Actions.RECEIVE_SCHEDULES]: (state, { payload: { locationId, schedules } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(schedules)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(schedules))
      }
    }),
    [SET_NAVIGATION]: (state) => ({ ...state }),
    [Actions.SELECT_SINGLE_LOCATION]: (state) => ({ ...state }),
    [Actions.SELECT_LOCATION]: (state) => ({ ...state })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const scheduleUpdates = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, []),
    }),
    [Actions.RECEIVE_SCHEDULE_UPDATES]: (state, { payload: { locationId, scheduleUpdates } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(scheduleUpdates)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(scheduleUpdates))
      }
    }),
    [Actions.PREV_SCHEDULE_UPDATE]: (state, { payload: locationId }) => ({
      ...state,
      offset: state.offset === 0 ? state.idsByLocation[locationId].length - 1 :
        Math.min(Math.max(state.offset - 1, 0), state.idsByLocation[locationId].length - 1)
    }),
    [Actions.NEXT_SCHEDULE_UPDATE]: (state, { payload: locationId }) => ({
      ...state,
      offset: state.offset === state.idsByLocation[locationId].length - 1 ? 0 :
        Math.min(state.offset + 1, state.idsByLocation[locationId].length - 1)
    })
  },
  {
    allById: {},
    idsByLocation: {},
    offset: 0
  }
)

const realizations = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REALIZATIONS]: (state, { payload: { locationId, realizations } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(realizations)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(realizations))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const shiftNotifications = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_SHIFT_NOTIFICATIONS]: (state, { payload: { locationId, shiftNotifications } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(shiftNotifications)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(shiftNotifications))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const DEFAULT_FORM_STATE = { isActive: false, isLoading: false, type: null, errors: {}, data: {} }
const form = handleActions(
  {
    [Actions.START_INIT]: (state) => DEFAULT_FORM_STATE,
    [Actions.OPEN_FORM]: (state, { payload: { type, data } }) => ({
      ...state,
      isActive: true,
      type,
      data
    }),
    [Actions.CLOSE_FORM]: (state) => DEFAULT_FORM_STATE,
    [Actions.SUBMIT_FORM]: (state) => ({ ...state, isLoading: true }),
    [Actions.ADD_FORM_ERRORS]: (state, { payload: errors }) => ({ ...state, isLoading: false, errors }),
    [Actions.CHANGE_FORM_DATA]: (state, { payload }) => ({ ...state, data: { ...state.data, ...payload } })
  },
  DEFAULT_FORM_STATE
)

const DEFAULT_ACTIVENESS_FORM_USER_WORK_PERIOD_STATE = { role: 0, userId: null, startedAt: null, finishedAt: null }
const DEFAULT_ACTIVENESS_FORM_USER_WORK_PERIODS_STATE = [
  { role: 0, userId: null, startedAt: moment(), finishedAt: null },
  { role: 1, userId: null, startedAt: moment(), finishedAt: null },
  { role: 2, userId: null, startedAt: moment(), finishedAt: null },
  { role: 3, userId: null, startedAt: moment(), finishedAt: null },
  { role: 4, userId: null, startedAt: moment(), finishedAt: null },
  { role: 5, userId: null, startedAt: moment(), finishedAt: null }
]
const DEFAULT_ACTIVENESS_FORM_STATE = { data: { userWorkPeriods: DEFAULT_ACTIVENESS_FORM_USER_WORK_PERIODS_STATE } }
const activenessForm = handleActions({
  [Actions.START_INIT]: () => DEFAULT_ACTIVENESS_FORM_STATE,
  [Actions.CHANGE_ACTIVENESS_FORM_USER_WORK_PERIOD]: (state, { payload: { idx, data } }) => {
    const result = {
      ...state,
      data: {
        ...state.data,
        userWorkPeriods: [
          ...state.data.userWorkPeriods.slice(0, idx),
          { ...state.data.userWorkPeriods[idx], ...data },
          ...state.data.userWorkPeriods.slice(idx + 1)
        ]
      }
    }

    const extra = result.data.userWorkPeriods.some(e => !e.userId) ? [] : [DEFAULT_ACTIVENESS_FORM_USER_WORK_PERIOD_STATE]
    return {
      ...result,
      data: {
        ...result.data,
        userWorkPeriods: [
          ...result.data.userWorkPeriods,
          ...extra
        ]
      }
    }
  },
  [Actions.ADD_ACTIVENESS_FORM_USER_WORK_PERIOD]: (state) => ({
    ...state,
    data: {
      ...state.data,
      userWorkPeriods: [
        ...state.data.userWorkPeriods,
        DEFAULT_ACTIVENESS_FORM_USER_WORK_PERIOD_STATE
      ]
    }
  }),
  [Actions.DELETE_ACTIVENESS_FORM_USER_WORK_PERIOD]: (state, { payload: { idx } }) => ({
    ...state,
    data: {
      ...state.data,
      userWorkPeriods: [
        ...state.data.userWorkPeriods.slice(0, idx),
        ...state.data.userWorkPeriods.slice(idx + 1)
      ]
    }
  }),
  [Actions.INIT_ACTIVENESS_FORM]: (state) => ({
    ...state,
    data: {
      ...state.data,
      userWorkPeriods: [
        ...state.data.userWorkPeriods.map((userWorkPeriod) => ({ ...userWorkPeriod, startedAt: moment() }))
      ]
    }
  }),
  [Actions.DELETE_FINISHED_ACTIVENESS]: () => DEFAULT_ACTIVENESS_FORM_STATE,
  [Actions.SELECT_LOCATION]: () => DEFAULT_ACTIVENESS_FORM_STATE,
  [Actions.SELECT_SINGLE_LOCATION]: () => DEFAULT_ACTIVENESS_FORM_STATE,
  [Actions.RECEIVE_ACTIVENESS]: (state, { payload: { userWorkPeriods: receivedUserWorkPeriods } }) => {
    const finalUserWorkPeriods = receivedUserWorkPeriods.map(userWorkPeriod => ({
      ...userWorkPeriod,
      role: userWorkPeriod.roleId,
      startedAt: moment(userWorkPeriod.startedAt),
      finishedAt: userWorkPeriod.finishedAt ? moment(userWorkPeriod.finishedAt) : null
    }))

    return {
      ...state,
      data: {
        userWorkPeriods: [...finalUserWorkPeriods, DEFAULT_ACTIVENESS_FORM_USER_WORK_PERIOD_STATE]
      }
    }
  }
}, DEFAULT_ACTIVENESS_FORM_STATE)

const activenesses = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_ACTIVENESSES]: (state, { payload: activenesses }) => ({
      ...state,
      allById: allById(activenesses),
      idsByLocation: {
        ...state.idsByLocation,
        ...idsByCustom(activenesses, 'locationId')
      }
    }),
    [Actions.RECEIVE_ACTIVENESS]: (state, { payload: activeness }) => ({
      ...state,
      allById: { ...state.allById, [activeness.id]: activeness },
      idsByLocation: {
        ...state.idsByLocation,
        [activeness.locationId]: [...state.idsByLocation[activeness.locationId], activeness.id]
      },
      errors: {}
    }),
    [Actions.DELETE_FINISHED_ACTIVENESS]: (state, { payload: activeness }) => ({
      ...state,
      idsByLocation: {
        ...state.idsByLocation,
        [activeness.locationId]: _.without(state.idsByLocation[activeness.locationId], activeness.id)
      }
    }),
    [Actions.ADD_ACTIVENESS_ERRORS]: (state, { payload: errors }) => ({
      ...state,
      errors
    })
  },
  { allById: {}, idsByLocation: {}, errors: {} }
)

const productionRuns = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_PRODUCTION_RUNS]: (state, { payload: productionRuns }) => ({
      ...state,
      allById: allById(productionRuns),
      idsByLocation: {
        ...state.idsByLocation,
        ...idsByCustom(productionRuns, 'locationId')
      }
    }),
    [Actions.RECEIVE_PRODUCTION_RUN]: (state, { payload: productionRun }) => {
      return {
        ...state,
        allById: { ...state.allById, [productionRun.id]: productionRun },
        idsByLocation: {
          ...state.idsByLocation,
          [productionRun.locationId]: _.uniq([...state.idsByLocation[productionRun.locationId], productionRun.id])
        }
      }
    },
    [Actions.DELETE_FINISHED_PRODUCTION_RUN]: (state, { payload: productionRun }) => ({
      ...state,
      idsByLocation: {
        ...state.idsByLocation,
        [productionRun.locationId]: state.idsByLocation[productionRun.locationId].filter(e => e !== productionRun.id)
      }
    })
  },
  { allById: {}, idsByLocation: {} }
)

export const DEFAULT_PRODUCTION_RUN_FORM_STATE = {
  data: {
    id: null,
    productionPlanId: null,
    productionRunComponents: [],
    productId: null,
    projectId: null,
    firstGoodPartAt: null,
    firstGoodPartAtProcessing: false,
    firstGoodPartAtDirty: false,
    lastGoodPartAt: null,
    lastGoodPartAtProcessing: null,
    lastGoodPartAtDirty: false,
    remarks: '',
    confirmed: false
  },
  errors: {}
}
const productionRunForm = handleActions(
  {
    [Actions.CHANGE_PRODUCTION_RUN_FORM_DATA]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload
      }
    }),
    [Actions.CHANGE_PRODUCTION_RUN_FORM_COMPONENT]: (state, { payload: { idx, data } }) => ({
      ...state,
      data: {
        ...state.data,
        productionRunComponents: [
          ...state.data.productionRunComponents.slice(0, idx),
          { ...state.data.productionRunComponents[idx], ...data },
          ...state.data.productionRunComponents.slice(idx + 1)
        ]
      }
    }),
    [Actions.INIT_PRODUCTION_RUN_FORM]: (state, { payload }) => ({
      ...payload,
      data: {
        ...payload.data,
        firstGoodPartAtDirty: state.data.firstGoodPartAtDirty,
        lastGoodPartAtDirty: state.data.lastGoodPartAtDirty
      }
    }),
    [Actions.ADD_PRODUCTION_RUN_ERRORS]: (state, { payload: errors }) => ({
      ...state,
      errors,
      data: {
        ...state.data,
        firstGoodPartAtProcessing: false,
        lastGoodPartAtProcessing: false,
        firstGoodPartAtDirty: false,
        lastGoodPartAtDirty: false
      }
    }),
    [Actions.DELETE_PRODUCTION_RUN_ERRORS]: (state) => ({
      ...state,
      errors: {}
    }),
    [Actions.RECEIVE_PRODUCTION_RUN]: (state) => ({
      ...state,
      data: {
        ...state.data,
        firstGoodPartAtProcessing: false,
        lastGoodPartAtProcessing: false
      },
      errors: {}
    }),
    [Actions.DELETE_FINISHED_PRODUCTION_RUN]: () => DEFAULT_PRODUCTION_RUN_FORM_STATE,
    [Actions.SET_FIRST_GOOD_PART]: (state) => ({
      ...state,
      data: {
        ...state.data,
        firstGoodPartAtProcessing: true
      }
    }),
    [Actions.SET_LAST_GOOD_PART]: (state) => ({
      ...state,
      data: {
        ...state.data,
        lastGoodPartAtProcessing: true
      }
    }),
    [Actions.CONFIRM_PRODUCTION_RUN]: (state) => ({
      ...state,
      data: {
        ...state.data,
        confirmed: true
      }
    })
  },
  DEFAULT_PRODUCTION_RUN_FORM_STATE
)

const users = handleActions(
  {
    [Actions.RECEIVE_USERS]: (state, { payload }) => ({
      ...state,
      allById: allById(payload),
      idsByLocation: {
        ...state.idsByLocation,
        ...idsByCustomCollection(payload, 'locationIds')
      }
    })
  },
  { allById: {}, idsByLocation: {} }
)

const currentUser = handleActions(
  {
    [Actions.RECEIVE_CURRENT_USER]: (state, { payload }) => payload,
    [combineActions( // eslint-disable-line
      Actions.MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS,
      Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS
    )]: (state, { payload: noteIds }) => ({
      ...state,
      unreadShiftBookNoteIds: (state.unreadShiftBookNoteIds || []).filter(id => !noteIds.includes(id))
    })
  },
  {}
)

const userWorkPeriods = handleActions(
  {
    [Actions.RECEIVE_USER_WORK_PERIOD_ROLES]: (state, { payload }) => ({
      ...state,
      roles: payload
    })
  },
  { roles: {} }
)

const permissions = handleActions(
  {
    [Actions.RECEIVE_PERMISSIONS]: (state, { payload: permissions }) => ({
      planning: permissions.planningApp,
      realization: permissions.realizationApp
    })
  },
  {
    planning: { read: false, write: false },
    realization: { read: false, write: false }
  }
)

const predefinedRemarks = handleActions(
  {
    [Actions.RECEIVE_PREDEFINED_REMARKS]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const machineries = handleActions(
  {
    [Actions.RECEIVE_MACHINERIES]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const barcodeModal = handleActions(
  {
    [Actions.SHOW_REALIZATION_BARCODE]: (state, { payload: realizationId }) => ({ ...state, realizationId }),
    [Actions.SHOW_PLAN_BARCODE]: (state, { payload: planId }) => ({ ...state, planId }),
    [Actions.SHOW_MIX_PLAN_BARCODE]: (state, { payload: mixPlanId }) => ({ ...state, mixPlanId })
  },
  { productId: null }
)

const breakdowns = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_BREAKDOWNS]: (state, { payload: breakdowns }) => ({
      ...state,
      allById: allById(breakdowns),
      idsByLocation: {
        ..._.mapValues(state.idsByLocation, () => []),
        ...idsByCustom(breakdowns, 'locationId')
      }
    }),
    [Actions.RECEIVE_BREAKDOWN]: (state, { payload: breakdown }) => ({
      ...state,
      allById: { ...state.allById, [breakdown.id]: breakdown },
      idsByLocation: {
        ...state.idsByLocation,
        [breakdown.locationId]: [...state.idsByLocation[breakdown.locationId], breakdown.id]
      }
    })
  },
  { allById: {}, idsByLocation: {} }
)

export const scraps = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, []),
      percentageByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_SCRAPS]: (state, { payload: { locationId, scraps, percentage } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(scraps)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(scraps))
      },
      percentageByLocation: {
        ...state.percentageByLocation,
        [locationId]: percentage
      }
    })
  },
  {
    allById: {},
    idsByLocation: {},
    percentageByLocation: []
  }
)

const realizationSummaries = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REALIZATION_SUMMARIES]: (state, { payload: { locationId, realizationSummaries } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(realizationSummaries)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(realizationSummaries))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const shiftBookNotesInitState = {
  allById: {},
  allIds: [],
  loading: true,
  draft: '',
  editingId: null,
  replyingId: null,
  error: false,
  processing: false,
  rootIds: [],
  idsByTopic: {}
}
const shiftBookNotes = handleActions(
  {
    [combineActions( // eslint-disable-line
      SET_NAVIGATION,
      Actions.SELECT_SINGLE_LOCATION
    )]: () => shiftBookNotesInitState,
    [Actions.RECEIVE_SHIFT_BOOK]: (state, action) => {
      const { allById, allIds, ...restState } = receiveCollection(state, action)

      return {
        ...restState,
        allById,
        allIds,
        rootIds: allIds.filter(id => !allById[id].topicId),
        idsByTopic: idsByCustom(action.payload, 'topicId'),
        loading: false
      }
    },
    [Actions.CHANGE_SHIFT_BOOK_NOTE]: (state, { payload }) => ({
      ...state,
      draft: payload
    }),
    [Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS]: (state, { payload: id }) => ({
      ...state,
      allById: {
        ...state.allById,
        [id]: {
          ...state.allById[id],
          resolved: true
        }
      }
    }),
    [Actions.EDIT_SHIFT_BOOK_NOTE]: (state, { payload: id }) => ({
      ...state,
      editingId: id,
      replyingId: null,
      draft: state.allById[id].body
    }),
    [Actions.CANCEL_EDIT_SHIFT_BOOK_NOTE]: (state) => ({
      ...state,
      editingId: null,
      draft: '',
      error: false
    }),
    [Actions.REPLY_SHIFT_BOOK_NOTE]: (state, { payload: id }) => ({
      ...state,
      editingId: null,
      replyingId: id,
      draft: ''
    }),
    [Actions.CANCEL_REPLY_SHIFT_BOOK_NOTE]: (state) => ({
      ...state,
      replyingId: null,
      draft: '',
      error: false
    }),
    [Actions.SET_ERROR_SHIFT_BOOK]: (state) => ({
      ...state,
      error: true,
      processing: false
    }),
    [Actions.CLEAR_DRAFT_SHIFT_BOOK]: (state) => ({
      ...state,
      draft: '',
      replyingId: null,
      editingId: null,
      processing: false
    }),
    [combineActions( // eslint-disable-line
      Actions.SUBMIT_EDIT_SHIFT_BOOK_NOTE,
      Actions.CREATE_SHIFT_BOOK_NOTE
    )]: (state) => ({
      ...state,
      processing: true
    })
  },
  shiftBookNotesInitState
)

const notDownloadedAttachmentsCount = handleActions(
  {
    [Actions.RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT]: (_state, { payload }) => payload
  },
  0
)

export default combineReducers({
  config,
  init,
  locations,
  projects,
  products,
  stoppageTypes,
  scrapTypes,
  navigation,
  schedules,
  scheduleUpdates,
  realizations,
  activenesses,
  productionRuns,
  form,
  activenessForm,
  permissions,
  predefinedRemarks,
  machineries,
  barcodeModal,
  breakdowns,
  scraps,
  realizationSummaries,
  users,
  currentUser,
  userWorkPeriods,
  productionRunForm,
  shiftBookNotes,
  shiftNotifications,
  notDownloadedAttachmentsCount
})
