import React from 'react'
import PropTypes from 'prop-types'

const Badge = ({ text, size, className, style: styleProp, ...props }) => {
  const style = {
    width: size,
    height: size,
    ...(styleProp || {})
  }

  return (
    <div className={`badge ${className}`} style={style} {...props}>
      <span className='badge__text'>
        {text}
      </span>
    </div>
  )
}

Badge.defaultProps = {
  size: 21,
  className: '',
  style: {}
}

Badge.propTypes = {
  text: PropTypes.node,
  size: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object
}

export default React.memo(Badge)
