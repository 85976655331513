import React from 'react'
import PropTypes from 'prop-types'
import ProductionRunFormComponentGear from './ProductionRunFormComponentGear'
import ProductionRunComponentName from './ProductionRunComponentName'
import ProductionRunFormComponentDeliveryNumber from './ProductionRunFormComponentDeliveryNumber'
import ProductionRunFormComponentPremixQuantity from './ProductionRunFormComponentPremixQuantity'

export const ROWS_IN_COLUMN = 4

const ProductionRunFormComponent = ({ idx }) => (
  <div
    style={{
      margin: '0 2px',
      padding: 5,
      height: 90
    }}
    className='workspace-form'
  >
    <ProductionRunFormComponentGear idx={idx} />
    <ProductionRunComponentName idx={idx} />
    <ProductionRunFormComponentDeliveryNumber idx={idx} />
    <ProductionRunFormComponentPremixQuantity idx={idx} />
  </div>
)

ProductionRunFormComponent.propTypes = {
  idx: PropTypes.number
}

export default ProductionRunFormComponent
