import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { injectIntl, intlShape, FormattedMessage } from 'react-intl'
import Button from 'components/Button'
import DropdownButton from 'components/DropdownButton'
import RealizationMenuActivenessForm from 'workspace/RealizationMenuActivenessForm'
import RealizationMenuActivenessFinishForm from 'workspace/RealizationMenuActivenessFinishForm'
import RealizationMenuShiftBookDropdown from 'workspace/RealizationMenuShiftBookDropdown'
import RealizationMenuAttachments from 'workspace/RealizationMenuAttachments'
import { openNewForm, updateActiveness } from 'workspace/actions'
import {
  selectCurrentActiveness,
  selectBreakdowns
} from 'workspace/selectors'
import { DATETIME_FORMAT } from 'lib/const'
import { FormTypes, DivisionMessages } from 'workspace/const'
import {
  selectCurrentProductionRun,
  selectPermissions,
  selectCurrentProductionRunConfirmed
} from './selectors'
import RealizationMenuProductionRunForm from './RealizationMenuProductionRunForm'

class RealizationMenu extends React.Component {
  static defaultProps = {
    onMenuClick: () => {}
  }

  static propTypes = {
    intl: intlShape,
    activeness: PropTypes.shape({
      id: PropTypes.number
    }),
    breakdowns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string,
        createdAt: PropTypes.string.isRequired
      })
    ),
    productionRun: PropTypes.shape({
      id: PropTypes.number,
      confirmed: PropTypes.bool
    }),
    onMenuClick: PropTypes.func,
    finishActiveness: PropTypes.func.isRequired,
    updateActiveness: PropTypes.func,
    productionRunOnly: PropTypes.bool,
    reportingHalted: PropTypes.bool
  }

  menuClick = (type, data) => this.props.onMenuClick(type, data)
  menuRealizationClick = e => this.menuClick(FormTypes.REALIZATION)
  menuStoppageClick = e => this.menuClick(FormTypes.REALIZATION_STOPPAGE)
  menuScrapClick = e => this.menuClick(FormTypes.REALIZATION_SCRAP)
  menuBreakdownStartClick = e => this.menuClick(FormTypes.BREAKDOWN_START)
  menuBreakdownFinishClick = breakdown =>
    this.menuClick(FormTypes.BREAKDOWN_FINISH, breakdown)
  finishActiveness = params =>
    this.props.finishActiveness({ ...params, id: this.props.activeness.id, finishAfter: true })
  updateActiveness = () =>
    this.props.updateActiveness({ id: this.props.activeness.id })

  render () {
    const {
      intl,
      activeness,
      breakdowns,
      productionRun,
      productionRunOnly,
      reportingHalted
    } = this.props

    return (
      <div className='workspace-menu'>
        {activeness && (
          <React.Fragment>
            <div
              className='workspace-menu__group'
              style={productionRunOnly ? { justifyContent: 'flex-start' } : {}}
            >
              <DropdownButton
                color='accent'
                dropdownColor='white-with-accent'
                className='workspace-menu__element'
                popperConfig={{ placement: 'bottom-start' }}
                data-production-run-dropdown='1'
                content={({ close, open }) => (
                  <RealizationMenuProductionRunForm
                    onClose={close}
                    open={open}
                  />
                )}
              >
                {productionRun ? (
                  <FormattedMessage
                    id='workspace.menu.manageProductionRun'
                    defaultMessage='Production run'
                    values={{
                      number: productionRun.callNumber
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id='workspace.menu.startProductionRun'
                    defaultMessage='Begin production run'
                  />
                )}
              </DropdownButton>
              {productionRun && !productionRunOnly && (
                <React.Fragment>
                  <Button
                    onClick={this.menuRealizationClick}
                    color='success'
                    className='workspace-menu__element'
                    disabled={reportingHalted}
                  >
                    <FormattedMessage
                      id='workspace.menu.realization'
                      defaultMessage='Realization'
                      description='Menu button that opens the realization form'
                    />
                  </Button>
                  <Button
                    onClick={this.menuScrapClick}
                    color='info'
                    className='workspace-menu__element'
                    disabled={reportingHalted}
                  >
                    <FormattedMessage
                      id='workspace.menu.realizationScrap'
                      defaultMessage='Scrap'
                      description='Menu button that opens the realization scrap form'
                    />
                  </Button>
                </React.Fragment>
              )}
              {!productionRunOnly && (
                <Button
                  onClick={this.menuStoppageClick}
                  color='danger'
                  className='workspace-menu__element'
                  disabled={reportingHalted}
                >
                  <FormattedMessage
                    id='workspace.menu.realizationStoppage'
                    defaultMessage='Stoppage'
                    description='Menu button that opens the realization stoppage form'
                  />
                </Button>
              )}
            </div>
          </React.Fragment>
        )}

        {!productionRunOnly && (
          <React.Fragment>
            <div className='workspace-menu__group'>
              {activeness && (
                <DropdownButton
                  color='warning'
                  className='workspace-menu__element'
                  content={({ close }) => (
                    <RealizationMenuActivenessFinishForm
                      onClose={close}
                      onAuthorizationCodeSubmit={this.finishActiveness}
                      onActivenessUpdate={this.updateActiveness}
                    />
                  )}
                >
                  <FormattedMessage
                    id='workspace.menu.finishActiveness'
                    defaultMessage='Stop working [{startedAt}]'
                    description='Menu button that finishes a location activeness'
                    values={{
                      startedAt: moment(activeness.startedAt)
                        .format(DATETIME_FORMAT)
                        .replace(' ', '\u00a0')
                        .replace(/-/g, '\u2011')
                    }}
                  />
                </DropdownButton>
              )}
              {!activeness && (
                <DropdownButton
                  color='warning'
                  dropdownColor='warning'
                  className='workspace-menu__element'
                  content={({ close }) => (
                    <RealizationMenuActivenessForm onClose={close} />
                  )}
                >
                  <FormattedMessage
                    id='workspace.menu.startActiveness'
                    defaultMessage='Begin working'
                    description='Menu button that starts a location activeness'
                  />
                </DropdownButton>
              )}
              <RealizationMenuShiftBookDropdown />
              <RealizationMenuAttachments />
            </div>

            <div className='workspace-menu__group workspace-menu__group--column'>
              {breakdowns && breakdowns.map(breakdown => {
                const division = DivisionMessages[breakdown.division]
                  ? intl.formatMessage(DivisionMessages[breakdown.division])
                  : breakdown.division.charAt(0).toUpperCase() + breakdown.division.slice(1)

                return (
                  <Button
                    key={breakdown.id}
                    onClick={() => this.menuBreakdownFinishClick(breakdown)}
                    color='danger'
                    className='workspace-menu__column-element'
                  >
                    {`${division} ${moment(breakdown.createdAt).format('HH:mm')}`}
                  </Button>
                )
              })}
              <Button
                onClick={this.menuBreakdownStartClick}
                color='default'
                className='workspace-menu__column-element'
                disabled={reportingHalted}
              >
                <FormattedMessage
                  id='workspace.menu.startBreakdown'
                  defaultMessage='Report breakdown'
                  description='Menu button that starts a location breakdown'
                />
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { realization: realizationPermissions } = selectPermissions(state)

  return {
    activeness: selectCurrentActiveness(state),
    productionRun: selectCurrentProductionRun(state),
    breakdowns: selectBreakdowns(state),
    reportingHalted: !selectCurrentProductionRunConfirmed(state),
    productionRunOnly:
      !realizationPermissions.write &&
      realizationPermissions.manageProductionRuns
  }
}

const mapDispatchToProps = {
  onMenuClick: openNewForm,
  finishActiveness: updateActiveness,
  updateActiveness
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(RealizationMenu)
