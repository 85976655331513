import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlanTimeline from 'workspace/PlanTimeline'
import WorkspaceLocationsEmpty from 'workspace/WorkspaceLocationsEmpty'

const PlanTimelines = ({ ids }) => (
  <div>
    {ids.map(id => <PlanTimeline key={id} id={id} />)}
    {ids.length === 0 && <WorkspaceLocationsEmpty />}
  </div>
)

PlanTimelines.defaultProps = {
  ids: []
}

PlanTimelines.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = (state) => ({
  ids: state.locations.selectedIds
})

export default connect(mapStateToProps)(PlanTimelines)
