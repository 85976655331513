import moment from 'moment-timezone'
import { setNavigationScope, setNavigationDate, configureNavigation } from 'containers/Navigation/actions'
import { DAY, WEEK, TYPE_WEEK_DAY_SHIFT } from 'containers/Navigation/const'
import { put, select } from 'redux-saga/effects'
import { selectMode, selectTimezone } from 'workspace/selectors'
import { Modes } from 'workspace/const'

const getInitialDate = (mode, timezone) => {
  return moment.tz({ hour: 0 }, timezone)
}

function * navigationInit (action) {
  let mode = action ? action.payload : yield select(selectMode)
  const timezone = yield select(selectTimezone)

  yield put(configureNavigation(TYPE_WEEK_DAY_SHIFT))
  yield put(setNavigationScope(mode === Modes.PLANNING ? WEEK : DAY))
  yield put(setNavigationDate(getInitialDate(mode, timezone)))
}

export default navigationInit
