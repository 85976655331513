import moment from 'moment-timezone'
import { createBrowserHistory } from 'history'
import { eventChannel } from 'redux-saga'
import { take, takeEvery, call, select, put } from 'redux-saga/effects'
import { selectMode, selectTimezone, selectSelectedLocation } from 'workspace/selectors'
import { selectNavigationScope, selectNavigationDate } from 'containers/Navigation/selectors'
import { Modes } from 'workspace/const'
import Actions, { selectSingleLocation } from 'workspace/actions'
import { SET_NAVIGATION, setNavigationScope, setNavigationDate } from 'containers/Navigation/actions'
import { DATE_FORMAT } from 'lib/const'

function createHistoryChannel (history) {
  return eventChannel(emit => {
    const unlisten = history.listen((location, action) => {
      emit({ location, action })
    })

    return unlisten
  })
}

function * changeHistory (history, action) {
  let mode = yield select(selectMode)
  if (mode === Modes.REALIZATION) {
    const location = yield select(selectSelectedLocation)
    const scope = yield select(selectNavigationScope)
    const date = yield select(selectNavigationDate)
    const path = `/workspace/realization/${location.id}/${scope}/${date.format(DATE_FORMAT)}`
    yield call(history.push, path)
  }
}

function * acceptHistory (location) {
  const mode = yield select(selectMode)
  if (mode === Modes.REALIZATION) {
    const timezone = yield select(selectTimezone)
    const [locationId, scope, date] = location.pathname.split('/').slice(-3)

    yield put(setNavigationScope(scope))
    yield put(setNavigationDate(moment.tz(date, timezone)))
    yield put(selectSingleLocation(parseInt(locationId)))
  }
}

export default function * history () {
  const history = yield call(createBrowserHistory)
  const historyChannel = yield call(createHistoryChannel, history)

  if (history.location.pathname === '/workspace/realization') {
    yield call(changeHistory, history)
  } else {
    yield call(acceptHistory, history.location)
  }

  yield takeEvery([
    SET_NAVIGATION,
    Actions.SELECT_SINGLE_LOCATION
  ], changeHistory, history)

  while (true) {
    try {
      const { location, action } = yield take(historyChannel)
      if (action === 'POP') {
        yield call(acceptHistory, location)
      }
    } catch (err) {
      console.error('history error:', err)
    }
  }
}
