import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import WorkspaceForm from 'workspace/WorkspaceForm'
import WorkspaceLocations from 'workspace/WorkspaceLocations'
import WorkspaceMenu from 'workspace/WorkspaceMenu'
import WorkspaceNavigation from 'workspace/WorkspaceNavigation'
import WorkspaceTimelines from 'workspace/WorkspaceTimelines'
import WorkspaceBarcodeModal from 'workspace/WorkspaceBarcodeModal'
import WorkspaceBarcodePlanModal from 'workspace/WorkspaceBarcodePlanModal'
import WorkspaceBarcodeMixModal from 'workspace/WorkspaceBarcodeMixModal'
import Loader from 'components/Loader'
import { selectPermissions, selectMode } from 'workspace/selectors'
import RealizationMenuAttachments from './RealizationMenuAttachments'

const Workspace = ({ mode, isLoaded, isFormActive, isFormAvailable, quickAttachments }) => {
  if (isLoaded) {
    return (
      <React.Fragment>
        {
          isFormAvailable &&
          <div className='workspace__menu'>
            {isFormActive ? <WorkspaceForm /> : <WorkspaceMenu />}
          </div>
        }

        <div className='workspace__submenu'>
          <div className='workspace__locations'>
            <WorkspaceLocations />
            {quickAttachments && <RealizationMenuAttachments />}
          </div>
          <div className='workspace__navigation'>
            <WorkspaceNavigation />
          </div>
        </div>

        <WorkspaceTimelines />
        <WorkspaceBarcodeModal />
        <WorkspaceBarcodePlanModal />
        <WorkspaceBarcodeMixModal />
      </React.Fragment>
    )
  } else {
    return <Loader full />
  }
}

Workspace.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  isFormActive: PropTypes.bool.isRequired,
  isFormAvailable: PropTypes.bool,
  quickAttachments: PropTypes.bool
}

const mapStateToProps = (state) => {
  const { write: canWrite, manageProductionRuns, readAttachments } = selectPermissions(state)[selectMode(state)]

  const isFormAvailable = canWrite || manageProductionRuns

  return {
    isLoaded: state.init.isFinished,
    isFormActive: state.form.isActive,
    quickAttachments: !isFormAvailable && readAttachments,
    isFormAvailable
  }
}

export default connect(mapStateToProps)(Workspace)
