import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectLocationRealizationsPerformance } from 'workspace/selectors'
import Icon from 'components/Icon'

const RealizationPerformance = ({ performance }) => (
  <span className='small'>
    &nbsp;
    [
    { performance === null && <Icon name='cog' spin /> }
    { performance !== null && performance === -1 && '---' }
    { performance !== null && performance > -1 && <code>{Math.round(100 * performance)}%</code> }
    ]
  </span>
)

RealizationPerformance.propTypes = {
  performance: PropTypes.number
}

const mapStateToProps = (state) => ({
  performance: selectLocationRealizationsPerformance(state)
})

export default connect(mapStateToProps)(RealizationPerformance)
